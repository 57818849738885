import React, { useEffect, useState } from 'react'
import  './homeOnAnimation.css'
import { Link } from 'react-router-dom'
import robot from '../assets/robot.png'
import robotLogoOn from '../assets/robotLogoOn.png'
import aiLogo from '../assets/aitradingLogo.png'
import Testimonials from './testimonial/Testimonials'
import { FaPowerOff } from "react-icons/fa"

const HomeOnAnimation = () => {
    const [isHovering, setIsHovered] = useState(false);
    const [showImage, setShowImage] = useState(false);

    const onMouseEnter = () => setIsHovered(true);
    const onMouseLeave = () => setIsHovered(false);

    const timer = 10000

    useEffect(() => {
      
      const homeTimeoutId = setTimeout(() => {
        setShowImage(true);
      }, timer); 
  
    
      return () => clearTimeout(homeTimeoutId);
    }, []); 
  
  
  return (
    <div  className='onContainer' >
         <div style={{width:'70%',zIndex:1}}>
         <div className="absolute top-0 -left-4 w-72 h-72 bg-purple-400 rounded-full 
                mix-blend-multiply filter blur-xl opacity-10 ">
                        </div>

                        <div className="absolute top-0 -right-4 w-72 h-72 bg-blue-300 rounded-full 
                                    mix-blend-multiply filter blur-xl opacity-20 ">
                        </div>

                        <div className="absolute -bottom-8 left-20 w-72 h-72 bg-sky-300 rounded- 
                                        full mix-blend-multiply filter blur-xl opacity-20 ">
                        </div>
         </div>
       
           
              <div style={{width:'80%', margin:'30px auto'}} className='flex flex-col items-center justify-center gap-4'>
            <div className='homeTextContainer'>
            {/* <h1 className='text-2xl text-sky-600 text-center  font-bold md:text-5xl'>Welcome to Tibio: Your Automated Trading Partner</h1> */}
            {/* <p className='text-lg text-center md:text-3xl'>At Tibio, we introduce you to the future of investing. Our artificial intelligence robot is meticulously crafted to autonomously trade investors' funds, ensuring seamless and profitable experiences. With Tibio, simplicity meets convenience, allowing you to entrust your investments to cutting-edge technology. Experience the ease of automated trading without any human intervention. Join Tibio today and unlock the potential of AI-driven financial success!</p> */}
            
            </div>
            <Testimonials />
            
                                {/* {isHovering ?   <Image 
                                    width={400}
                                    height={400}
                                    className={styles.robotImageOne}
                                    quality={100}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave} 
                                    style={{objectFit:'contain'}}
                                    src='/robotLogoOn.png' alt="website modal image" />
                                      :    */}
                                     {showImage && 
                                     <div className='imageHomeContainer'>
                                        <Link to='/login'>
                                        <img 
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave} 
                                    className='robotHomeImageOne'
                                    // style={{width:'300px', height:'400px', objectFit:'cover'}}
                                    src={isHovering ? robotLogoOn : robot} alt="Tibio image" />
                                      </Link>
                                     </div>
                                   
                                    }
                                     {/* }  */}
                            

              </div>
                    
          
         
       {showImage && <h1 className='tapText' >Press <FaPowerOff /></h1>}
 
    </div>
  )
}

export default HomeOnAnimation
