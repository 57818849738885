import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import WalletSlideColumn from '../components/WalletSlideColumn'
import ChooseTransferColumn from '../components/ChooseTransferColumn'
import Footer from '../components/Footer'

const ChooseTransfer = () => {
  return (
    <div>
      <DashboardNavbar />
      <DashboardSidebar />
        <WalletSlideColumn />
        <ChooseTransferColumn />

        <Footer />
    </div>
  )
}

export default ChooseTransfer
