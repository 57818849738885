import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import chartAnimation from '../assets/robotchartAnimation.json'
import { Link } from 'react-router-dom';
import logo from '../assets/aitradingLogo.png'
import './footer.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import { FaTelegram } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const Footer = () => {
  return (
    <div className='flex flex-col items-center justify-center gap-2 bg-sky-700 p-5 mt-8'>
      <div style={{width:'100%'}}>
      <Player
          src={chartAnimation}
          className="player"
          loop
          autoplay
           style={{ height: '100px', width: '100%', objectFit:'cover' }}
          
          />
      </div>

      <div className='footerMiddleContainer'>
        <div className='flex gap-4'>

                <div className='footerText'>
            {/* <h1 className='text-2xl text-gray-400 font-bold'>Welcome to Tibio</h1> */}
            <p className='text-sm text-gray-400'> The content, design, and intellectual property featured on this website, including but not limited to text, graphics, logos, images, and software, are the exclusive property of Tibio and protected by international copyright laws. Any use, reproduction, distribution, or modification of the content without explicit written permission from Tibio is strictly prohibited. Unauthorized use may lead to legal action.</p>

        </div>

        </div>         
     
      </div>

      <span className="text-center text-white text-sm">Copyright © 2023 Tibio Inc. All Rights Reserved.</span>
    </div>
  )
}

export default Footer
