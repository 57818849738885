import {createSlice} from '@reduxjs/toolkit'


const initialState = {
 
  portfolioDetails: {}, // for user object

}

export const portfolioSlice = createSlice({
    name:'portfolio',
    initialState,
    reducers:{
     
        setPortfolioDetails: (state,action)=>{ 
            state.portfolioDetails = action.payload  
        },
    
       
    }
});

export const {  
    setPortfolioDetails,
 } = portfolioSlice.actions


 export const selectPortfolioDetails = (state) => state.portfolio.portfolioDetails;
  
  


export default portfolioSlice.reducer;