import React from 'react'
import styles from './logosSlide.module.css'
import img1 from '../assets/img1.jpeg'
import img2 from '../assets/img2.jpeg'
import img3 from '../assets/img3.jpeg'
import img4 from '../assets/img4.jpeg'
import img5 from '../assets/img5.jpeg'
import img6 from '../assets/img6.jpeg'
import img7 from '../assets/img7.jpeg'
import img8 from '../assets/img8.jpeg'
import img9 from '../assets/img9.jpeg'
import img10 from '../assets/img10.jpeg'
import img11 from '../assets/img11.jpeg'
import img12 from '../assets/img12.jpeg'
import img13 from '../assets/img13.jpeg'
import img14 from '../assets/img14.jpeg'
import img15 from '../assets/img15.jpeg'
import img16 from '../assets/img16.jpeg'

const images =  [
   {image: img1},
  { image: img2},
  { image: img3},
   {image: img4},
   {image: img5},
   {image: img6},
   {image: img7},
   {image: img8},
   {image: img9},
   {image: img10},
   {image: img11},
   {image: img12},
  //  {image: img13},
   {image: img14},
   {image: img15},
   {image: img16}
]

const LogosSlide = () => {
  return (
    <div className={styles.topTagContainer}>
    <div className={styles.innerContainer}>

        <div className={styles.slide}>
        <img src={img1} className={styles.image} alt="" />
        </div>

        <div className={styles.slide}>
        <img src={img2} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img3} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img4} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img5} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img6} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img7} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img8} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img9} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img10} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img11} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img12} className={styles.image} alt="" />
        </div>
            
        {/* <div className={styles.slide}>
        <img src={img13} className={styles.image} alt="" />
        </div>
             */}
        <div className={styles.slide}>
        <img src={img14} className={styles.image} alt="" />
        </div>
            

        <div className={styles.slide}>
        <img src={img15} className={styles.image} alt="" />
        </div>

        <div className={styles.slide}>
        <img src={img16} className={styles.image} alt="" />
        </div>
        <div className={styles.slide}>
        <img src={img1} className={styles.image} alt="" />
        </div>

        <div className={styles.slide}>
        <img src={img2} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img3} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img4} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img5} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img6} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img7} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img8} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img9} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img10} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img11} className={styles.image} alt="" />
        </div>
            
        <div className={styles.slide}>
        <img src={img12} className={styles.image} alt="" />
        </div>
            
        {/* <div className={styles.slide}>
        <img src={img13} className={styles.image} alt="" />
        </div>
             */}
        <div className={styles.slide}>
        <img src={img14} className={styles.image} alt="" />
        </div>
            

        <div className={styles.slide}>
        <img src={img15} className={styles.image} alt="" />
        </div>
        
        <div className={styles.slide}>
        <img src={img16} className={styles.image} alt="" />
        </div>
            
    
  
      </div>
  
      </div>
  )
}

export default LogosSlide
