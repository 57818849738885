import './navbar.css'
import { NavLink } from 'react-router-dom'
import logoImage from '../assets/aitradingLogo.png'
import { useEffect, useState } from 'react';
import { HiMenuAlt3 } from "react-icons/hi";
import { SiExpertsexchange } from "react-icons/si";
import { IoClose } from "react-icons/io5";
import { BiSolidRightArrow } from 'react-icons/bi'
import { BiSolidDownArrow } from 'react-icons/bi'
import { TiArrowSortedUp } from "react-icons/ti";
import Loader from './Loader'

export default function Navbar() {
  const [click, setClick] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [openAboutTibio, setOpenAboutTibio] = useState(false)

  const handleClick = ()=> setClick(!click);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 100) {
        // Adjust the scroll position at which the navbar becomes fixed
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
 

  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);

  return (
    <main style={{position:'sticky'}} className={`nav-container ${isNavbarFixed ? "fixed" : ""}`}>
      {loading ? <Loader /> : null}
      <img
        src={logoImage}
        // width={100}
        // height={100}
        style={{width:'100px', height:'100px', objectFit:'contain'}}
        alt="logo image"
  
      />
      <div className='navItems-container'>
      <div className='menu-bar' onClick={handleClick} >
        { click ?
         <IoClose  className='text-3xl text-sky-600 font-bold'  /> 
         : 
         <HiMenuAlt3 className='text-2xl text-sky-600'  />
         }
        </div>
         <div className={click ? 'navItems-container active' : 'navItems-inner-container '}>

        
            <NavLink  className='nav'  activeClassName="active"  to='/aiTrading'>Home</NavLink>
            <NavLink  className='nav'  to='/contact'>Contact Us</NavLink>

          <div className='flex items-center justify-center gap-6 '>

          <div  onMouseEnter={()=>setOpenAboutTibio(true)} onClick={()=>setOpenAboutTibio(!openAboutTibio)} style={{position:'relative', cursor:'pointer'}} className='nav'  >Tibio</div>
            {openAboutTibio ? <TiArrowSortedUp onClick={()=>setOpenAboutTibio(!openAboutTibio)} className='navIcon' /> : <BiSolidDownArrow onClick={()=>setOpenAboutTibio(!openAboutTibio)} className='navIcon' />}

          </div>


           {openAboutTibio &&
            <div onMouseLeave={()=>setOpenAboutTibio(false)} className='nav-inner'>
            <NavLink className='nav'   to='/about'>About</NavLink>
            <NavLink className='nav'   to='/finance'>Finance</NavLink>
            <NavLink className='nav'   to='/performance'>Performance</NavLink>
            <NavLink className='nav'   to='/esg'>ESG</NavLink>
            </div>
              }  
            <NavLink className='nav'  to='/login'>Login</NavLink>
         </div>
      </div>
     
    </main>
  )
}
