
import HomeOnAnimation from '../components/HomeOnAnimation'


export default function Home() {
  return (
    <main>
   

        <HomeOnAnimation />

    </main>
  )

}

