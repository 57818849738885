import React, { useEffect, useState } from 'react'
import styles from './dashboardWithdrawal.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import Loader from './Loader'
import withdrawalImage from '../assets/withdrawal.jpg'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'

const DashboardWithdrawal = () => {
    const [showModal, setShowModal] = useState(false)
    const [amount, setAmount] = useState(0)
    const [walletAddress, setWalletAddress] = useState('')
    const [walletType, setWalletType] = useState('')
    const userInfo = useSelector(selectUserInfo)
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)
  

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai/'

    // const handleShowPasswordForm =(e)=>{
    //   e.preventDefault()
    //   setShowModal(true)
    // }
    const [loading, setLoading] = useState(true);

    useEffect(() => {
     
      setTimeout(() => {
        setLoading(false);
      }, 3000); 
    }, []);

    const handleWithdrawalSubmit=async(e)=>{
      e.preventDefault()
      console.log(amount, walletAddress, walletType)

      if(!amount || !walletAddress || !walletType) {
        setPopUp('Fill in all the details to proceed')
        setShowPopupModal(true)
        return
      }
  
     setLoading(true)

     var myHeaders = new Headers();
     myHeaders.append("Authorization", `Bearer ${userInfo.token}`);


      var formdata = new FormData();
      formdata.append("amount", amount);
      formdata.append("walletAddress", walletAddress);
      formdata.append("walletType", walletType);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(`${API_ENDPOINT}/api/withdrawal/make-withdrawal`, requestOptions)
        .then(response => response.json())
        .then(result =>{
            console.log(result)
            if(result.message === 'Withdrawal successful'){
              setPopUp('Withdrawal Successful, Note that it might take up to 30minutes to an hour depending on your wallet address network before the money reflects in your wallet')
              setShowPopupModal(true)
              const form = e.target;
                form.reset()
                setLoading(false)
                return
            } else {
              setPopUp('Oops! Something went wrong, ensure you have enough money in your main wallet and stable internet connection and try again')
              setShowPopupModal(true)
              setLoading(false)
              return
            }
           
       
        })
        .catch(error => console.log('error', error));
  
    }


  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }
  

    
  return (
    <div style={{width:'100%',display:'flex', alignItems:'center', justifyContent:'center', padding:100}}>
  {loading ? <Loader /> : null}

    {/* popup */}
    { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
    <div className={styles.downlineTransferContainer}>

      <div className={styles.left}>
        <img  src={withdrawalImage} alt="image" />
      </div>

    <form onSubmit={handleWithdrawalSubmit} className={styles.right}>
          {/* <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Withdrawal</h1> */}
          {/* <label className={styles.emailText} htmlFor="email">Enter Amount</label> */}
          <input 
          onChange={(e)=>setAmount(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}} placeholder='Amount' className='bg-sky-100 mt-2 p-2' type="number" name='amount' />
          <input
          onChange={(e)=>setWalletAddress(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}} placeholder='Wallet Address' className='bg-sky-100 mt-2 p-2' type="text" name='address' />
          <input 
          onChange={(e)=>setWalletType(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}} placeholder='Wallet Type' className='bg-sky-100 mt-2 p-2 mb-5' type="text" name='crypto' />
         
          <button  className={styles.btn} type="submit">Withdraw</button>
        </form>

    </div>
     


   {/* { showModal &&  <div className={styles.modal}>
     < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
      <div style={{width:'300px'}} className='flex gap-3 my-11 bg-sky-200 mx-11 p-5 items-center justify-center'>
    
        <form style={{width:'300px'}} className='flex flex-col gap-3 items-center justify-center m-auto' action="">
          <label className={styles.emailText} htmlFor="email">Enter Wallet</label>
          <input style={{backgroundColor:'white', height:'40px', width:'100%'}} type="email" name='email' />
          <button className={styles.loginButton} type="submit">Submit</button>
       

        </form>
      </div>

      </div> } */}
     
    </div>
  )
}

export default DashboardWithdrawal
