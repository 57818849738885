
import React, { useRef, useState, useContext, useEffect } from 'react'
import Navbar from '../components/Navbar'
import styles from './login.module.css'
import axios from 'axios';
import { FaRegTimesCircle } from 'react-icons/fa'
import homeImage from '../assets/homeImage.jpg'
// import robot from '../assets/robot.jpg'
import robot from '../assets/robot.png'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { TickerTape } from "react-ts-tradingview-widgets";
import TopTag from '../components/TopTag'
import { AuthContext } from '../context/AuthContext';
import OtpInput from 'react-otp-input';
import Loader from '../components/Loader';
import { selectUserInfo, setUserInfo } from '../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux'
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import Footer from '../components/Footer';

{/* <FaRegEyeSlash /> */}

{/* <FaRegEye /> */}
const LoginPassword = () => {
  const [showModal, setShowModal] = useState(false)
  const [showOTPModal, setShowOTPModal] = useState(false)
  const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)

  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const {user, err} = useContext(AuthContext);
  const [loading, setLoading] = useState(false)
  const timeoutRef = useRef(null);
  const navigate = useNavigate()
  const [otp, setOtp] = useState('');
  const [removeEnterEmail, setRemoveEnterEmail] = useState(true)
  const location = useLocation();
  const {state} = location;
  const [email, setEmail] = useState('')
  const userInfo = useSelector(selectUserInfo)
  const dispatch = useDispatch()
  const [isPasswordSecure, setIsPasswordSecure] = useState(true)

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai/'

  useEffect(()=>{
    setEmail(state.email)
  },[])

// let timer;
//   const autoSubmitEmail =async()=>{
//     console.log('submitted--- on key up')
//     clearTimeout(timer)
//    timer = setTimeout(async()=>{

//       try {
//         const userExist = await fetch('api/userExists', {
//           method: "POST",
//           headers: {
//             "Content-Type": 'application/json'
//           },
//           body: JSON.stringify({
//             email 
//           })
//         })
  
//         const {user} = await userExist.json()
//         if(!user){
  
//           // setError('User does not exist, Sign up.')
//           setPopUp('User does not exist, Edit your email or Sign up if you dont have an account.')
//           setShowPopupModal(true)
//           return 
//         }
//         setShowModal(true)
//       } catch (error) {
        
//       }
//       console.log('submitted--- after few seconds')

//     },10000)
   
 
//   }

// Type for the function to be debounced
// type FunctionToDebounce<T extends (...args: any[]) => void> = (...args: Parameters<T>) => void;

// Debounce function
// const debounce = (func, delay) => {
//     let timeoutId;
  
//     return (...args) => {
//       clearTimeout(timeoutId);
//       timeoutId = setTimeout(() => func(...args), delay);
//     };
//   };

// Example usage with the autoSubmitEmail function
// const debouncedAutoSubmitEmail = debounce(autoSubmitEmail, 10000);

  // const handleShowPasswordForm =async(e)=>{
  //   e.preventDefault()
  //   if(!email) {
  //     setError('Fill in your email address')
  //     setPopUp('Fill in your registered email address')
  //       setShowPopupModal(true)
  //     return
  //   }


//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }

//     timeoutRef.current = window.setTimeout(autoSubmitEmail, 3000); // 30 seconds in milliseconds

 
//     try {
//       const userExist = await fetch('api/userExists', {
//         method: "POST",
//         headers: {
//           "Content-Type": 'application/json'
//         },
//         body: JSON.stringify({
//           email 
//         })
//       })

//       const {user} = await userExist.json()
//       if(!user){

//         // setError('User does not exist, Sign up.')
//         setPopUp('User does not exist, Edit your email or Sign up if you dont have an account.')
//         setShowPopupModal(true)
//         return 
//       }
      // setShowModal(true)
//     } catch (error) {
      
//     }
  //   console.log('email & password :',email, password)

  // }

  // const handleShowPasswordForm =async(e)=>{
  //   e.preventDefault()

  //   if(!email) {
  //     setError('Fill in your email address')
  //     setPopUp('Fill in your valid email address to continue')
  //     setShowPopupModal(true)
  //     return
  //   }


  // var formdata = new FormData();
  // formdata.append("email", email);
  
  // var requestOptions = {
  //   method: 'POST',
  //   body: formdata,
  //   redirect: 'follow'
  // };
  
  // fetch("http://localhost:5000/api/auth/verify-email", requestOptions)
  //   .then(response => response.json())
  //   .then(result => {
  //     console.log(result.message)
  //     if(result.message === 'Email is available'){

  //       setError('Email does not exist.')
  //       setPopUp('Email does not exist, Edit your email or Sign up if you do not have an account.')
  //       setShowPopupModal(true)
  //       return
        
       
  //     }else if(result.message === 'Email already taken!'){
  //       setShowModal(true)
  //     }
  //     console.log(result)})
  //   .catch(error => console.log('error', error));
    

  //   console.log('email & password :',email, password)
  // }



  const handleLogin =async(e)=>{
    e.preventDefault()
    if(!password) {
      setError('Fill in your Password')
      return
    }

    dispatch({type:"LOGIN_START"});
   setLoading(true)
    var formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${API_ENDPOINT}/api/auth/login`, requestOptions)
      .then(response => response.json())
      .then(result =>{
    if(result.message === 'Login successful' && result.user.has_activated){
          // dispatch({type:"LOGIN_SUCCESS", payload: result});
          dispatch(setUserInfo(result))
          // window.localStorage.setItem("user", JSON.stringify(result))
           navigate('/dashboard')
           console.log(result)
        }else if(!result.user.has_activated){
          setPopUp('Email is not verified, Go to your email and click on the link to verify email, Then try again.')
          setShowPopupModal(true)
          const form = e.target;
            form.reset()
            setLoading(false)
        }else{
          console.log('error logining in, try again')
          setPopUp('Sorry could not login, check your password and try again.')
          setShowPopupModal(true)
        }
       setLoading(false)
      })
      .catch(error => console.log('error', error));


  
 
  
  
    console.log('email & password :',email, password)
  }

  const handleChangeOtp = (value) => {
    console.log(value);
    setOtp(value)
      // Ensure that the latest value is used
      if (value.length === 4) {
        // verifyToken(prevOtp);
       
        setLoading(true)
        console.log(value)
        if(Number(value) === userInfo?.user?.otp){
          navigate('/dashboard')
          console.log(value)
        }else{
          setPopUp('Wrong OTP, Please confirm the number and try again')
          setShowPopupModal(true)
          setLoading(false)
          return
        }
        setLoading(false)

      }
      return value;

  };

console.log(user?.user?.otp)

  const verifyToken=(enteredOTP)=>{
    setLoading(true)
    console.log(enteredOTP)
    if(Number(enteredOTP) === userInfo?.user?.otp){
      navigate('/dashboard')
      console.log(enteredOTP)
    }else{
      setPopUp('Wrong OTP, Please confirm the number and try again')
      setShowPopupModal(true)
      setLoading(false)
      return
    }
    setLoading(false)
   
  }





  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }
  

 

  return (
    <div>
       {loading ? <Loader /> : null}
      <TopTag />
{/* <TickerTape   colorTheme="dark"></TickerTape> */}
      <Navbar />
      <div className={styles.imageContainer}  >
   {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
      <img
        src={homeImage}
        alt='welcome image'
        quality={100}
       className={styles.heroImage}

        />
        <div className={styles.loginContainer}>

      
      <div className='flex gap-3 my-2 mx-11 p-5 items-center'>
      <div>
        <img
        src={robot}
         style={{width:600, height:400, objectFit:'contain'}}
         className={styles.loginImage}
        alt='welcome image'
        quality={100}
       

        />
        </div>
        <form
         onSubmit={handleLogin}
          style={{width:'300px'}} className='flex flex-col gap-3 ' action="">
          <h1 className='text-4xl font-bold pb-6 pt-2 text-center text-white'>Login</h1>
        
          <div className={styles.inputContainer}>
          <input
           onFocus={()=> {setError(''); setRemoveEnterEmail(false)}}
            onChange={(e)=>setPassword(e.target.value)}
            placeholder='Enter Password'
            className={styles.emailInput}
           style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10}} type={isPasswordSecure ? "password" : 'name'} name='password'
            />
            {isPasswordSecure ? <FaRegEye
            onClick={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray',  fontSize:'20px', cursor:'pointer'}}/>  
                               : <FaRegEyeSlash 
                               onClick={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray', fontSize:'20px', cursor:'pointer'}}/>
             }
                  {/* <MaterialCommunityIcons 
                    onPress={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
                    name={isPasswordSecure ? "eye"  : "eye-off" }
                    size={18} color='lightgray' style={{position:'relative', left:115, bottom:50, zIndex:10}} /> */}
             {/* {removeEnterEmail && <label className={styles.emailText} htmlFor="email">Enter Password</label>} */}
          </div>
                    <Link to='/forgotPassword'>
                    <button style={{marginTop:'-15px'}} className='border-none p-2 bg-transparent text-gray-300 text-sm text-right hover:text-white'>Forget Password?</button>
                    </Link>
        
       
          <button   disabled={loading ? true : false} className={styles.btn} type="submit">Login</button>
         
          { error && <p className='px-3 py2 font-bold bg-red-500 text-white w-fit rounded-md  text-xs'>{error}</p>}
        </form>
      </div>

      </div>

   {/* { showModal &&  <div className={styles.modal}>
     < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
      <div className='flex gap-3 my-11 bg-sky-200/50 mx-11 p-5 items-center  backdrop-filter backdrop-blur-lg bg-opacity-10'>
      
      </div>

      </div> } */}


      {/* 2fa */}

      {showOTPModal && <div className={styles.modal}>

<div style={{marginTop:200}} className='flex flex-col gap-3 my-11 bg-sky-100/50 mx-11 p-7 items-center backdrop-filter backdrop-blur-lg bg-opacity-30'>


    <h2 className='text-2xl mb-7 text-center font-bold text-white'>Type in the correct token sent to your email address</h2>
    <OtpInput
      containerStyle={{ margin: 20 }}
      inputStyle={{ width: 60, height: 60, borderRadius: 10, margin: 10 }}
      shouldAutoFocus
      value={otp}
      inputType="tel"
      onChange={handleChangeOtp}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
      // renderInput={(props, index) => (
      //   <input
      //     {...props}
      //     onFocus={(e) => {
      //       // Move cursor to the end on focus
      //       e.target.setSelectionRange(e.target.value.length, e.target.value.length);
      //     }}
      //   />
      // )}
    />

    </div>

</div> 
    }

</div>
<Footer />
    </div>
  )
}

export default LoginPassword
