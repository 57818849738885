import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styles from './referralBonus.module.css'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'
import moment from 'moment';

const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'createdAt', headerName: 'Date', width: 200 },
    { field: 'walletType', headerName: 'Withdrawal Method', width: 100 },
    { field: 'amount', headerName: 'Amount Requested', width: 70 },
    // { field: 'amountCre', headerName: 'Amount Credited', width: 70 },
    { field: 'walletAddress', headerName: 'Wallet Address', width: 140 },
    // { field: 'withdrawalWallet', headerName: 'Withdrawal Wallet', width: 120 },
    // { field: 'status', headerName: 'Status', width: 100 },
      {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div style={{borderRadius:'10px'}} className='bg-green-600 p-2 text-white'>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  const rows = [
    { id: 1, date: '11 September 2023', withdrawalMethod: 'Bitcoin', amount:'$50', amountCre: '$50', walletAddress:'ghghghffcgvggv',withdrawalWallet:'Main wallet', status:'approved'},
    { id: 2, date: '14 September 2023 ', withdrawalMethod: 'Bitcoin', amount:'$50',amountCre: '$50', walletAddress:'ghghghffcgvggv',withdrawalWallet:'Main wallet', status:'approved' },
    { id: 3, date: '20 September 2023 ', withdrawalMethod: 'Bitcoin', amount:'$50',amountCre: '$50', walletAddress:'ghghghffcgvggv',withdrawalWallet:'Main wallet', status:'approved' },
    { id: 4, date: '22 September 2023 ', withdrawalMethod: 'Bitcoin', amount:'$50', amountCre: '$50', walletAddress:'ghghghffcgvggv',withdrawalWallet:'Main wallet', status:'approved' },
   
  ];
  // type MyDataGridProps = {
  //   pageSize: number;
  //   rowsPerPageOptions: number[];
  // };

const WithdrawalHistoryColumn = () => {

  const [loading, setLoading] = useState(true);
  const [formattedData, setFormattedData] = useState([]);

  const userInfo = useSelector(selectUserInfo)

  
  const [eachUserWithdrawal, setEachUserWithdrawal] = useState([])
  const [allWithdrawals, setAllWithdrawals] = useState([])
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'
 
  // const {userDetails} = useContext(UserContext);
  // console.log(userInfo)

  useEffect(() => {
    const fetchUserWithdrawal = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
  
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
  
      try {
        const response = await fetch(`${API_ENDPOINT}/api/withdrawal`, requestOptions);
        const result = await response.json();
  
        if (result) {
          setAllWithdrawals(result.withdrawals);
        }
  
        console.log(result.withdrawals);
      } catch (error) {
        console.error('Error fetching user withdrawals:', error);
      }
    };
  
    fetchUserWithdrawal();
  }, []);
  
  useEffect(() => {
    if (allWithdrawals.length !== 0) {
      const filteredUserWithdrawal = allWithdrawals.filter(detail => detail.user === userInfo.user._id);
      setEachUserWithdrawal(filteredUserWithdrawal, () => {
        console.log(eachUserWithdrawal); // This will now log the updated value
      });
    }
  }, [allWithdrawals, userInfo.user]);

  console.log(eachUserWithdrawal)

  useEffect(() => {
    // Format the createdAt field using Moment.js
    const formatted = eachUserWithdrawal.map(item => ({
      ...item,
      createdAt: moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a'),
    }));
  
    // Set the formatted data in state
    setFormattedData(formatted);
  }, [eachUserWithdrawal]);
  
  // Log the updated eachUserWithdrawal whenever it changes
  useEffect(() => {
    console.log(eachUserWithdrawal);
  }, [eachUserWithdrawal]);
  
  console.log(formattedData); // Log formattedData whenever it changes

  return (
    <div className={styles.datatable}>
    <h1 className="m-4 text-2xl">
      Withdrawal History
    </h1>
    <DataGrid
      className={styles.datagrid}
      getRowId={(row) => row._id}
      rows={formattedData} columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      GridLinesVisibility="Vertical"
    />

  </div>
  )
}

export default WithdrawalHistoryColumn
