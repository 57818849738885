import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import TopTag from '../components/TopTag'
import ContactUsColumn from '../components/ContactUsColumn'
import { TickerTape } from "react-ts-tradingview-widgets";
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import ForgetPasswordColumn from '../components/ForgetPasswordColumn';


const ForgetPassword = () => {
  const [loading, setLoading]= useState(true)
  const customCopyrightStyles = {
    display: 'none', 
    opacity: 0,
    color:'white'
  };

  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);

  return (
    <div>
      <TopTag /> 
      {loading ? <Loader /> : null}
     
        <Navbar />   
        <ForgetPasswordColumn />
      
        <Footer />
    </div>
  )
}

export default ForgetPassword
