import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styles from './accountActivities.module.css'
import { selectUserInfo } from '../slices/authSlice';
import { selectFetchedUserInfo } from '../slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'date', headerName: 'Date', width: 160 },
    { field: 'name', headerName: 'Name', width: 160 },
    { field: 'comment', headerName: 'Action', width: 250 },
    { field: 'amount', headerName: 'Amount', width: 120 },
    // { field: 'status', headerName: 'Status', width: 100 },
      {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div style={{borderRadius:'10px'}} className='bg-sky-600 p-2 text-white'>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  const rows = [
    { id: 1, date: '11 September 2023', action: 'Withdrawal from main Account', amount:'$50', status:'withdrawal'},
    { id: 2, date: '14 September 2023 ', action: 'Funding to Main Account', amount:'$50',status:'funding' },
    { id: 3, date: '20 September 2023 ', action: 'Referral Bonus from downline', amount:'$50', status:'referral' },
    { id: 4, date: '22 September 2023 ', action: 'Withdrawal of Bonus from account', amount:'$50', status:'investment' },
    // Add more data rows as needed
  ];
const AccountActivitiesColumn = () => {
  const [accountActivitiesData, setAccountActivitiesData] = useState([])
  const [accountActivitiesDataTwo, setAccountActivitiesDataTwo] = useState([])
  const [accountActivitiesDataThree, setAccountActivitiesDataThree] = useState([])
  const [accountActivitiesDataFour, setAccountActivitiesDataFour] = useState([])
  const [clickedOne, setClickedOne] = useState(true)
  const [clickedTwo, setClickedTwo] = useState(false)
  const [clickedThree, setClickedThree] = useState(false)
  const [clickedFour, setClickedFour] = useState(false)
  const [clickedFive, setClickedFive] = useState(false)


  const [loading, setLoading] = useState(true);

  const fetchedUserInfo = useSelector(selectFetchedUserInfo)
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'
 
    const [allResponses, setAllResponses] = useState([])

  // fetch responses

  useEffect(() => {
    const fetchResponses = async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_ENDPOINT}/api/response/${userInfo.user._id}`, requestOptions);
        const result = await response.json();
        console.log('called again')
        
       
         // const sortedData = result.withdrawals.sort((b, a) => a.createdAt - b.createdAt)
         const dataWithDateObjects = result.userResponses.map(item => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }));
              // Sort the array based on the createdAt property in descending order (from latest to oldest)
              const sortedData =  dataWithDateObjects.sort((a, b) => b.createdAt - a.createdAt);
              setAllResponses(sortedData);
              setLoading(false)
        console.log(result);
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchResponses()
   
  }, [userInfo.token, API_ENDPOINT]); // Add dependencies as needed

  console.log(allResponses)

  function getFirstWord(inputString) {
    // Use split to separate words
    const wordsArray = inputString.split(' ');
  
    // Retrieve the first word (element at index 0)
    const firstWord = wordsArray[0];
  
    return firstWord;
  }
    // Create a new array by mapping through the transactions array
let newData = allResponses.map((transaction) => {
  // Find the corresponding user by matching IDs
  // const user = fetchedUsers.find((user) => user._id === transaction.user);
 
  // Combine user data and transaction data
  return {
    id: transaction._id,
    name: userInfo ? userInfo.user.name : '', // Use an empty string if the user is not found
    email: userInfo ? userInfo.user.email : '',
    comment: transaction.comment,
    amount: transaction.amount,
    status:getFirstWord(transaction.comment),
    date: transaction.createdAt,
  };
});


  const handleButtonOne = ()=>{
    // const newRows =  rows.filter(row=>row.status == 'withdrawal')
    setAccountActivitiesData(rows)
    setClickedOne(true)
    setClickedTwo(false)
    setClickedThree(false)
    setClickedFour(false)
    setClickedFive(false)
  }

  const handleButtonTwo = ()=>{
    newData =  newData.filter(row=>row.status == 'Withdrawal')
    setAccountActivitiesDataTwo(newData)
    setClickedOne(false)
    setClickedTwo(true)
    setClickedThree(false)
    setClickedFour(false)
    setClickedFive(false)
  }

  const handleButtonThree = ()=>{
     newData =  newData.filter(row=>row.status == 'Funding')
    setAccountActivitiesData(newData)
    setClickedOne(false)
    setClickedTwo(false)
    setClickedThree(true)
    setClickedFour(false)
    setClickedFive(false)
  }

  const handleButtonFour = ()=>{
     newData = newData.filter(row=>row.status == 'Purchase')
    setAccountActivitiesDataThree(newData)
    setClickedOne(false)
    setClickedTwo(false)
    setClickedThree(false)
    setClickedFour(true)
    setClickedFive(false)
  }

  const handleButtonFive = ()=>{
     newData =  newData.filter(row=>row.status == 'Referral')
    setAccountActivitiesDataFour(newData)
    setClickedOne(false)
    setClickedTwo(false)
    setClickedThree(false)
    setClickedFour(false)
    setClickedFive(true)
  }


  
  return (
    <div className={styles.datatable}>
    <h1 className="m-4 text-2xl">
      Account Activities
    </h1>
    <div className={styles.buttonsContainer}>
      <div onClick={handleButtonOne} className={clickedOne ? styles.buttonClicked : styles.buttonOne}>All</div>
      <div onClick={handleButtonTwo} className={clickedTwo ?  styles.buttonClicked : styles.buttonTwo}>Withdrawals</div>
      <div onClick={handleButtonThree} className={ clickedThree ? styles.buttonClicked : styles.buttonThree}>Funding</div>
      <div onClick={handleButtonFour} className={clickedFour ? styles.buttonClicked : styles.buttonFour}>Investments</div>
      <div onClick={handleButtonFive} className={clickedFive ? styles.buttonClicked : styles.buttonFive}>Referrals</div>
    </div>
    <DataGrid
      className={styles.datagrid}
      rows={clickedThree ? accountActivitiesData : clickedTwo ? accountActivitiesDataTwo : clickedFour? accountActivitiesDataThree : clickedFive ? accountActivitiesDataFour : newData} columns={columns}
    //   columns={requestColumns.concat(actionColumn)}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      GridLinesVisibility="Vertical"
    />
   
  </div>
  )
}

export default AccountActivitiesColumn
