import React from 'react'
import Navbar from '../components/Navbar'
import TopTag from '../components/TopTag'
import ContactUsColumn from '../components/ContactUsColumn'
import { TickerTape } from "react-ts-tradingview-widgets";
import ContactMessageColumn from '../components/ContactMessageColumn';
import Footer from '../components/Footer';


const ContactMessage = () => {
  const customCopyrightStyles = {
    display: 'none', 
    opacity: 0,
    color:'white'
  };
  return (
    <div>
      <TopTag /> 
      {/* <TickerTape copyrightStyles={customCopyrightStyles}  colorTheme="dark"></TickerTape> */}
     
        <Navbar />
        <ContactMessageColumn />
        <Footer /> 
    </div>
  )
}

export default ContactMessage
