import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import FundWalletColumn from '../components/FundWalletColumn'
import WalletSlideColumn from '../components/WalletSlideColumn'
import { IoArrowUndo } from "react-icons/io5";
import {useLocation, useNavigate} from 'react-router-dom'
import styles from './transferFromWalletAmount.module.css'
import Footer from '../components/Footer'

const FundWallet = () => {
  const navigate = useNavigate()
  return (
    <div>
      <DashboardNavbar />
      <DashboardSidebar />
      {/* <WalletSlideColumn /> */}
      <div className='m-8'>
      <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
        <h1 className='text-xl font-bold ml-5 pl-10 text-gray-300 mb-2'>Fund Wallet</h1>
      <FundWalletColumn />
      </div>

      <Footer />
    </div>
  )
}

export default FundWallet
