import React, { useEffect, useState } from 'react'
import { FaWallet } from "react-icons/fa";
import './walletSlideColum.css'
const walletsArray = [
    {
        id:1,
        name: 'Daily Profit Wallet',
        balance: '$280',
        // <FaWallet className='text-4xl text-sky-800' />
    },
    {
        id:2,
        name: 'Compounding Profit Wallet',
        balance: '$2800',
        // <FaWallet className='text-4xl text-sky-800' />
    },
    {
        id:3,
        name: 'Downline Commission Wallet',
        balance: '$200',
        // <FaWallet className='text-4xl text-sky-800' />
    },
    {
        id:4,
        name: 'Referral Commission Wallet',
        balance: '$80',
        // <FaWallet className='text-4xl text-sky-800' />
    },
    {
        id:5,
        name: 'ROI Bonus Wallet',
        balance: '$280',
        // <FaWallet className='text-4xl text-sky-800' />
    },
    {
        id:6,
        name: 'Main Wallet',
        balance: '$2800',
        // <FaWallet className='text-4xl text-sky-800' />
    },
]

const WalletSlideColumn = () => {

    const [curr, setCurr] = useState(0);

    let autoSlide = true;
    let autoSlideInterval = 5000;

    const prev = () =>
    setCurr((curr) => (curr === 0 ? walletsArray.length - 1 : curr - 1))
  const next = () =>
    setCurr((curr) => (curr === walletsArray.length - 1 ? 0 : curr + 1))

  useEffect(() => {
    if (!autoSlide) return
    const slideInterval = setInterval(next, autoSlideInterval)
    return () => clearInterval(slideInterval)
  }, [])

  return (
    <div className='walletSlide-container'>
     
    {/* <div className="next-prev">
      <ArrowBackIosIcon onClick={prev} className='test-button' />
      <ArrowForwardIosIcon onClick={next} className='test-button' />
    </div> */}

    {
      walletsArray.map((walletData,index)=>(
        <div key={walletData.id} className={index === curr ? 'walletSlide activate' : 'walletSlide'}>

      {index === curr && (
        <>
             <p>{walletData.name}</p>
            <h3>{walletData.balance}</h3>
            <FaWallet className='text-3xl text-sky-800 text-center mx-auto p-2 bg-green-400 rounded-full' />
          </>
      )}
        </div>
      ))
    }
    
  </div>
  )
}

export default WalletSlideColumn
