import { createContext, useEffect, useReducer } from "react"

const INITIAL_STATE = {
    userDetails: JSON.parse(localStorage.getItem("userDetails")) || null,
   
}


export const UserContext = createContext(INITIAL_STATE)

const UserReducer = (state, action) =>{
    switch(action.type){
     
       case"USER_SAVED":
       return {
        userDetails: action.payload,
       };
      
        default:
           return state
    }
}

export const UserContextProvider = (({children})=>{
    const [state, dispatch] = useReducer(UserReducer, INITIAL_STATE)


    useEffect(()=>{
        localStorage.setItem("userDetails", JSON.stringify(state.userDetails))
    },[state.userDetails])

    return (
        <UserContext.Provider 
        value={{
         userDetails:state.userDetails,
          dispatch}}>
            {children}
        </UserContext.Provider>
    )
})