import React, { useEffect, useState } from 'react'
import styles from './transfersColumn.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import moment from 'moment';
import purchaseImage from '../assets/purchasePortfolio.jpg'
import {useNavigate} from 'react-router-dom'

const PurchasePortfoliosColumn = () => {

    const [showModal, setShowModal] = useState(false)
    const [showROI, setShowROI] = useState(false)
    const [amount, setAmount] = useState('');
    const [dailyROI, setDailyROI] = useState(0);
    // const [compoundingROI, setCompoundingROI] = useState(0);
    const [compoundingROI, setCompoundingROI] = useState(0);

    const [tomorrowProjection, setTomorrowProjection] = useState(0)
    const [nextTomorrowProjection, setNextTomorrowProjection] = useState(0)
    const [nextNexttomorrowProjection, setNextNextTomorrowProjection] = useState(0)
    const [fourthDayProjection, setFourthDayProjection] = useState(0)
    const [fifthDayProjection, setFifthDayProjection] = useState(0)

    const [tomorrowCompoundingProjection, setTomorrowCompoundingProjection] = useState(0)
    const [nextTomorrowCompoundingProjection, setNextTomorrowCompoundingProjection] = useState(0)
    const [nextNexttomorrowCompoundingProjection, setNextNextTomorrowCompoundingProjection] = useState(0)
    const [fourthDayCompoundingProjection, setFourthDayCompoundingProjection] = useState(0)
    const [fifthDayCompoundingProjection, setFifthDayCompoundingProjection] = useState(0)
 
    const navigate = useNavigate()

    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)
// function getTomorrowDate(): moment.Moment {
//   const today = moment();
//   const tomorrow = today.add(1, 'day');
//   console.log(tomorrow)
//   return tomorrow;
// }

// getTomorrowDate()
let tomorrow = moment().add(1, 'days').format('DD MMMM YYYY').toString();
let nextTomorrow = moment().add(2, 'days').format('DD MMMM YYYY').toString();
let nextNextTomorrow = moment().add(3, 'days').format('DD MMMM YYYY').toString();
let fourthDay = moment().add(4, 'days').format('DD MMMM YYYY').toString();
let fifthDay = moment().add(5, 'days').format('DD MMMM YYYY').toString();


console.log(tomorrow)
console.log(nextTomorrow)
console.log(nextNextTomorrow)
    function handleChange(e) {
       
        // setAmount((prevAmount) => ({
        //     ...prevAmount,
        //     [e.target.name]: e.target.value,
        // }));
        setAmount(e.target.value)
        console.log(amount)

      }

      const numbersArrayROIOne = [0.5, 0.58, 0.60, 0.62, 0.73, 0.75];
      const numbersArrayROITwo = [0.76, 0.79, 0.80, 0.81, 0.87, 0.95];
      const numbersArrayROIThree = [0.96, 0.98, 1.08, 1.15, 1.23, 1.3];
      const numbersArrayROIFour = [1.385, 1.35, 1.48, 1.54, 1.6, 1.65];
      const numbersArrayROIFive = [1.75, 1.85, 1.92, 1.99, 2.0, 2.1];
      const numbersArrayROISix = [2.2, 2.25, 2.38, 2.46, 2.48, 2.5];
      const numbersArrayROISeven = [2.45, 2.65, 2.78, 2.96, 3.3, 3.5];

      const numbersCompoundingArrayROIOne = [0.9, 0.92, 0.94, 0.96, 0.98, 1.0];
      const numbersCompoundingArrayROITwo = [1.1, 1.09, 1.14, 1.18, 1.19, 1.2];
      const numbersCompoundingArrayROIThree = [1.3, 1.4, 1.58, 1.65, 1.68, 1.7];
      const numbersCompoundingArrayROIFour = [1.85, 1.89, 1.90, 1.94, 1.9, 2.0];
      const numbersCompoundingArrayROIFive = [2.05, 2.15, 2.22, 2.39, 2.5, 2.65];
      const numbersCompoundingArrayROISix = [2.67, 2.75, 2.78, 2.86, 2.89, 2.95];
      const numbersCompoundingArrayROISeven = [3.15, 4.65, 4.18, 4.96, 3.3, 5.0];


    const handleSubmitAmount =(e)=>{
      e.preventDefault()
    //   setShowModal(true)

    if(!amount){
      setPopUp('Type in an amount to proceed')
      setShowPopupModal(true)
     return
    }
 
      console.log(amount)
        if(Number(amount) <= 500){

            const randomIndex = Math.floor(Math.random() * numbersArrayROIOne.length);
            const randomNum = numbersArrayROIOne[randomIndex];

            setDailyROI(randomNum)
            
            // projections
            const randomTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIOne.length);
            const randomTomorrowProjectionNum = numbersArrayROIOne[randomTomorrowProjectionIndex];

            setTomorrowProjection(randomTomorrowProjectionNum)

            const randomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIOne.length);
            const randomNextTomorrowProjectionNum = numbersArrayROIOne[randomNextTomorrowProjectionIndex];

            setNextTomorrowProjection(randomNextTomorrowProjectionNum)


            const randomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIOne.length);
            const randomNextNextTomorrowProjectionNum = numbersArrayROIOne[randomNextNextTomorrowProjectionIndex];

            setNextNextTomorrowProjection(randomNextNextTomorrowProjectionNum)

            const fourthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROIOne.length);
            const randomFourthDayProjectionNum = numbersArrayROIOne[fourthDayProjectionIndex];

            setFourthDayProjection(randomFourthDayProjectionNum)

            const fifthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROIOne.length);
            const randomFifthDayProjectionNum = numbersArrayROIOne[fifthDayProjectionIndex];

            setFifthDayProjection(randomFifthDayProjectionNum)


            // compounding
            const randomCompoundingIndex = Math.floor(Math.random() * numbersCompoundingArrayROIOne.length);
            const randomCompoundingNum = numbersCompoundingArrayROIOne[randomCompoundingIndex];

            setCompoundingROI(randomCompoundingNum)

                 // compounding projections
                 const coumpoundingrandomTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIOne.length);
                 const coumpoundingrandomTomorrowProjectionNum = numbersCompoundingArrayROIOne[coumpoundingrandomTomorrowProjectionIndex];
     
                 setTomorrowCompoundingProjection(coumpoundingrandomTomorrowProjectionNum)
     
                 const coumpoundingrandomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIOne.length);
                 const coumpoundingrandomNextTomorrowProjectionNum = numbersCompoundingArrayROIOne[coumpoundingrandomNextTomorrowProjectionIndex];
     
                 setNextTomorrowCompoundingProjection(coumpoundingrandomNextTomorrowProjectionNum)
     
     
                 const coumpoundingrandomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIOne.length);
                 const coumpoundingrandomNextNextTomorrowProjectionNum = numbersCompoundingArrayROIOne[coumpoundingrandomNextNextTomorrowProjectionIndex];
     
                 setNextNextTomorrowCompoundingProjection(coumpoundingrandomNextNextTomorrowProjectionNum)
     
                 const coumpoundingfourthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIOne.length);
                 const coumpoundingrandomFourthDayProjectionNum = numbersCompoundingArrayROIOne[coumpoundingfourthDayProjectionIndex];
     
                 setFourthDayCompoundingProjection(coumpoundingrandomFourthDayProjectionNum)
     
                 const coumpoundingfifthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIOne.length);
                 const coumpoundingrandomFifthDayProjectionNum = numbersCompoundingArrayROIOne[coumpoundingfifthDayProjectionIndex];
     
                 setFifthDayCompoundingProjection(coumpoundingrandomFifthDayProjectionNum)

        } else if(Number(amount) > 500 && Number(amount) <= 5000){

            const randomIndex = Math.floor(Math.random() * numbersArrayROITwo.length);
            const randomNum = numbersArrayROITwo[randomIndex];

            setDailyROI(randomNum)

                        // projections
                        const randomTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROITwo.length);
                        const randomTomorrowProjectionNum = numbersArrayROITwo[randomTomorrowProjectionIndex];
            
                        setTomorrowProjection(randomTomorrowProjectionNum)
            
                        const randomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROITwo.length);
                        const randomNextTomorrowProjectionNum = numbersArrayROITwo[randomNextTomorrowProjectionIndex];
            
                        setNextTomorrowProjection(randomNextTomorrowProjectionNum)
            
            
                        const randomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROITwo.length);
                        const randomNextNextTomorrowProjectionNum = numbersArrayROITwo[randomNextNextTomorrowProjectionIndex];
            
                        setNextNextTomorrowProjection(randomNextNextTomorrowProjectionNum)

                        const fourthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROITwo.length);
                        const randomFourthDayProjectionNum = numbersArrayROITwo[fourthDayProjectionIndex];
            
                        setFourthDayProjection(randomFourthDayProjectionNum)
            
                        const fifthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROITwo.length);
                        const randomFifthDayProjectionNum = numbersArrayROITwo[fifthDayProjectionIndex];
            
                        setFifthDayProjection(randomFifthDayProjectionNum)
            
            
                        // compounding


            const randomCompoundingIndex = Math.floor(Math.random() * numbersCompoundingArrayROITwo.length);
            const randomCompoundingNum = numbersCompoundingArrayROITwo[randomCompoundingIndex];

            setCompoundingROI(randomCompoundingNum)

              // compounding projections
              const coumpoundingrandomTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROITwo.length);
              const coumpoundingrandomTomorrowProjectionNum = numbersCompoundingArrayROITwo[coumpoundingrandomTomorrowProjectionIndex];
  
              setTomorrowCompoundingProjection(coumpoundingrandomTomorrowProjectionNum)
  
              const coumpoundingrandomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROITwo.length);
              const coumpoundingrandomNextTomorrowProjectionNum = numbersCompoundingArrayROITwo[coumpoundingrandomNextTomorrowProjectionIndex];
  
              setNextTomorrowCompoundingProjection(coumpoundingrandomNextTomorrowProjectionNum)
  
  
              const coumpoundingrandomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROITwo.length);
              const coumpoundingrandomNextNextTomorrowProjectionNum = numbersCompoundingArrayROITwo[coumpoundingrandomNextNextTomorrowProjectionIndex];
  
              setNextNextTomorrowCompoundingProjection(coumpoundingrandomNextNextTomorrowProjectionNum)
  
              const coumpoundingfourthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROITwo.length);
              const coumpoundingrandomFourthDayProjectionNum = numbersCompoundingArrayROITwo[coumpoundingfourthDayProjectionIndex];
  
              setFourthDayCompoundingProjection(coumpoundingrandomFourthDayProjectionNum)
  
              const coumpoundingfifthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROITwo.length);
              const coumpoundingrandomFifthDayProjectionNum = numbersCompoundingArrayROITwo[coumpoundingfifthDayProjectionIndex];
  
              setFifthDayCompoundingProjection(coumpoundingrandomFifthDayProjectionNum)

        } else if(Number(amount) > 5000 && Number(amount) <= 25000){

            const randomIndex = Math.floor(Math.random() * numbersArrayROIThree.length);
            const randomNum = numbersArrayROIThree[randomIndex];

            setDailyROI(randomNum)

                        // projections
                        const randomTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIThree.length);
                        const randomTomorrowProjectionNum = numbersArrayROIThree[randomTomorrowProjectionIndex];
            
                        setTomorrowProjection(randomTomorrowProjectionNum)
            
                        const randomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIThree.length);
                        const randomNextTomorrowProjectionNum = numbersArrayROIThree[randomNextTomorrowProjectionIndex];
            
                        setNextTomorrowProjection(randomNextTomorrowProjectionNum)
            
            
                        const randomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIThree.length);
                        const randomNextNextTomorrowProjectionNum = numbersArrayROIThree[randomNextNextTomorrowProjectionIndex];
            
                        setNextNextTomorrowProjection(randomNextNextTomorrowProjectionNum)

                        const fourthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROIThree.length);
                        const randomFourthDayProjectionNum = numbersArrayROIThree[fourthDayProjectionIndex];
            
                        setFourthDayProjection(randomFourthDayProjectionNum)
            
                        const fifthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROIThree.length);
                        const randomFifthDayProjectionNum = numbersArrayROIThree[fifthDayProjectionIndex];
            
                        setFifthDayProjection(randomFifthDayProjectionNum)
            
            
                        // compounding


            const randomCompoundingIndex = Math.floor(Math.random() * numbersCompoundingArrayROIThree.length);
            const randomCompoundingNum = numbersCompoundingArrayROIThree[randomCompoundingIndex];

            setCompoundingROI(randomCompoundingNum)

              // compounding projections
              const coumpoundingrandomTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIThree.length);
              const coumpoundingrandomTomorrowProjectionNum = numbersCompoundingArrayROIThree[coumpoundingrandomTomorrowProjectionIndex];
  
              setTomorrowCompoundingProjection(coumpoundingrandomTomorrowProjectionNum)
  
              const coumpoundingrandomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIThree.length);
              const coumpoundingrandomNextTomorrowProjectionNum = numbersCompoundingArrayROIThree[coumpoundingrandomNextTomorrowProjectionIndex];
  
              setNextTomorrowCompoundingProjection(coumpoundingrandomNextTomorrowProjectionNum)
  
  
              const coumpoundingrandomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIThree.length);
              const coumpoundingrandomNextNextTomorrowProjectionNum = numbersCompoundingArrayROIThree[coumpoundingrandomNextNextTomorrowProjectionIndex];
  
              setNextNextTomorrowCompoundingProjection(coumpoundingrandomNextNextTomorrowProjectionNum)
  
              const coumpoundingfourthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIThree.length);
              const coumpoundingrandomFourthDayProjectionNum = numbersCompoundingArrayROIThree[coumpoundingfourthDayProjectionIndex];
  
              setFourthDayCompoundingProjection(coumpoundingrandomFourthDayProjectionNum)
  
              const coumpoundingfifthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIThree.length);
              const coumpoundingrandomFifthDayProjectionNum = numbersCompoundingArrayROIThree[coumpoundingfifthDayProjectionIndex];
  
              setFifthDayCompoundingProjection(coumpoundingrandomFifthDayProjectionNum)

        } else if(Number(amount) > 25000 && Number(amount) <= 100000){

            const randomIndex = Math.floor(Math.random() * numbersArrayROIFour.length);
            const randomNum = numbersArrayROIFour[randomIndex];

            setDailyROI(randomNum)

                        // projections
                        const randomTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIFour.length);
                        const randomTomorrowProjectionNum = numbersArrayROIFour[randomTomorrowProjectionIndex];
            
                        setTomorrowProjection(randomTomorrowProjectionNum)
            
                        const randomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIFour.length);
                        const randomNextTomorrowProjectionNum = numbersArrayROIFour[randomNextTomorrowProjectionIndex];
            
                        setNextTomorrowProjection(randomNextTomorrowProjectionNum)
            
            
                        const randomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIFour.length);
                        const randomNextNextTomorrowProjectionNum = numbersArrayROIFour[randomNextNextTomorrowProjectionIndex];
            
                        setNextNextTomorrowProjection(randomNextNextTomorrowProjectionNum)

                        const fourthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROIFour.length);
                        const randomFourthDayProjectionNum = numbersArrayROIFour[fourthDayProjectionIndex];
            
                        setFourthDayProjection(randomFourthDayProjectionNum)
            
                        const fifthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROIFour.length);
                        const randomFifthDayProjectionNum = numbersArrayROIFour[fifthDayProjectionIndex];
            
                        setFifthDayProjection(randomFifthDayProjectionNum)
            
            
                        // compounding


            const randomCompoundingIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFour.length);
            const randomCompoundingNum = numbersCompoundingArrayROIFour[randomCompoundingIndex];

            setCompoundingROI(randomCompoundingNum)

              // compounding projections
              const coumpoundingrandomTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFour.length);
              const coumpoundingrandomTomorrowProjectionNum = numbersCompoundingArrayROIFour[coumpoundingrandomTomorrowProjectionIndex];
  
              setTomorrowCompoundingProjection(coumpoundingrandomTomorrowProjectionNum)
  
              const coumpoundingrandomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFour.length);
              const coumpoundingrandomNextTomorrowProjectionNum = numbersCompoundingArrayROIFour[coumpoundingrandomNextTomorrowProjectionIndex];
  
              setNextTomorrowCompoundingProjection(coumpoundingrandomNextTomorrowProjectionNum)
  
  
              const coumpoundingrandomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFour.length);
              const coumpoundingrandomNextNextTomorrowProjectionNum = numbersCompoundingArrayROIFour[coumpoundingrandomNextNextTomorrowProjectionIndex];
  
              setNextNextTomorrowCompoundingProjection(coumpoundingrandomNextNextTomorrowProjectionNum)
  
              const coumpoundingfourthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFour.length);
              const coumpoundingrandomFourthDayProjectionNum = numbersCompoundingArrayROIFour[coumpoundingfourthDayProjectionIndex];
  
              setFourthDayCompoundingProjection(coumpoundingrandomFourthDayProjectionNum)
  
              const coumpoundingfifthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFour.length);
              const coumpoundingrandomFifthDayProjectionNum = numbersCompoundingArrayROIFour[coumpoundingfifthDayProjectionIndex];
  
              setFifthDayCompoundingProjection(coumpoundingrandomFifthDayProjectionNum)

        } else if(Number(amount) > 100000 && Number(amount) <= 500000){

            const randomIndex = Math.floor(Math.random() * numbersArrayROIFive.length);
            const randomNum = numbersArrayROIFive[randomIndex];

            setDailyROI(randomNum)

                        // projections
                        const randomTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIFive.length);
                        const randomTomorrowProjectionNum = numbersArrayROIFive[randomTomorrowProjectionIndex];
            
                        setTomorrowProjection(randomTomorrowProjectionNum)
            
                        const randomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIFive.length);
                        const randomNextTomorrowProjectionNum = numbersArrayROIFive[randomNextTomorrowProjectionIndex];
            
                        setNextTomorrowProjection(randomNextTomorrowProjectionNum)
            
            
                        const randomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROIFive.length);
                        const randomNextNextTomorrowProjectionNum = numbersArrayROIFive[randomNextNextTomorrowProjectionIndex];
            
                        setNextNextTomorrowProjection(randomNextNextTomorrowProjectionNum)

                        const fourthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROIFive.length);
                        const randomFourthDayProjectionNum = numbersArrayROIFive[fourthDayProjectionIndex];
            
                        setFourthDayProjection(randomFourthDayProjectionNum)
            
                        const fifthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROIFive.length);
                        const randomFifthDayProjectionNum = numbersArrayROIFive[fifthDayProjectionIndex];
            
                        setFifthDayProjection(randomFifthDayProjectionNum)
            
            
                        // compounding


            const randomCompoundingIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFive.length);
            const randomCompoundingNum = numbersCompoundingArrayROIFive[randomCompoundingIndex];

            setCompoundingROI(randomCompoundingNum)

              // compounding projections
              const coumpoundingrandomTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFive.length);
              const coumpoundingrandomTomorrowProjectionNum = numbersCompoundingArrayROIFive[coumpoundingrandomTomorrowProjectionIndex];
  
              setTomorrowCompoundingProjection(coumpoundingrandomTomorrowProjectionNum)
  
              const coumpoundingrandomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFive.length);
              const coumpoundingrandomNextTomorrowProjectionNum = numbersCompoundingArrayROIFive[coumpoundingrandomNextTomorrowProjectionIndex];
  
              setNextTomorrowCompoundingProjection(coumpoundingrandomNextTomorrowProjectionNum)
  
  
              const coumpoundingrandomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFive.length);
              const coumpoundingrandomNextNextTomorrowProjectionNum = numbersCompoundingArrayROIFive[coumpoundingrandomNextNextTomorrowProjectionIndex];
  
              setNextNextTomorrowCompoundingProjection(coumpoundingrandomNextNextTomorrowProjectionNum)
  
              const coumpoundingfourthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFive.length);
              const coumpoundingrandomFourthDayProjectionNum = numbersCompoundingArrayROIFive[coumpoundingfourthDayProjectionIndex];
  
              setFourthDayCompoundingProjection(coumpoundingrandomFourthDayProjectionNum)
  
              const coumpoundingfifthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROIFive.length);
              const coumpoundingrandomFifthDayProjectionNum = numbersCompoundingArrayROIFive[coumpoundingfifthDayProjectionIndex];
  
              setFifthDayCompoundingProjection(coumpoundingrandomFifthDayProjectionNum)

        } else if(Number(amount) > 500000 && Number(amount) <= 1000000){

            const randomIndex = Math.floor(Math.random() * numbersArrayROISix.length);
            const randomNum = numbersArrayROISix[randomIndex];

            setDailyROI(randomNum)

                        // projections
                        const randomTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROISix.length);
                        const randomTomorrowProjectionNum = numbersArrayROISix[randomTomorrowProjectionIndex];
            
                        setTomorrowProjection(randomTomorrowProjectionNum)
            
                        const randomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROISix.length);
                        const randomNextTomorrowProjectionNum = numbersArrayROISix[randomNextTomorrowProjectionIndex];
            
                        setNextTomorrowProjection(randomNextTomorrowProjectionNum)
            
            
                        const randomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROISix.length);
                        const randomNextNextTomorrowProjectionNum = numbersArrayROISix[randomNextNextTomorrowProjectionIndex];
            
                        setNextNextTomorrowProjection(randomNextNextTomorrowProjectionNum)

                        const fourthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROISix.length);
                        const randomFourthDayProjectionNum = numbersArrayROISix[fourthDayProjectionIndex];
            
                        setFourthDayProjection(randomFourthDayProjectionNum)
            
                        const fifthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROISix.length);
                        const randomFifthDayProjectionNum = numbersArrayROISix[fifthDayProjectionIndex];
            
                        setFifthDayProjection(randomFifthDayProjectionNum)
            
            
                        // compounding
                        

            const randomCompoundingIndex = Math.floor(Math.random() * numbersCompoundingArrayROISix.length);
            const randomCompoundingNum = numbersCompoundingArrayROISix[randomCompoundingIndex];

            setCompoundingROI(randomCompoundingNum)

              // compounding projections
              const coumpoundingrandomTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROISix.length);
              const coumpoundingrandomTomorrowProjectionNum = numbersCompoundingArrayROISix[coumpoundingrandomTomorrowProjectionIndex];
  
              setTomorrowCompoundingProjection(coumpoundingrandomTomorrowProjectionNum)
  
              const coumpoundingrandomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROISix.length);
              const coumpoundingrandomNextTomorrowProjectionNum = numbersCompoundingArrayROISix[coumpoundingrandomNextTomorrowProjectionIndex];
  
              setNextTomorrowCompoundingProjection(coumpoundingrandomNextTomorrowProjectionNum)
  
  
              const coumpoundingrandomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROISix.length);
              const coumpoundingrandomNextNextTomorrowProjectionNum = numbersCompoundingArrayROISix[coumpoundingrandomNextNextTomorrowProjectionIndex];
  
              setNextNextTomorrowCompoundingProjection(coumpoundingrandomNextNextTomorrowProjectionNum)
  
              const coumpoundingfourthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROISix.length);
              const coumpoundingrandomFourthDayProjectionNum = numbersCompoundingArrayROISix[coumpoundingfourthDayProjectionIndex];
  
              setFourthDayCompoundingProjection(coumpoundingrandomFourthDayProjectionNum)
  
              const coumpoundingfifthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROISix.length);
              const coumpoundingrandomFifthDayProjectionNum = numbersCompoundingArrayROISix[coumpoundingfifthDayProjectionIndex];
  
              setFifthDayCompoundingProjection(coumpoundingrandomFifthDayProjectionNum)

        } else if(Number(amount) > 1000000 ){

            const randomIndex = Math.floor(Math.random() * numbersArrayROISeven.length);
            const randomNum = numbersArrayROISeven[randomIndex];

            setDailyROI(randomNum)

                        // projections
                        const randomTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROISeven.length);
                        const randomTomorrowProjectionNum = numbersArrayROISeven[randomTomorrowProjectionIndex];
            
                        setTomorrowProjection(randomTomorrowProjectionNum)
            
                        const randomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROISeven.length);
                        const randomNextTomorrowProjectionNum = numbersArrayROISeven[randomNextTomorrowProjectionIndex];
            
                        setNextTomorrowProjection(randomNextTomorrowProjectionNum)
            
            
                        const randomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersArrayROISeven.length);
                        const randomNextNextTomorrowProjectionNum = numbersArrayROISeven[randomNextNextTomorrowProjectionIndex];
            
                        setNextNextTomorrowProjection(randomNextNextTomorrowProjectionNum)

                        const fourthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROISeven.length);
                        const randomFourthDayProjectionNum = numbersArrayROISeven[fourthDayProjectionIndex];
            
                        setFourthDayProjection(randomFourthDayProjectionNum)
            
                        const fifthDayProjectionIndex = Math.floor(Math.random() * numbersArrayROISeven.length);
                        const randomFifthDayProjectionNum = numbersArrayROISeven[fifthDayProjectionIndex];
            
                        setFifthDayProjection(randomFifthDayProjectionNum)
            
            
                        // compounding


            const randomCompoundingIndex = Math.floor(Math.random() * numbersCompoundingArrayROISeven.length);
            const randomCompoundingNum = numbersCompoundingArrayROISeven[randomCompoundingIndex];

            setCompoundingROI(randomCompoundingNum)

              // compounding projections
              const coumpoundingrandomTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROISeven.length);
              const coumpoundingrandomTomorrowProjectionNum = numbersCompoundingArrayROISeven[coumpoundingrandomTomorrowProjectionIndex];
  
              setTomorrowCompoundingProjection(coumpoundingrandomTomorrowProjectionNum)
  
              const coumpoundingrandomNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROISeven.length);
              const coumpoundingrandomNextTomorrowProjectionNum = numbersCompoundingArrayROISeven[coumpoundingrandomNextTomorrowProjectionIndex];
  
              setNextTomorrowCompoundingProjection(coumpoundingrandomNextTomorrowProjectionNum)
  
  
              const coumpoundingrandomNextNextTomorrowProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROISeven.length);
              const coumpoundingrandomNextNextTomorrowProjectionNum = numbersCompoundingArrayROISeven[coumpoundingrandomNextNextTomorrowProjectionIndex];
  
              setNextNextTomorrowCompoundingProjection(coumpoundingrandomNextNextTomorrowProjectionNum)
  
              const coumpoundingfourthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROISeven.length);
              const coumpoundingrandomFourthDayProjectionNum = numbersCompoundingArrayROISeven[coumpoundingfourthDayProjectionIndex];
  
              setFourthDayCompoundingProjection(coumpoundingrandomFourthDayProjectionNum)
  
              const coumpoundingfifthDayProjectionIndex = Math.floor(Math.random() * numbersCompoundingArrayROISeven.length);
              const coumpoundingrandomFifthDayProjectionNum = numbersCompoundingArrayROISeven[coumpoundingfifthDayProjectionIndex];
  
              setFifthDayCompoundingProjection(coumpoundingrandomFifthDayProjectionNum)
        }

       
        setShowROI(true)
    }


    useEffect(()=>{
      if (showROI){

        navigate('/portfoliosRates', {
          state: {
            amount:amount,
            dailyROI: dailyROI,
            compoundingROI: compoundingROI,
            tomorrow: tomorrow,
            tomorrowProjection: tomorrowProjection,
            tomorrowCompoundingProjection: tomorrowCompoundingProjection,
            nextTomorrow: nextTomorrow,
            nextTomorrowProjection: nextTomorrowProjection,
            nextTomorrowCompoundingProjection: nextTomorrowCompoundingProjection,
            nextNextTomorrow: nextNextTomorrow,
            nextNextTomorrowProjection: nextNexttomorrowProjection,
            nextNextTomorrowCompoundingProjection: nextNexttomorrowCompoundingProjection,
            fourthDay:fourthDay,
            fifthDay:fifthDay,
            fourthDayProjection: fourthDayProjection,
            fourthDayCompoundingProjection: fourthDayCompoundingProjection,
            fifthDayProjection:fifthDayProjection,
            fifthDayCompoundingProjection:fifthDayCompoundingProjection,
          },
        });

}
   
    },[dailyROI,compoundingROI, tomorrow, tomorrowProjection, nextTomorrow, nextTomorrowProjection, nextNexttomorrowProjection,fourthDay, fifthDay])


    const handleClosePopup =()=>{
      setShowPopupModal(false)
       setPopUp('')
      }

  return (
    <div className={styles.transferImagesContainer}>

         {/* popup */}
    { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

    <img
            src={purchaseImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
            <div className={styles.transferContainer}>
<form style={{width:'300px', margin:'auto'}} className='flex flex-col gap-3 ' action="">
  <h1 className='text-4xl font-bold pb-6 pt-2 text-center text-white'>Purchase Portfolio</h1>
  {/* <label className={styles.emailText} htmlFor="email">Amount</label> */}
  <input value={amount} onChange={handleChange} placeholder='Amount' style={{backgroundColor:'white', height:'40px', width:'100%', paddingLeft:20, outline:'none'}} type="number" name='amount' />
<button onClick={handleSubmitAmount} className={styles.loginButton} type="submit">Next</button>

</form>
</div>

{/* { showROI &&  <div  className={styles.modal}>
< FaRegTimesCircle onClick={()=>setShowROI(false)} className={styles.close} />
<div style={{width:'60%', margin:'50px auto'}} className='my-11 bg-sky-200 mx-11 p-5'>

<div  className='flex gap-3  items-center'>
<div>
<img
src={robot}
style={{ width: 300, height: 200, zIndex:10, objectFit:'contain' }} // optional
alt='welcome image'
/>
</div>
<form style={{width:'400px'}} className='flex flex-col gap-3 ' action="">
  <h1 className='text-2xl font-bold pb-6 pt-2 text-center'>Return on Investment(ROI)</h1>
  <p>ROI&apos;s are generated by AI based on todays market and projection for the future for maximum profit</p>
  <label className={styles.emailText} htmlFor="email">Daily Profit</label>
  <input disabled value={dailyROI} style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:20, fontWeight:'bold', textAlign:'center'}} type="number" name='roi' />
  <label className={styles.emailText} htmlFor="email">Compounding Profit</label>
  <input disabled value={compoundingROI} style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:20, fontWeight:'bold', textAlign:'center'}} type="number" name='roi' />
 
  <button className={styles.loginButton} type="submit">Submit</button>

</form>
</div>

<div style={{margin:'20px auto'}} className='flex flex-col items-center'>
    <h1 className='text-xl font-bold'>ROI Projection for the next few days</h1>
  
    <div style={{width:'80%'}} className='flex justify-between items-center bg-white p-2 m-1 shadow-md'>
    <p>{tomorrow}</p>
    <p> {tomorrowProjection}% on ROI</p>
    </div>

    <div style={{width:'80%'}} className='flex justify-between items-center bg-yellow-50 p-2 m-1 shadow-md'>
    <p>{nextTomorrow}</p>
    <p> {nextTomorrowProjection}% on ROI</p>
    </div>

    <div style={{width:'80%'}} className='flex justify-between items-center bg-purple-50 p-2 m-1 shadow-md'>
    <p>{nextNextTomorrow}</p>
    <p> {nextNexttomorrowProjection}% on ROI</p>
    </div>
      
  
</div>

</div>
</div> } */}



</div>

  )
}

export default PurchasePortfoliosColumn
