import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styles from './referralBonus.module.css'



const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'date', headerName: 'Date', width: 280 },
    { field: 'bonusDetails', headerName: 'Bonus Details', width: 300 },
    { field: 'amount', headerName: 'Bonus Amount', width: 120 },
  ];
  const rows = [
    { id: 1, date: '11 September 2023 , 10 : 02am', bonusDetails: 'Referral Bonus of 10%, 1st level', amount:'$50' },
    { id: 2, date: '14 September 2023 , 10 : 02am', bonusDetails: 'Referral Funding Bonus of 5%, 2nd level', amount:'$50' },
    { id: 3, date: '20 September 2023 , 10 : 02am', bonusDetails: 'Referral Funding Bonus of 5%, 2nd level', amount:'$50' },
    { id: 4, date: '22 September 2023 , 10 : 02am', bonusDetails: 'Referral Funding Bonus of 5%, 2nd level', amount:'$50' },
    // Add more data rows as needed
  ];

const ReferralData = () => {
  return (
    <div className={styles.datatable}>
    <h1 className="m-4 text-2xl">
      Referral Bonus
    </h1>
    <DataGrid
      className={styles.datagrid}
      rows={rows} columns={columns}
    //   columns={requestColumns.concat(actionColumn)}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      GridLinesVisibility="Vertical"
    />

  </div>
  )
}

export default ReferralData
