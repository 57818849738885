
import React, { useRef, useState, useContext, useEffect } from 'react'
import Navbar from '../components/Navbar'
import styles from './login.module.css'
import axios from 'axios';
import { FaRegTimesCircle } from 'react-icons/fa'

import homeImage from '../assets/homeImage.jpg'
// import robot from '../assets/robot.jpg'
import robot from '../assets/robot.png'
import { Link, useNavigate } from 'react-router-dom'
import { TickerTape } from "react-ts-tradingview-widgets";
import TopTag from '../components/TopTag'
import { AuthContext } from '../context/AuthContext';
import Footer from '../components/Footer';
import OtpInput from 'react-otp-input';
import Loader from '../components/Loader';
import { Player } from '@lottiefiles/react-lottie-player';
import inputSRC from '../assets/loadingAnimation.json'
import ActivatorSpinner from '../assets/activatorSpinnerAni.json'

const LoginPage = () => {
  const [showModal, setShowModal] = useState(false)
  const [showOTPModal, setShowOTPModal] = useState(false)
  const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const {user, err, dispatch} = useContext(AuthContext);
  const timeoutRef = useRef(null);
  const navigate = useNavigate()
  const [otp, setOtp] = useState('');
  const [emailSpinner, setEmailSpinner] = useState(false)
  const [showLoginLoading, setShowLoginLoading] = useState(false)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'
  const [savedOTP, setSavedOTP] = useState('')
// let timer;
//   const autoSubmitEmail =async()=>{
//     console.log('submitted--- on key up')
//     clearTimeout(timer)
//    timer = setTimeout(async()=>{

//       try {
//         const userExist = await fetch('api/userExists', {
//           method: "POST",
//           headers: {
//             "Content-Type": 'application/json'
//           },
//           body: JSON.stringify({
//             email 
//           })
//         })
  
//         const {user} = await userExist.json()
//         if(!user){
  
//           // setError('User does not exist, Sign up.')
//           setPopUp('User does not exist, Edit your email or Sign up if you dont have an account.')
//           setShowPopupModal(true)
//           return 
//         }
//         setShowModal(true)
//       } catch (error) {
        
//       }
//       console.log('submitted--- after few seconds')

//     },10000)
   
 
//   }

// Type for the function to be debounced
// type FunctionToDebounce<T extends (...args: any[]) => void> = (...args: Parameters<T>) => void;

// Debounce function
// const debounce = (func, delay) => {
//     let timeoutId;
  
//     return (...args) => {
//       clearTimeout(timeoutId);
//       timeoutId = setTimeout(() => func(...args), delay);
//     };
//   };

// Example usage with the autoSubmitEmail function
// const debouncedAutoSubmitEmail = debounce(autoSubmitEmail, 10000);

  // const handleShowPasswordForm =async(e)=>{
  //   e.preventDefault()
  //   if(!email) {
  //     setError('Fill in your email address')
  //     setPopUp('Fill in your registered email address')
  //       setShowPopupModal(true)
  //     return
  //   }


//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }

//     timeoutRef.current = window.setTimeout(autoSubmitEmail, 3000); // 30 seconds in milliseconds

 
//     try {
//       const userExist = await fetch('api/userExists', {
//         method: "POST",
//         headers: {
//           "Content-Type": 'application/json'
//         },
//         body: JSON.stringify({
//           email 
//         })
//       })

//       const {user} = await userExist.json()
//       if(!user){

//         // setError('User does not exist, Sign up.')
//         setPopUp('User does not exist, Edit your email or Sign up if you dont have an account.')
//         setShowPopupModal(true)
//         return 
//       }
      // setShowModal(true)
//     } catch (error) {
      
//     }
  //   console.log('email & password :',email, password)

  // }

  const handleShowPasswordForm =async(e)=>{
    e.preventDefault()
    
    if(!email) {
      setError('Fill in your email address')
      setPopUp('Fill in your valid email address to continue')
      setShowPopupModal(true)
      return
    }

    setLoading(true)
    setShowLoginLoading(true)
  var formdata = new FormData();
  formdata.append("email", email);
  
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  fetch(`${API_ENDPOINT}/api/auth/verify-login-email`, requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result.message)
      if(result.message === 'Email exits'){
        navigate('/loginPassword', {state:{email:email}})
      
       
      }else if(result.message === 'Email exits but not yet verified'){
        setError('Email not verified.')
        setPopUp('Email not Verified, Go to your email inbox or spam and verify your email.')
        setShowPopupModal(true)
        return
      } else if(result.message === 'Email sent successfully!'){
        setShowOTPModal(true)
        setSavedOTP(result.otp)
        return
      } else {
        setError('Email does not exist.')
        setPopUp('Email does not exist, Edit your email or Sign up if you do not have an account.')
        setShowPopupModal(true)
        return
        
      }
      setLoading(false)
      setShowLoginLoading(false)
      console.log(result)})
    .catch(error => console.log('error', error))
    
    
    setLoading(false)
    setShowLoginLoading(false)
    console.log('email & password :',email, password)
  }



  // const handleLogin =async(e)=>{
  //   e.preventDefault()
  //   if(!password) {
  //     setError('Fill in your Password')
  //     return
  //   }

  //   dispatch({type:"LOGIN_START"});
   
  //   var formdata = new FormData();
  //   formdata.append("email", email);
  //   formdata.append("password", password);
    
  //   var requestOptions = {
  //     method: 'POST',
  //     body: formdata,
  //     redirect: 'follow'
  //   };
    
  //   fetch("http://localhost:5000/api/auth/login", requestOptions)
  //     .then(response => response.json())
  //     .then(result =>{
  //       if(result.message === 'Login successful' && result.user.has_activated && result.user.two_activated){
  //         dispatch({type:"LOGIN_SUCCESS", payload: result});
  //         //  navigate('/dashboard')
  //         setShowOTPModal(true)
  //          console.log(result)
  //       }else if(result.message === 'Login successful' && result.user.has_activated && !result.user.two_activated){
  //         dispatch({type:"LOGIN_SUCCESS", payload: result});
  //          navigate('/dashboard')
  //          console.log(result)
  //       }else if(!result.user.has_activated){
  //         setPopUp('Email is not verified, Go to your email and click on the link to verify email, Then try again.')
  //         setShowPopupModal(true)
  //         const form = e.target;
  //           form.reset()
  //       }else{
  //         console.log('error logining in, try again')
  //       }
       
  //     })
  //     .catch(error => console.log('error', error));


  
 
  
  
  //   console.log('email & password :',email, password)
  // }



const handleChangeOtp = (value) => {
  console.log(value);
  setOtp(value)
    // Ensure that the latest value is used
    if (value.length === 4) {
      // verifyToken(prevOtp);
     
      setLoading(true)
      console.log(value)
      if(Number(value) === savedOTP){
        navigate('/loginPassword', {state:{email:email}})
        console.log(value)
        setLoading(false)
      }else{
        setPopUp('Wrong OTP, Please confirm the number and try again')
        setShowPopupModal(true)
        setLoading(false)
        return
      }
      setLoading(false)

    }
    return value;

};

  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }
  
    useEffect(() => {
   
      setTimeout(() => {
        setLoading(false);
      }, 3000); 
    }, []);

  return (
    <div>
      {loading ? <Loader /> : null}
      <TopTag />
{/* <TickerTape   colorTheme="dark"></TickerTape> */}
      <Navbar />
      <div className={styles.imageContainer}  >
   {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
      <img
        src={homeImage}
        alt='welcome image'
        quality={100}
       className={styles.heroImage}

        />
        <div className={styles.loginContainer}>

      
      <div className='flex gap-3 my-2 mx-11 p-5 items-center'>
  
        <div>
        <img
        src={robot}
         style={{width:600, height:400, objectFit:'contain'}}
        alt='welcome image'
        quality={100}
        className={styles.loginImage}

        />
        </div>
        <form style={{width:'300px'}} className='flex flex-col gap-3 '>
          <h1 className='text-4xl font-bold pb-6 pt-2 text-center text-white'>Login</h1>
          <div className={styles.inputContainer}>

          <input
          // onKeyUp={debouncedAutoSubmitEmail}
          onFocus={()=> {setError('')}}
          onChange={(e)=>{setEmail(e.target.value); setEmailSpinner(true)}}
          placeholder='Enter Email'
          className={styles.emailInput}
           style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10}}
            type="email" name='email'
            />

            {/* {removeEnterEmail && <label className={styles.emailText} htmlFor="email">Enter Email</label>} */}
           {emailSpinner && <div>
            <Player
          src={inputSRC}
          className="player"
          loop
          autoplay
           style={{ height: '40px', width: '40px', position:'absolute',bottom:'0px', right:'5px' }}
          
          /> 
            </div>}
          </div>
       

          { showLoginLoading ? 
           <button style={{width:'100%', height:50, borderRadius:'20px', backgroundColor:'transparent', padding:10, display:'flex', alignItems:'center', justifyContent:'center', border:'2px solid skyblue'}}>

            <Player
          src={ActivatorSpinner}
          className="player"
          loop
          autoplay
           style={{ height: '30px', width: '30px',margin:10 }}
          
          />

          </button> 
             :
           <button
           onClick={handleShowPasswordForm}
           disabled={loading ? true : false}
           className={styles.btn} style={{backgroundColor: loading ? 'lightgray' : 'inherit'}} type="submit">Next  </button>
            }
       
        { error && <p className='px-3 py2 font-bold bg-red-500 text-white w-fit rounded-md  text-xs'>{error}</p>}
        <Link to='/signup' className='text-sm text-white text-center'>New to Tibio? <strong className=''>Sign up</strong></Link>
        </form>
      </div>

      </div>

   {/* { showModal &&  <div className={styles.modal}>
     < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
      <div className='flex gap-3 my-11 bg-sky-200/50 mx-11 p-5 items-center  backdrop-filter backdrop-blur-lg bg-opacity-10'>
        <div>
        <img
        src={robot}
         style={{width:600, height:400, objectFit:'contain'}}
         className={styles.loginImage}
        alt='welcome image'
        quality={100}
       

        />
        </div>
        <form
         onSubmit={handleLogin}
          style={{width:'300px'}} className='flex flex-col gap-3 ' action="">
          <h1 className='text-4xl font-bold pb-6 pt-2 text-center text-white'>Login</h1>
        
          <div className={styles.inputContainer}>
          <input
           onFocus={()=> {setError(''); setRemoveEnterEmail(false)}}
            onChange={(e)=>setPassword(e.target.value)}
            // placeholder='Enter Password'
            className={styles.emailInput}
           style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10}} type="password" name='password'
            />
              <label className={styles.emailText} htmlFor="email">Enter Password</label>
          </div>

          <button style={{marginTop:'-15px'}} className='border-none p-2 bg-transparent text-sky-700 text-sm text-right hover:text-white'>Forget Password?</button>
       
          <button className={styles.btn} type="submit">Submit</button>
        
          { error && <p className='px-3 py2 font-bold bg-red-500 text-white w-fit rounded-md  text-xs'>{error}</p>}
        </form>
      </div>

      </div> } */}


      {/* 2fa */}

      {showOTPModal && <div className={styles.modal}>

<div style={{marginTop:200}} className='flex flex-col gap-3 my-11 bg-sky-100/50 mx-11 p-7 items-center backdrop-filter backdrop-blur-lg bg-opacity-30'>


    <h2 className='text-2xl mb-7 text-center font-bold text-white'>Type in the correct token sent to your email address</h2>
  <OtpInput
      // style={{width:40, height:40}}
      containerStyle={{margin:20}}
      inputStyle={{width:60, height:60, borderRadius:10, margin:10}}
      shouldAutoFocus
      value={otp}
      inputType='tel'
      onChange={handleChangeOtp}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />

{/* <button onClick={verifyToken} className='p-3 text-2xl font-bold bg-sky-700 text-white'>Activate 2FA</button> */}
    </div>

</div> 
    }

</div>
<Footer />
    </div>
  )
}

export default LoginPage
