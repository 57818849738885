import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import EachReferralColumn from '../components/EachReferralColumn'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'
import EmptyContainer from '../components/EmptyContainer';
import Footer from '../components/Footer';

const Referral = () => {
  const userInfo = useSelector(selectUserInfo)
  const [allReferrals, setAllReferrals] = useState([])
  const [emptyRef, setEmptyRef] = useState([])

  const ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'

  const userID = userInfo.user._id
  useEffect(()=>{
    const fetchUserReferrals =async()=>{

      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(`${ENDPOINT}/api/referrals/${userInfo.user._id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setAllReferrals(result.referrals)
          console.log(result)})
        .catch(error => console.log('error', error));
    }

    fetchUserReferrals()
  },[])
  console.log(allReferrals)
  return (
    <div>
       <DashboardNavbar />
      <DashboardSidebar />
      {
        allReferrals?.length === 0 
        ? <EmptyContainer emptyTitle='You dont have any referrals yet' emptyDetails='Register someone with your referral link and also qualify for Downline Commission for all your downlines when you invest' />
        :
        <div style={{ height:'400px'}} className='flex gap-5 flex-wrap m-12 items-center justify-center'>
        {
          allReferrals.map((referral, index)=>(
            <EachReferralColumn key={index} referral={referral}/>
          ))
        }
         
        
      </div>
      }
     
     <Footer />
    </div>
  )
}

export default Referral
