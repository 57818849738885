import React, { useEffect, useRef } from 'react';

let tvScriptLoadingPromise;

const TradingViewWidgetTwo = () => {

    const onLoadScriptRef = useRef();

    useEffect(
      () => {
        onLoadScriptRef.current = createWidget;
  
        if (!tvScriptLoadingPromise) {
          tvScriptLoadingPromise = new Promise((resolve) => {
            const script = document.createElement('script');
            script.id = 'tradingview-widget-loading-script';
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js';
            script.type = 'text/javascript';
            script.onload = resolve;
  
            document.head.appendChild(script);

             
 
          });
        }
  
        tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());
  
        return () => onLoadScriptRef.current = null;
  
        function createWidget() {
          if ('TradingView' in window) {
            new window.TradingView.widget({
                colorTheme: 'light',
                dateRange: '12M',
                exchange: 'US',
                showChart: true,
                locale: 'en',
                largeChartUrl: '',
                isTransparent: false,
                showSymbolLogo: false,
                showFloatingTooltip: false,
                width: 400,
                height: 600,
                plotLineColorGrowing: 'rgba(41, 98, 255, 1)',
                plotLineColorFalling: 'rgba(41, 98, 255, 1)',
                gridLineColor: 'rgba(240, 243, 250, 0)',
                scaleFontColor: 'rgba(106, 109, 120, 1)',
                belowLineFillColorGrowing: 'rgba(41, 98, 255, 0.12)',
                belowLineFillColorFalling: 'rgba(41, 98, 255, 0.12)',
                belowLineFillColorGrowingBottom: 'rgba(41, 98, 255, 0)',
                belowLineFillColorFallingBottom: 'rgba(41, 98, 255, 0)',
                symbolActiveColor: 'rgba(41, 98, 255, 0.12)',
              });
          }
        }
      },
      []
    );
//   useEffect(() => {
//     // Code to be executed when the component mounts

//     // Define the configuration object
//     const widgetConfig = {
//       colorTheme: 'light',
//       dateRange: '12M',
//       exchange: 'US',
//       showChart: true,
//       locale: 'en',
//       largeChartUrl: '',
//       isTransparent: false,
//       showSymbolLogo: false,
//       showFloatingTooltip: false,
//       width: 400,
//       height: 600,
//       plotLineColorGrowing: 'rgba(41, 98, 255, 1)',
//       plotLineColorFalling: 'rgba(41, 98, 255, 1)',
//       gridLineColor: 'rgba(240, 243, 250, 0)',
//       scaleFontColor: 'rgba(106, 109, 120, 1)',
//       belowLineFillColorGrowing: 'rgba(41, 98, 255, 0.12)',
//       belowLineFillColorFalling: 'rgba(41, 98, 255, 0.12)',
//       belowLineFillColorGrowingBottom: 'rgba(41, 98, 255, 0)',
//       belowLineFillColorFallingBottom: 'rgba(41, 98, 255, 0)',
//       symbolActiveColor: 'rgba(41, 98, 255, 0.12)',
//     }

//     // const element = document.querySelector('#yourElementId');

//   // Your code using the element

//     // Create a new script element
//     const scriptElement = document.createElement('script');
//     scriptElement.type = 'text/javascript';
//     scriptElement.src = 'https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js';
//     scriptElement.async = true;
//     scriptElement.innerHTML = JSON.stringify(widgetConfig);

//     // Append the script element to the document body
//     document.body.appendChild(scriptElement);

//     // Code to be executed when the component unmounts
//     return () => {
//       // Remove the script element when the component unmounts
//       document.body.removeChild(scriptElement);
//     };
//   }, []); // The empty dependency array ensures the effect runs only once when the component mounts

  return (
    <div style={{marginLeft:50}} className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget"></div>
      {/* <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div> */}
    </div>
  );
};

export default TradingViewWidgetTwo;