import React, { useContext, useEffect, useState } from 'react'
import { MdNotifications } from 'react-icons/md'
import { BiNotification } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import logo from '../assets/aitradingLogo.png'
import robot from '../assets/robotLogo.png'
import { Link } from 'react-router-dom'
import { UserContext } from '../context/UserContext'
import { selectUserInfo, setUserInfo } from '../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux'
import {setFetchedUserInfo, selectFetchedUserInfo } from '../slices/userSlice'
import styles from './dashboardNavbar.module.css'
import axios from 'axios';

const DashboardNavbar = () => {
  const [openNoti, setOpenNoti] = useState(false)
  const [loading, setLoading] = useState(true);
  // const {userDetails} = useContext(UserContext)
  const userInfo = useSelector(selectUserInfo)
  const fetchedUserInfo = useSelector(selectFetchedUserInfo)
  // const userInfo = useSelector(selectUserInfo)
  const dispatch = useDispatch()
  const [userFetchedDetails, setUserFetchedDetails] = useState({})
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'

  const [allResponses, setAllResponses] = useState([])

  useEffect(() => {
    const fetchUser = async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_ENDPOINT}/api/users/currentUser`, requestOptions);
        const result = await response.json();
        // console.log('called again')
        setUserFetchedDetails(result);
        dispatch(setFetchedUserInfo(result));
        // console.log(result);
      } catch (error) {
        console.log('error', error);
      }
    };

    // Initial fetch
    fetchUser();

    // Set up interval for fetching every 10 seconds
    const intervalId = setInterval(fetchUser, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [userInfo.token, dispatch, API_ENDPOINT]); // Add dependencies as needed

  



  // Fetch Responses



  useEffect(() => {
    const fetchResponses = async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_ENDPOINT}/api/response/${userInfo.user._id}`, requestOptions);
        const result = await response.json();
        console.log('called again')
        
       
         // const sortedData = result.withdrawals.sort((b, a) => a.createdAt - b.createdAt)
         const dataWithDateObjects = result.userResponses.map(item => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }));
              // Sort the array based on the createdAt property in descending order (from latest to oldest)
              const sortedData =  dataWithDateObjects.sort((a, b) => b.createdAt - a.createdAt);
              setAllResponses(sortedData);
              console.log(sortedData)
              setLoading(false)
        console.log(result);
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchResponses()
   
  }, [userInfo.token, API_ENDPOINT]); // Add dependencies as needed

  useEffect(() => {
    // Make an HTTP request to your Node.js server when the component mounts
    const fetchData = async () => {
    axios.post(`${API_ENDPOINT}/paymentSuccess`, { /* Your request data */ })
      .then(response => {
        // Handle the successful response
        console.log(response.data);
        // Based on the response, render different screens or take appropriate actions
        // Example: Navigate to a success screen or failure screen
        if (response.data && response.data.message === 'This is a correct JSON callback') {
          // Render success screen
          console.log('correct plisio response',response.data);
        } else {
          // Render failure screen
        }
      })
      .catch(error => {
        // Handle the error
        console.error('Error:', error);
      });
    }

        // Polling interval in milliseconds (adjust as needed)
const pollingInterval = 5000; // 5 seconds

// Fetch data initially
fetchData();

// Set up polling with setInterval
const intervalId = setInterval(() => {
  fetchData();
}, pollingInterval);

// Cleanup the interval when the component is unmounted
return () => {
  clearInterval(intervalId);
};

  }, []); // Run this effect only once when the component mounts

  return (
    <div style={{height:100}} className='flex items-center justify-between px-4 shadow-sm bg-sky-50'>
   <div>
   {/* <img
        src={logo}
         style={{objectFit:'cover', width:70, height: 70}}
        alt='welcome image'   

        /> */}
   </div>

   <div className='flex gap-1 items-center'>

    <MdNotifications onClick={()=>setOpenNoti(!openNoti)} style={{fontSize:25, cursor:'pointer'}} className='text-sky-600 relative' />

   <img
        src={fetchedUserInfo?.profilePic?.url ? fetchedUserInfo?.profilePic?.url  : robot}
        
         style={{objectFit:'cover', width:40, height:40, borderRadius:'100%', borderBottom:'2px solid rgb(58, 135, 224)', padding:'5px'}}
        alt='welcome image'

        />
        <div>
            <h2 className='text-sm'>{fetchedUserInfo?.name}</h2>
            <p className={styles.navEmail} >{fetchedUserInfo?.email}</p>
        </div>
   </div>


{ openNoti &&  <div style={{top:'100px', right:'150px', width:'300px', zIndex:1000}} className='shadow-md absolute bg-white p-4'>


    <div className='flex justify-between '>

    <h1 className='text-xl font-bold'>Notifications</h1>
    <p className='bg-sky-50 text-sky-300 p-1'>Latest</p>
    </div>
    <div style={{height:220, overflow:'hidden'}}>

 
    {allResponses.map(response=>(
      <div> 
         <div key={response._id}  className='flex gap-4 items-center mt-3 justify-between'>
        <BiNotification style={{fontSize:25, cursor:'pointer'}} className='text-sky-700' />
        <div>
          <p className='text-sm'>{response.comment}</p>
          <p className='text-sm'>{new Date(response.updatedAt).toLocaleString('en-US')}</p>

        </div>
        <AiOutlineClose style={{fontSize:25, cursor:'pointer'}} className='text-sky-700' />
      </div>
      </div>
    
    )
    )}

</div>
  
    {/* <div className='flex gap-4 items-center mt-3'>
      <BiNotification style={{fontSize:25, cursor:'pointer'}} className='text-sky-700' />
      <div>
        <p className='text-sm'>Welcome to your new account</p>
        <p className='text-sm'>12th November 2022</p>
      </div>
      <AiOutlineClose style={{fontSize:25, cursor:'pointer'}} className='text-sky-700' />
    </div>
    <div className='flex gap-4 items-center mt-3'>
      <BiNotification style={{fontSize:25, cursor:'pointer'}} className='text-sky-700' />
      <div>
        <p className='text-sm'>Welcome to your new account</p>
        <p className='text-sm'>12th November 2022</p>
      </div>
      <AiOutlineClose style={{fontSize:25, cursor:'pointer'}} className='text-sky-700' />
    </div>
    <div className='flex gap-4 items-center mt-3'>
      <BiNotification style={{fontSize:25, cursor:'pointer'}} className='text-sky-700' />
      <div>
        <p className='text-sm'>Welcome to your new account</p>
        <p className='text-sm'>12th November 2022</p>
      </div>
      <AiOutlineClose style={{fontSize:25, cursor:'pointer'}} className='text-sky-700' />
    </div> */}
  
  
   <button onClick={()=>setOpenNoti(false)} className='bg-sky-700 p-3' style={{width:'250px', margin:'18px auto'}}>
   <Link style={{ margin:'auto'}} className=' text-center m-4 text-white font-bold' to='/accountActivities'>View All</Link>
   </button>
    
   </div>}
   
    </div>
  )
}

export default DashboardNavbar
