import React, { useContext, useState } from 'react'
import { BiSolidDashboard } from 'react-icons/bi'
import { BiSolidBank } from 'react-icons/bi'
import { BiSolidNetworkChart } from 'react-icons/bi'
import { BiSolidRightArrow } from 'react-icons/bi'
import { BiSolidDownArrow } from 'react-icons/bi'
import { BsFingerprint } from 'react-icons/bs'
import { BsFillBoxFill } from 'react-icons/bs'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { SiWebmoney } from 'react-icons/si'
import { AiFillSetting } from 'react-icons/ai'
import { BsFillSendFill } from 'react-icons/bs'
import { CgProfile } from 'react-icons/cg'
import logo from '../assets/aitradingLogo.png'
import robot from '../assets/robotLogo.png'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import './dashboardSidebar.css'
import { AuthContext } from '../context/AuthContext'
import copy from "copy-to-clipboard";
import { IoMdHome } from "react-icons/io"
import { FaRegTimesCircle } from 'react-icons/fa'
import { Player } from '@lottiefiles/react-lottie-player';
import loaderSRC from '../assets/robotAnimation.json'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'

const DashboardSidebar = () => {
  const [openAuth, setOpenAuth] = useState(false)
  const [openReferral, setOpenReferral] = useState(false)
  const [openWithdrawal, setOpenWithdrawal] = useState(false)
  const [openPortfolio, setOpenPortfolio] = useState(false)
const {user, dispatch} = useContext(AuthContext)
const navigate = useNavigate()
const [popUp, setPopUp] = useState('')
const [showPopupModal, setShowPopupModal] = useState(false)

const userInfo = useSelector(selectUserInfo)
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'
const url = 'https://tibio.ai'


  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }

  const copyToClipboard = () => {
    copy(userInfo.user.referral_code);
    // alert(`You have copied "${user.user.referral_code}"`);
    setPopUp(`You have copied "${userInfo.user.referral_code}"`)
    setShowPopupModal(true)
};

const copyReferralLinkToClipboard = () => {
  copy(`https://tibio.ai/register/referral/${user.user.referral_code}`);
  // copy(`${API_ENDPOINT}/api/auth/register/${userInfo.user.referral_code}`);
  // alert(`You have copied "${user.user.referral_code}"`);
  setPopUp(`Referral Link copied to clipboard successfully`)
  setShowPopupModal(true)
};
 

const handleLogout=async()=>{


  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userInfo.token}`);


  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch(`${API_ENDPOINT}/api/auth/logout`, requestOptions)
    .then(response => response.json())
    .then(result =>{
      console.log(result)
      if(result.msg === 'user logged out successfully.!'){
        dispatch({type:"LOGOUT"});

        if (localStorage.getItem("user")) {
          localStorage.removeItem("user");
        }
         navigate('/login')
         console.log(result)
      }else{
        console.log('error loging out, try again')
      }
     
    })
    .catch(error => console.log('error', error));

 

}
  return (<>
  
   {/* popup */}
   { showPopupModal && 
   <div className='showContainer'>

        <div className='innerShowContainer'>
        < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className='showClose' />
            <div className='flex gap-2 flex-col'>
            <Player
          src={loaderSRC}
          className="player"
          loop
          autoplay
           style={{ height: '50px', width: '50px' }}
          
          />
            <p className='showText'>{popUp}</p>
            </div>
           
            <button className='showButton' onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
   

    <div style={{zIndex:55}} className="drawer">
    <input id="my-drawer" type="checkbox" className="drawer-toggle" />
    <div className="drawer-content">
      {/* Page content here */}
     
      <label style={{backgroundColor:'rgba(58, 135, 224, 0.2)', border:'none', color:'rgb(58, 135, 224)', position:'absolute', top:-80,left:60, height:60, width:60, display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer', borderRadius:'10px'}} htmlFor="my-drawer" className=" btn-primary drawer-button" >
        {/* <FiMenu /> */}
        <img
          src={logo}
           style={{objectFit:'contain', height:70, width:70}}
          alt='welcome image'
          />
        </label>
    </div> 
    <div className="drawer-side">
      <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
      <ul style={{border:'2px solid black'}} className="menu p-4 w-60 min-h-full bg-sky-200 text-base-content backdrop-filter backdrop-blur-lg bg-opacity-30">
        {/* Sidebar content here */}
        <li>
        <img
          src={logo}
          alt='welcome image'
          style={{width:100, height:100}}
  
          />
        </li>
        <li className='flex gap-4 mt-10' ><NavLink activeClassName="active" className='flex gap-3' to='/aiTrading'> <IoMdHome style={{color:'rgb(58, 135, 224)',fontSize:28}} /> Home  </NavLink> </li>
        <li className='flex gap-4' ><NavLink activeClassName="active" className='flex gap-3' to='/dashboard'> <BiSolidDashboard style={{color:'rgb(58, 135, 224)',fontSize:28}} /> Dashboard  </NavLink> </li>


        <li className='flex gap-4 ' >
          <div 
          // onMouseOver={()=>setOpenPortfolio(true)}
          onClick={()=>setOpenPortfolio(!openPortfolio)}
           className='flex justify-between items-center'>

          <div className='flex gap-3 items-center'  > 
        <BiMoneyWithdraw  style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Portfolio Catalogue </div>
        {!openPortfolio ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openPortfolio &&  <div className='bg-sky-100 pl-7 backdrop-filter backdrop-blur-lg bg-opacity-10'>
         <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/portfolios'> Purchase Portfolio </NavLink> </li>
        <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/activePortfolios'>  Active Portfolios  </NavLink> </li>
        <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/expiredPortfolios'>  Expired Portfolios  </NavLink> </li>
         </div>}
        {/* <li className='flex gap-4 ' ><Link className='flex gap-3'  href='investments'>  <SiWebmoney style={{color:'rgb(58, 135, 224)',fontSize:28}} /> Portfolios  </Link> </li> */}
       

        <li className='flex gap-4 ' >
          <div
          // onMouseOver={()=>setOpenWithdrawal(true)}
          onClick={()=>setOpenWithdrawal(!openWithdrawal)} 
          className='flex justify-between items-center'>

          <div className='flex gap-3 items-center'  > 
        <BiMoneyWithdraw  style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Withdrawal   </div>
        {!openWithdrawal ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openWithdrawal &&  <div className='bg-sky-100 pl-7 backdrop-filter backdrop-blur-lg bg-opacity-10'>
         <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/withdrawal'> Initiate Withdrawal </NavLink> </li>
        <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/withdrawalHistory'>  Withdrawal History  </NavLink> </li>
         </div>}

        <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/accountActivities'>  <BsFillBoxFill style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Account Activities </NavLink> </li>
        <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/chooseTransfers'>  <BsFillSendFill style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Transfers </NavLink> </li>
        <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/profile'>  <CgProfile style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Profile  </NavLink> </li>

        <li className='flex gap-4 ' >
          <div
          // onMouseOver={()=>setOpenReferral(true)}
          onClick={()=>setOpenReferral(!openReferral)}
           className='flex justify-between items-center'>

          <div className='flex gap-3 items-center'  > 
        <BiSolidNetworkChart  style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Referrals   </div>
        {!openReferral ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openReferral &&  <div className='bg-sky-100 pl-7 backdrop-filter backdrop-blur-lg bg-opacity-10'>
         <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/referal'>  All Referrals   </NavLink> </li>
        <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/referralBonus'>  Referral Bonus  </NavLink> </li>
        <li className='flex gap-4 ' ><div onClick={copyToClipboard} className='flex gap-3'  to='/referal'>  Referral Code  </div> </li>
        <li className='flex gap-4 ' ><div onClick={copyReferralLinkToClipboard} className='flex gap-3'  to='/referal'>  Referral Link  </div> </li>
         </div>}

        {/* <li className='flex gap-4 ' ><Link className='flex gap-3'  href='referal'>  <BiSolidNetworkChart style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Referral  </Link> </li> */}
        <li className='flex gap-4 ' >
          <div 
          // onMouseOver={()=>setOpenAuth(true)}
          onClick={()=>setOpenAuth(!openAuth)}
           className='flex justify-between items-center'>

          <div className='flex gap-3 items-center'  > 
        <BsFingerprint style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Authentication   </div>
        {!openAuth ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>
       { openAuth &&  <div className='bg-sky-100 pl-7 backdrop-filter backdrop-blur-lg bg-opacity-10'>
         <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/twoFA'>  2FA   </NavLink> </li>
        <li className='flex gap-4 ' ><NavLink className='flex gap-3'  to='/updatePassword'>  Update Password  </NavLink> </li>
         </div>}

        <li onClick={handleLogout} className='flex gap-4 ' ><div className='flex gap-3'  > <AiFillSetting style={{color:'rgb(58, 135, 224)', fontSize:28}} /> Log out  </div> </li>
      
         
      </ul>
    </div>
  </div>

  </>
  )
}

export default DashboardSidebar
