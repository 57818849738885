import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import FundWalletSuccessComponent from '../components/FundWalletSuccessComponent'
import Footer from '../components/Footer'

const FundWalletSuccess = () => {
  return (
    <div>
            <DashboardNavbar/>
      <DashboardSidebar />
      <FundWalletSuccessComponent />
      <Footer />
    </div>
  )
}

export default FundWalletSuccess
