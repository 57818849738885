import React from 'react'
import styles from './chooseTransferColumn.module.css'
import { Link } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa";

const ChooseTransferColumn = () => {
  return (
    <div className={styles.chooseTransferColumnContainer}>
      
      <Link to='/transferToWallet' className={styles.left}>
    <h1>Transfer to Main Wallet</h1>
    <p>Transfers are made from other wallet to main wallet</p>
    <FaArrowRight className={styles.arrow} />
      </Link>

      <Link to="/transfers" className={styles.right}>
      <h1>Transfer from Main Wallet</h1>
      <p>Transfers are made from main wallet to other users main wallet</p>
      <FaArrowRight className={styles.arrow} />
      </Link>

    </div>
  )
}

export default ChooseTransferColumn
