
import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import DashboardHome from '../components/DashboardHome'
import Footer from '../components/Footer'

const page = () => {
  return (
    <div>
      <DashboardNavbar />
      <DashboardSidebar />
 
{/* Dashboard Sidebar ends here */}

    <DashboardHome />
    <Footer />
    </div>
  )
}

export default page
