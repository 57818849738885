import React from 'react'
import { Link } from 'react-router-dom'
import './innerHeroComponent.css'

const InnerHeroComponent = ({title}) => {
  return (
    <div style={{width:'100%', height:'350px'}} className='bg-gray-100 flex items-center justify-center flex-col'>
      <h1 className='text-2xl text-blue-900 font-extrabold text-center p-3 mt-8 lg:text-5xl' >{title}</h1>
      <Link className='btn' to='/login'>Get Started</Link>
    </div>
  )
}

export default InnerHeroComponent
