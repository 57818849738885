import React from 'react'
import profileImage from '../assets/profileImage.png'

const EachReferralColumn = ({referral}) => {
  return (
    <div style={{width:'300px', height:'150px'}} className='flex gap-5 items-center p-5 bg-sky-100 '>
        <img
         src={profileImage}
          style={{objectFit:'cover',width:50, height:50, borderRadius:'100%'}}
         alt='welcome image'
         />
         <div>
            <h1 className='text-l font-bold'>{referral.name}</h1>
            <h1 style={{fontSize:'14px', textAlign:'center'}}>{referral.email}</h1>
         </div>
    </div>
  )
}

export default EachReferralColumn
