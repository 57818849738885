import React from 'react'
import Ticker from './Ticker'

const CryptoBinance = () => {
  return (
    <div>
      <h2 className='text-lg font-semibold leading-8 text-sky-700'>Live Crytocurrency Feeds</h2>
      {/* <p className='text-lg font-semibold tracking-tighter text-gray-900 sm:text-4xl'>Currencies from top earning coins</p> */}
    <Ticker />
    </div>
  )
}

export default CryptoBinance
