import React from 'react'
import Navbar from '../components/Navbar'
import TopTag from '../components/TopTag'
import { TickerTape } from "react-ts-tradingview-widgets";
import './about.css'
import InnerHeroComponent from '../components/InnerHeroComponent';
import robotIMG from '../assets/robImg.png'
import robotBabyImg from '../assets/babyRobot.jpg'
import robotLogo from '../assets/robotLogo.png'
import Footer from '../components/Footer';

const AboutUspage = () => {
  const customCopyrightStyles = {
    display: 'none', 
    opacity: 0,
    color:'white'
  };
  return (
    <div>
      <TopTag />

      {/* <TickerTape copyrightStyles={customCopyrightStyles}  colorTheme="dark"></TickerTape> */}
        <Navbar />
    <InnerHeroComponent title='Revolutionizing Your Investment Journey' />

      <div className='aboutContainer'>
        <div className="aboutTop">
          <ul>
            <p className='text-sky-600 text-2xl font-bold'>Welcome to Tibio, where artificial intelligence meets financial innovation! We've crafted an intelligent robot dedicated to automating the trading of investors' funds, ensuring a seamless and profitable investment experience. At Tibio, simplicity and convenience are at the core of our design. Our robot operates autonomously, eliminating the need for human interference.</p>
            <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>Key Features</p>
            <li className='text-sky-600 text-lg' ><span>Automated Trading: </span> Tibio employs advanced algorithms to make informed trading decisions in real-time.</li>
            <li className='text-sky-600 text-lg'><span>Profit Sharing: </span> We believe in collective success. Tibio automatically shares profits among its investors.</li>
            <li className='text-sky-600 text-lg'><span>User-Friendly Design: </span> Designed with you in mind, Tibio offers an easy and convenient interface for effortless interaction.</li>
            <li className='text-sky-600 text-lg'><span>Autonomous Operation: </span> Enjoy the benefits of hands-free investing as Tibio operates autonomously, optimizing your returns.</li>
            <p className='text-sky-600 text-2xl mt-8'>Embark on a journey of financial empowerment with Tibio. Join us in revolutionizing the future of investment!</p>
          </ul>
        <img src={robotLogo} alt="tibio" />
        </div>

        <div  className="aboutMiddle">
          <h1 className='text-4xl text-sky-800 font-bold text-center my-5'>Unlocking Financial Prosperity with Tibio AI Trading Operations: A Transformative Investment Experience</h1>
          <h4 className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>Welcome to the Future of Investment!</h4>
          <p className='text-sky-600 text-2xl m-8'>In a world driven by innovation, Tibio stands out as a beacon of financial empowerment. Tibio is not just an artificial intelligence robot; it's a revolution in the way we perceive and engage with investments. Let's delve into the myriad advantages that make investing with Tibio a game-changer for individuals seeking financial growth and independence.</p>

          <div className="aboutMiddleInner">
            <h2>1. Automated Precision:</h2>
            <p>Tibio harnesses the power of cutting-edge artificial intelligence to make lightning-fast, data-driven decisions. This ensures that your investments are always strategically positioned in the dynamic financial landscape.</p>
          </div>

          <div className="aboutMiddleInner different">
            <h2>2. Profit Sharing Ecosystem:</h2>
            <p>At Tibio, we believe in shared success. Our unique profit-sharing model means that as Tibio generates profits, they are distributed among the community of investors. Your financial success is our collective goal.</p>
          </div>
          <div className="aboutMiddleInner">
            <h2>3. Ease and Convenience:</h2>
            <p>Designed with user experience in mind, Tibio offers unparalleled ease and convenience. From onboarding to daily operations, our platform is crafted to be user-friendly, ensuring that you navigate the investment landscape effortlessly.</p>
          </div>
          <div className="aboutMiddleInner different">
            <h2>4. Autonomous Operation:</h2>
            <p>Say goodbye to constant monitoring and decision fatigue. Tibio performs autonomously, removing the need for human intervention. Your investments are managed efficiently and effectively round the clock.</p>
          </div>
          <div className="aboutMiddleInner">
            <h2>5. Global Impact:</h2>
            <p>Join a community of investors around the world who have experienced the transformative power of Tibio. Our global reach means that you are part of a diverse network, sharing insights and experiences with like-minded individuals.</p>
          </div>
          <div className="aboutMiddleInner different">
            <h2>6. Transparent Operations:</h2>
            <p>Tibio prioritizes transparency. Our operations are transparent, ensuring that you have real-time insights into your investments. Track performance, understand strategies, and make informed decisions with ease.</p>
          </div>
          <div className="aboutMiddleInner">
            <h2>7. Proven Track Record:</h2>
            <p>Tibio has transformed the lives of investors worldwide. Explore success stories and testimonials that underscore the positive impact our AI trading operations have had on financial portfolios.</p>
          </div>
          <div className="aboutMiddleInner different">
            <h2>8. Continous Innovation:</h2>
            <p>In the ever-evolving landscape of finance and technology, Tibio is committed to continuous innovation. Benefit from ongoing enhancements, ensuring that your investment strategy aligns with the latest market trends.</p>
          </div>

          <div className='babyRobotContainer'>
          <p className='text-sky-600 text-2xl m-11'>Tibio is not just an investment platform; it's a partner in your financial journey. Experience the advantages of automated precision, profit-sharing, ease of use, autonomy, global impact, transparency, a proven track record, and continuous innovation.</p>
                    <img src={robotBabyImg} alt="tibio" />
          </div>
        
          <p className='text-sky-600 text-4xl m-11 text-center font-bold'>Join the Tibio community today and redefine your financial future!</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AboutUspage
