import React from 'react'
import styles from './topTag.module.css'
import { useTicker } from "../utils/hook";
import TickerCrypto from "./TickerCrypto";

const TopTag = () => {
  const cryptocurrencies = useTicker()
  return (
    <div className={styles.topTagContainer}>
  <div className={styles.innerContainer}>
{cryptocurrencies?.map((crypto)=>(
        <TickerCrypto key={crypto.id} crypto={crypto}/>
      ))}
  

    </div>

    </div>
  )
}

export default TopTag
