
import React, { memo } from "react";

import { formatPrice } from "../utils";
import Status from "./Status";
import CryptoLoader from "./CryptoLoader";


function TickerCrypto({ crypto }) {
  const colorClassName = crypto.prevPrice ? crypto.price > crypto.prevPrice ? "text-green-600" : "text-red-500" : "text-gray-900"
  return (
    <div className="w-fit py-2 x-2 flex items-center gap-2 justify-center">
     <img className="w-5 h-5 ml-8 rounded-full" src={`https://s2.coinmarketcap.com/static/img/coins/128x128/${crypto.iconCode}.png`} />
     <h5 className=" text-l font-semibold tracking-tight text-gray-900">{crypto.name}</h5>
   
       <div className="flex gap-2">
      <span style={{paddingRight:5}} className={colorClassName} title={`${crypto.price}`}>{formatPrice(crypto.price)}</span>
        <Status label='24h High' value={formatPrice(crypto.highPrice)} / >
        <Status label='24h Low' value={formatPrice(crypto.lowPrice)} / >
        <Status label='' value={crypto.symbol} / >
      </div>
   
 
    </div>
  );
}

export default memo(TickerCrypto);