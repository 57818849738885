import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import FundWalletFailureComponent from '../components/FundWalletFailureComponent'
import Footer from '../components/Footer'

const FundWalletFailure = () => {
  return (
    <div>
          <DashboardNavbar />
      <DashboardSidebar />
      <FundWalletFailureComponent />
      <Footer />
    </div>
  )
}

export default FundWalletFailure
