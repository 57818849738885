import React,{useEffect, useState} from 'react'
import videoFile from '../assets/aiVideo.mp4'
import styles from  './contact.module.css'
import { FaTelegram } from "react-icons/fa";
import { FaRegTimesCircle } from 'react-icons/fa'
import {useLocation} from 'react-router-dom'
import robot from '../assets/robot.png'
import techHand from '../assets/techHand.jpg'
import {useNavigate} from 'react-router-dom'
import Loader from './Loader';

const ContactMessageColumn = () => {
    const [showModal, setShowModal] = useState(false)
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)
    // const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const [removeEnterEmail, setRemoveEnterEmail] = useState(true)
    const location = useLocation();
    const {state} = location;
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai/'

  
    const handleClosePopup =()=>{
      setShowPopupModal(false)
       setPopUp('')
      //  navigate('/aiTrading')
      }
    
  
    useEffect(()=>{
      setEmail(state.email)
    },[])

    console.log(email)
    const handleContactForm=(e)=>{
        e.preventDefault()

        if(!message && !email){
          setShowPopupModal(true)
          setPopUp('Fill in your Message to proceed')
          return
        }
        setLoading(true)
        var formdata = new FormData();
        formdata.append("email", email);
        formdata.append("message", message);
        
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch(`${API_ENDPOINT}/api/contactMessage/send-message`, requestOptions)
          .then(response => response.json())
          .then(result =>{   
              setLoading(false)
               console.log(result)
               setShowPopupModal(true)
               setPopUp('Message Successfully sent to Tibio AI, You will be contacted shortly through your reply email')
               const form = e.target;
               form.reset()
               setLoading(false)
            
          })
          .catch(error => console.log('error', error));
    
    }

  return (
    <div className={styles.imageContainer}  >
    {loading ? <Loader /> : null}
          {/* <video className={styles.heroImage} autoPlay loop muted>
          <source src={videoFile} type='video/mp4' />
         </video> */}

              {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={handleClosePopup} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
   
         <img
        src={techHand}
        alt='welcome image'
        quality={100}
       className={styles.heroImage}

        />
      <div className={styles.text}>
      

        <div className={styles.contactInnerContainer}>

        <div className='flex gap-3 bg-sky-100 p-4 w-fit text-black items-center m-auto cursor-pointer mt-8'>
        <FaTelegram className='font-bold text-3xl' />
        <p>Live chat with telegram bot</p>
        </div>
      
    <div className={styles.chatContainer}>

    <div className='flex gap-3 my-11 bg-sky-200/50 mx-11 p-5 items-center backdrop-filter backdrop-blur-lg bg-opacity-30'>
        <div className=''>
        <img
        src={robot}
         style={{width:600, height:400, objectFit:'contain'}}
         className={styles.loginImage}
        alt='welcome image'
        quality={100}
       

        />
        </div>
        <form
         onSubmit={handleContactForm}
          style={{width:'300px'}} className='flex flex-col gap-3' >
          <div className={styles.inputContainer}>
          <textarea
           onFocus={()=> { setRemoveEnterEmail(false)}}
            onChange={(e)=>setMessage(e.target.value)}
           style={{backgroundColor:'white', height:'100px', width:'100%', outline:'none', paddingLeft:10}} type="text" name='password'
            />
   {removeEnterEmail &&  <label className={styles.emailText} htmlFor="email">Type Your Message</label>}
          </div>  
          <button className={styles.btn} type="submit">Send Message</button>

        </form>
      </div>
</div>

</div>
      </div>

    

  </div>
  )
}

export default ContactMessageColumn
