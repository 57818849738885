import React, { useState } from 'react'
import styles from './forgetPasswordColumn.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import passwordImage from '../assets/building.jpg'
import { Player } from '@lottiefiles/react-lottie-player';
import inputSRC from '../assets/loadingAnimation.json'
import OtpInput from 'react-otp-input';
import { selectUserInfo, setUserInfo } from '../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Loader from './Loader';

const ForgetPasswordColumn = () => {
    const [showModal, setShowModal] = useState(false)
    const [showCurrentPasswordModal, setShowCurrentPasswordModal] = useState(false)
    const [email, setEmail] = useState('')
    const [emailSpinner, setEmailSpinner] = useState(false)
    const [showOTPModal, setShowOTPModal] = useState(false)
    const [otp, setOtp] = useState('');
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)
    const [showLoginLoading, setShowLoginLoading] = useState(false)
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'
    const [savedOTP, setSavedOTP] = useState('')
  
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleChange =(e)=>{
      e.preventDefault()
      setEmail(e.target.value)
      setEmailSpinner(true)
    }
    const handleSubmitEmail =(e)=>{
        e.preventDefault()
        if(email){
          setShowLoginLoading(true)
          var formdata = new FormData();
          formdata.append("email", email);
          
          var requestOptions = {
            method: 'PUT',
            body: formdata,
            redirect: 'follow'
          };
        setLoading(true)
          fetch(`${API_ENDPOINT}/api/auth/forget-password`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result.message)
              if(result.message === 'Email sent successfully!'){
                setLoading(false)
               setSavedOTP(result.otp)
               setShowOTPModal(true)
                setPopUp('Check your provided email for your four digit token')
                setShowPopupModal(true)
                return
                
               
              }else if(result.error){
                // setShowModal(true)
                setPopUp(`${result.error}`)
                setShowPopupModal(true)
        
              }
              setLoading(false)
              setShowLoginLoading(false)
              console.log(result)})
            .catch(error => console.log('error', error))
            
            
        
        }else{
          setPopUp('Type in your registered Email Address')
          setShowPopupModal(true)
        }
       
      }

      const handleChangeOtp = (value) => {
        console.log(value);
        setOtp(value)
          // Ensure that the latest value is used
          if (value.length === 4) {
            // verifyToken(prevOtp);
           
            setLoading(true)
            console.log(value)
            if(Number(value) === savedOTP){
              navigate('/createNewPassword',{state:{email:email}})
              console.log(value)
            }else{
              setPopUp('Wrong OTP, Please confirm the number and try again')
              setShowPopupModal(true)
              setLoading(false)
              return
            }
            setLoading(false)
    
          }
          return value;
    
      };

      const handleClosePopup =()=>{
        setShowPopupModal(false)
         setPopUp('')
        }
      


  return (
    <div className={styles.transferImagesContainer}>
      {loading ? <Loader /> : null}
         {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

    <img
            src={passwordImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
            <div className={styles.transferContainer}>
<form style={{width:'300px', margin:'auto'}} className='flex flex-col gap-3 ' action="">
  <h1 className='text-4xl font-bold pb-6 pt-2 text-center text-white'>Forgot Password</h1>
  {/* <label className={styles.emailText} htmlFor="email">Amount</label> */}
  <div className='relative'>
  <input value={email}
   onChange={handleChange}
    placeholder='Email'
     style={{backgroundColor:'white', height:'40px', width:'100%', paddingLeft:20, outline:'none'}} 
     type="email" name='email' />
       {emailSpinner && <div>
            <Player
          src={inputSRC}
          className="player"
          loop
          autoplay
           style={{ height: '40px', width: '40px', position:'absolute',bottom:'0px', right:'5px' }}
          
          /> 
            </div>}
  </div>

<button onClick={handleSubmitEmail} className={styles.btn} type="submit">Next</button>

</form>
</div>

{showOTPModal && <div className={styles.modal}>

<div style={{marginTop:200}} className='flex flex-col gap-3 my-11 bg-sky-100/50 mx-11 p-7 items-center backdrop-filter backdrop-blur-lg bg-opacity-30'>


    <h2 className='text-2xl mb-7 text-center font-bold text-white'>Type in the correct token sent to your email address</h2>
    <OtpInput
      containerStyle={{ margin: 20 }}
      inputStyle={{ width: 60, height: 60, borderRadius: 10, margin: 10 }}
      shouldAutoFocus
      value={otp}
      inputType="tel"
      onChange={handleChangeOtp}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />

    </div>

</div> 
    }
</div>
  )
}

export default ForgetPasswordColumn
