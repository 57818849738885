import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import PaymentSuccessComponent from '../components/PaymentSuccessComponent'
import Footer from '../components/Footer'

const PaymentSuccess = () => {
  return (
    <div>
            <DashboardNavbar />
      <DashboardSidebar />
      <PaymentSuccessComponent />
      <Footer />
    </div>
  )
}

export default PaymentSuccess
