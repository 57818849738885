
import React, { useEffect, useState, useContext } from 'react'
import { SiWebmoney } from 'react-icons/si'
import Loader from './Loader';
import RecentTransactionTable from './RecentTransactionTable';
import styles from './dashboardHome.module.css'
import { Link } from 'react-router-dom';
import TradingViewOne from '../components/TradingViewOne'
import { selectUserInfo } from '../slices/authSlice';
import { selectFetchedUserInfo } from '../slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import chartOne from '../assets/chart1.png'
import chartTwo from '../assets/chart2.png'
import chartThree from '../assets/chart3.png'
import { FaArrowUp } from "react-icons/fa";
import { BiMoneyWithdraw } from 'react-icons/bi'
import { BsFillBoxFill } from 'react-icons/bs'
import { BiSolidNetworkChart } from 'react-icons/bi'
import { EconomicCalendar } from "react-ts-tradingview-widgets";




// total amountwithdrawn displayed and also total times withdrawn implement








const DashboardHome = () => {
  const [loading, setLoading] = useState(true);

  const fetchedUserInfo = useSelector(selectFetchedUserInfo)
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'
 
    const [allReferrals, setAllReferrals] = useState([])
  

console.log(userInfo.user._id)

  useEffect(() => {
    const fetchReferrals = async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_ENDPOINT}/api/referrals/${userInfo.user._id}`, requestOptions);
        const result = await response.json();
        console.log('called again')
        setAllReferrals(result.referrals);
       
        console.log(result);
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchReferrals()
   
  }, [userInfo.token, API_ENDPOINT]); // Add dependencies as needed


  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);

  return (
    <div>
      {/* top */}
      {loading ? <Loader /> : null}
      <div style={{marginTop:'50px', marginLeft:'100px'}}>
      <Link className={styles.btn} to='/Wallets'>Wallets</Link>
      </div>

<div className='flex gap-4 flex-wrap items-center justify-center m-5'>

<div style={{width:'300px'}} className='bg-gradient-to-r from-cyan-500/50 to-gray-500/50  p-3 border-2 shadow-md flex flex-col gap-3 rounded-lg'>
          <div className='flex items-center gap-2'>
          <SiWebmoney style={{color:'rgb(58, 135, 224)',fontSize:20}} />
          <h1 style={{fontSize:'18px', textAlign:'center', fontWeight:400}}>Daily Profit</h1> 
          </div>
            <p className='text-2xl font-bold text-sky-800 ml-2' >${Number(fetchedUserInfo?.dailyWalletTotal) > 0 ? Math.round(fetchedUserInfo?.dailyWalletTotal) : 0 }</p>
            <div className='flex items-end justify-between'>
              <div className='flex text-green-600 items-center'>
              <FaArrowUp />
              <p>0.98</p>
              </div>
           
              <img style={{width:'60%'}} className='bg-sky-200/50' src={chartOne} alt="chart" />
            </div>
        </div>

        <div style={{width:'300px'}} className='bg-gradient-to-r from-cyan-500/50 to-gray-500/50  p-3 border-2 shadow-md flex flex-col gap-3 rounded-lg'>
          <div className='flex items-center gap-2'>
          <SiWebmoney style={{color:'rgb(58, 135, 224)',fontSize:20}} />
          <h1 style={{fontSize:'18px', textAlign:'center', fontWeight:400}}>Compounding Profit</h1> 
          </div>
            <p className='text-2xl font-bold text-sky-800 ml-2' >${Number(fetchedUserInfo?.compoundingWalletTotal) > 0 ? fetchedUserInfo?.compoundingWalletTotal : 0 }</p>
            <div className='flex items-end justify-between'>
              <div className='flex text-green-600 items-center'>
              <FaArrowUp />
              <p>0.98</p>
              </div>
           
              <img style={{width:'60%'}} className='bg-sky-200/50' src={chartTwo} alt="chart" />
            </div>
        </div>

        <div style={{width:'300px'}} className='bg-gradient-to-r from-cyan-500/50 to-gray-500/50  p-3 border-2 shadow-md flex flex-col gap-3 rounded-lg'>
          <div className='flex items-center gap-2'>
          <SiWebmoney style={{color:'rgb(58, 135, 224)',fontSize:20}} />
          <h1 style={{fontSize:'18px', textAlign:'center', fontWeight:400}}>Main Wallet</h1> 
          </div>
            <p className='text-2xl font-bold text-sky-800 ml-2' >${Number(fetchedUserInfo?.mainWallet) > 0 ? fetchedUserInfo?.mainWallet : 0 }</p>
            <div className='flex items-end justify-between'>
              <div className='flex text-green-600 items-center'>
              <FaArrowUp />
              <p>0.98</p>
              </div>
           
              <img style={{width:'60%'}} className='bg-sky-200/50' src={chartThree} alt="chart" />
            </div>
        </div>

</div>
<div className={styles.innerContainer}>

<div className={styles.innerLeft}>
       

        <div style={{width:'200px', height:120}} className='bg-sky-200 p-3 border-2 shadow-md flex items-center justify-between gap-8 flex-grow'>
          <div>
          <h1 style={{fontSize:'15px'}}>Total Amount Withdrawn</h1>    
           <p className='text-xl font-bold' >$5,000</p>
          </div>
            <BiMoneyWithdraw style={{color:'rgb(58, 135, 224)',fontSize:40}} className='p-2 bg-yellow-100 rounded-full' />
        </div>

        <div style={{width:'200px', height:120}} className='bg-yellow-100 p-3 border-2 shadow-md flex items-center justify-between gap-8 flex-grow'>
          <div>
          <h1 style={{fontSize:'15px'}}>Total Portfolios</h1>    
           <p className='text-xl font-bold' >{fetchedUserInfo?.portfolios?.length}</p>
          </div>
            <BsFillBoxFill style={{color:'rgb(58, 135, 224)',fontSize:40}} className='p-2 bg-green-100 rounded-full' />
        </div>

        <div style={{width:'200px', height:120}} className='bg-green-100 p-3 border-2 shadow-md flex items-center justify-between gap-8 flex-grow'>
          <div>
          <h1 style={{fontSize:'15px'}}>Total Referrals</h1>    
           <p className='text-xl font-bold' >{allReferrals.length}</p>
          </div>
            <BiSolidNetworkChart style={{color:'rgb(58, 135, 224)',fontSize:40}} className='p-2 bg-sky-100 rounded-full'/>
        </div>
      </div>

      <div className={styles.innerRight} style={{height:600, width:'65%', margin:'auto' }}>

        <TradingViewOne />

        </div>

      

</div>

<div className='p-8 my-9' style={{ width:'80%', margin:'auto', paddingLeft:'50px',height:'600px' }}>
        <EconomicCalendar colorTheme="dark" height={400} width="100%"></EconomicCalendar>
        </div>
   
    

      <RecentTransactionTable />
    </div>
  )
}

export default DashboardHome
