import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import DashboardWithdrawal from '../components/DashboardWithdrawal'
import WithdrawalTopColumn from '../components/WithdrawalTopColumn'
import WalletSlideColumn from '../components/WalletSlideColumn'
import Footer from '../components/Footer'

const page = () => {
  return (
    <div>
       <DashboardNavbar />
      <DashboardSidebar />
      {/* <WalletSlideColumn /> */}
      {/* <WithdrawalTopColumn /> */}
      <DashboardWithdrawal />
      <Footer />
    </div>
  )
}

export default page
