import React, { useEffect } from 'react'
import { useState } from 'react'
import './testimonial.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Testimonials = () => {

     const [curr, setCurr] = useState(0);

     const testimonialSlides = [
       {id: 1,
        testimonial:  'Welcome to Tibio, where your financial success begins with intelligent trading.',
        // company : 'The PW Hub Limited'
      },
      {id: 2,
        testimonial:  'Greetings, Investor! Get ready for a seamless journey with Tibio, your AI trading companion.',
        // company : 'Precious Photography'
      },
      {id: 3,
        testimonial:  'Hello and welcome! Tibio is here to make your investment experience effortless and rewarding.',
        // company : 'House of Jelo'
      },
      {id: 4,
        testimonial:  'Step into the future of investing with Tibio—smart, simple, and completely automated.',
      },
      {id: 5,
        testimonial:  "Investor's paradise starts here! Welcome to Tibio, your trusted AI trading partner",
      },
      {id: 6,
        testimonial:  'Unlock the power of autonomous trading. Welcome to Tibio, where your funds work for you.',
      },
      {id: 7,
        testimonial:  'Hello, Investor Extraordinaire! Tibio welcomes you to a world of hassle-free and profitable trading.',
      },
      {id: 8,
        testimonial:  'Tibio greets you with open arms, ready to elevate your investment game.',
      },
      {id: 9,
        testimonial:  'Welcome to Tibio, your gateway to stress-free investing and intelligent profit-sharing. ',
      },
      {id: 10,
        testimonial:  "Investor's delight begins here! Tibio warmly welcomes you to a new era of financial success.",
      },
      {id: 11,
        testimonial:  'Greetings, Wise Investor! Tibio is thrilled to have you on board for automated, profitable trading.',
      },
      {id: 12,
        testimonial:  'Step into the world of smart investing. Tibio welcomes you with excitement and possibilities.',
      },
      {id: 13,
        testimonial:  "Investor, your journey to financial freedom starts now! Welcome to Tibio's revolutionary trading experience.",
      },
      {id: 14,
        testimonial:  'A warm welcome to the future of investment! Tibio is here to redefine your financial success.',
      },
      {id: 15,
        testimonial:  "Tibio welcomes you to a world where AI meets profitability. Let's embark on this journey together.",
      },
      {id: 16,
        testimonial:  "Investor's haven is here! Tibio warmly welcomes you to an era of hands-free, intelligent trading.",
      },
      {id: 17,
        testimonial:  "Greetings, Financial Trailblazer! Welcome to Tibio, where your investments take the lead.",
      },
      {id: 18,
        testimonial:  "Hello, Investor Visionary! Tibio welcomes you to a world where AI and profits converge.",
      },
      {id: 19,
        testimonial:  "Your financial journey begins with Tibio! Welcome to a smarter, more profitable way to trade.",
      },
      {id: 20,
        testimonial:  "Investor, brace yourself for a warm welcome to Tibio—where automated trading meets exceptional returns.",
      },
      
     ]
     let autoSlide = true;
     let autoSlideInterval = 60000;

     const prev = () =>
     setCurr((curr) => (curr === 0 ? testimonialSlides.length - 1 : curr - 1))
   const next = () =>
     setCurr((curr) => (curr === testimonialSlides.length - 1 ? 0 : curr + 1))
 
   useEffect(() => {
     if (!autoSlide) return
     const slideInterval = setInterval(next, autoSlideInterval)
     return () => clearInterval(slideInterval)
   }, [])

  return (
    <div className='testimonial-container'>
     
      {/* <div className="next-prev">
        <ArrowBackIosIcon onClick={prev} className='test-button' />
        <ArrowForwardIosIcon onClick={next} className='test-button' />
      </div> */}

      {
        testimonialSlides.map((testimonialData,index)=>(
          <div key={testimonialData.id} className={index === curr ? 'slide activate' : 'slide'}>

        {index === curr && (
          <>
               <p>"{testimonialData.testimonial}"</p>
             
            </>
        )}
          </div>
        ))
      }
      
    </div>
  )
}

export default Testimonials
