import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import emptySRC from '../assets/emptyFolderAni.json'

const EmptyContainer = ({emptyTitle, emptyDetails}) => {
  return (
    <div style={{width:'100%', height:'500px'}} className='flex flex-col items-center justify-center gap-5'>
      <Player
          src={emptySRC}
          className="player"
          loop
          autoplay
           style={{ height: '300px', width: '300px' }}
          
          /> 
          <h1 className='text-xl font-bold text-sky-600 px-2 text-center'>{emptyTitle}</h1>
          <p className='text-sm text-red-400 text-center px-3'>{emptyDetails}</p>
    </div>
  )
}

export default EmptyContainer
