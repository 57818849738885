
import React, { useContext } from 'react'
import profileImage from '../assets/profileImage.png'
import { UserContext } from '../context/UserContext';

const ProfileSummary = () => {
  const {userDetails, dispatch} = useContext(UserContext);

  return (
    <div style={{height:'200px', width:'350px'}} className='p-5 bg-sky-100 flex gap-5 backdrop-filter backdrop-blur-lg bg-opacity-30'>
    <img
         src={profileImage}
          style={{objectFit:'cover',width:'100px', height:'100px', borderRadius:'100%'}}
         alt='welcome image'
         quality={100}
 
         />
         <div className='pr-3'>
           <h1 className='text-xl font-bold'>{userDetails?.name}</h1>
           <p className='text-sm'>{userDetails?.email}</p>
          {userDetails?.address && <p className='text-sm'>{userDetails?.address}, {userDetails?.city}, {userDetails?.state}</p>}
           <div className='flex gap-2 items-center justify-around p-5'>
               <div>
                   <h1 className='text-l font-bold'>$5900</h1>
                   <h1 style={{fontSize:'12px', textAlign:'center'}}>Wallet</h1>
               </div>
               <div>
                   <h1 className='text-l font-bold'>$50</h1>
                   <h1 style={{fontSize:'12px', textAlign:'center'}}>Profit</h1>
               </div>
           </div>
         </div>
    </div>
  )
}

export default ProfileSummary
