import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import ExpiredPortfolioComponents from '../components/ExpiredPortfolioComponents'
import Footer from '../components/Footer'
import EmptyContainer from '../components/EmptyContainer'

const page = () => {
  return (
    <div>
      <DashboardNavbar />
      <DashboardSidebar />
      <EmptyContainer emptyTitle='You dont have any Expired Portfolio' emptyDetails='Your Portfolio has to be active for over 9 months to fall into this category. Have you Purchased any Portfolio yet?' />
      {/* <ExpiredPortfolioComponents /> */}
      <Footer />
    </div>
  )
}

export default page
