import {createSlice} from '@reduxjs/toolkit'


const initialState = {
 
  fetchedUserInfo: {}, // for user object

}

export const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
     
        setFetchedUserInfo: (state,action)=>{ 
            state.fetchedUserInfo = action.payload  
        },
    
       
    }
});

export const {  
    setFetchedUserInfo,
 } = userSlice.actions


 export const selectFetchedUserInfo = (state) => state.user.fetchedUserInfo;
  
  


export default userSlice.reducer;