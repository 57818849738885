import React from 'react'
import Navbar from '../components/Navbar'
import TopTag from '../components/TopTag'
import InnerHeroComponent from '../components/InnerHeroComponent'
import './about.css'
import esgOne from '../assets/esg1.jpg'
import esgTwo from '../assets/esg2.jpg'
import esgThree from '../assets/esg3.jpg'
import Footer from '../components/Footer'

const ESG = () => {
  return (
    <div>
        <TopTag />
      <Navbar />
      <InnerHeroComponent title='Environmental, Social, and Governance (ESG) Policy' />
      <div className='aboutContainer'>
      <div className="aboutTop">
      <div>
        <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>Introduction:</p>
          <p className='text-sky-600 text-2xl font-bold'>Tibio is dedicated to conducting business with a strong commitment to environmental sustainability, social responsibility, and high governance standards. This ESG policy outlines our principles, practices, and ambitious projections for the year 2024 and beyond.</p>
          
          <div className='esgEachContainer'>
            <div className='esgLeft'>
            <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>I. Environmental Responsibility:</p>
            <div className=''>
            <h2 className='bg-sky-200 p-2 max-w-max m-2'>Short-Term (2024)</h2>
            <p className='text-lg'>Green Investments: Allocate a percentage of funds to environmentally responsible and sustainable investment opportunities. Energy Efficiency: Implement energy-efficient technologies within our operations.</p>
            </div>
            <div>
            <h2 className='bg-sky-200 p-2 max-w-max m-2'>Medium-Term (2024-2026)</h2>
            <p>Carbon Neutrality: Develop a plan to achieve carbon neutrality by 2026. Renewable Investments: Increase investments in renewable energy projects.</p>
            </div>
            <div>
            <h2 className='bg-sky-200 p-2 max-w-max m-2'>Long-Term (Beyond 2026)</h2>
            <p>Zero-Waste Operations: Work towards achieving zero-waste operations. Eco-Friendly Technologies: Explore and adopt emerging technologies with a reduced environmental impact.</p>
            </div>
                
            </div>

            <div className='esgRight'>
                <img src={esgOne} alt="" />
            </div>
        

          </div>

          <div className='esgEachContainer'>

          <div className='esgRight'>
                <img src={esgTwo} alt="" />
            </div>
        

            <div className='esgLeft'>
            <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>II. Social Responsibility:</p>
            <div className=''>
            <h2 className='bg-sky-200 p-2 max-w-max m-2'>Short-Term (2024)</h2>
            <p className='text-lg'>Diversity and Inclusion Training: Conduct diversity and inclusion training for all employees. Community Outreach: Launch community outreach programs in the regions where we operate.</p>
            </div>
            <div>
            <h2 className='bg-sky-200 p-2 max-w-max m-2'>Medium-Term (2024-2026)</h2>
            <p className='text-lg'>Social Impact Investments: Allocate funds to projects with a clear positive social impact. Employee Well-being Initiatives: Introduce programs focused on the well-being of our employees.</p>
            </div>
            <div>
            <h2 className='bg-sky-200 p-2 max-w-max m-2'>Long-Term (Beyond 2026)</h2>
            <p className='text-lg'>Education Initiatives: Contribute to education initiatives for underprivileged communities. Global Philanthropy: Establish a global philanthropy program to address social issues.</p>
            </div>
                
            </div>


          </div>

          <div className='esgEachContainer'>
            <div className='esgLeft'>
            <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>III. Governance Standards:</p>
            <div className=''>
            <h2 className='bg-sky-200 p-2 max-w-max m-2'>Short-Term (2024)</h2>
            <p className='text-lg'>Regular Audits: Conduct regular audits to ensure compliance with governance standards. Ethics Training: Provide ongoing ethics training for all staff members.</p>
            </div>
            <div>
            <h2 className='bg-sky-200 p-2 max-w-max m-2'>Medium-Term (2024-2026)</h2>
            <p className='text-lg'>Enhanced Transparency: Implement measures to enhance transparency in financial reporting. Risk Management: Strengthen risk management protocols.</p>
            </div>
            <div>
            <h2 className='bg-sky-200 p-2 max-w-max m-2'>Long-Term (Beyond 2026)</h2>
            <p className='text-lg'>Board Diversity: Strive for increased diversity on our board of directors. Global Governance Advocacy: Participate in global governance advocacy initiatives.</p>
            </div>
                
            </div>

            <div className='esgRight'>
                <img src={esgThree} alt="" />
            </div>
        

          </div>
         
         
        
            <div style={{maxWidth:'60%', margin:'100px auto'}} className='bg-sky-300 p-2'>
                <h1 className='text-center font-bold p-2 bg-white'>Projections for 2024</h1>
                <li>Financial Inclusion: Launch initiatives to increase financial inclusion, particularly in underserved regions.</li>
                <li> Sustainable Technologies: Invest in emerging sustainable technologies. </li>
                <li>Employee Volunteering: Encourage and support employee volunteering in local communities.</li>
            </div>

            <div>
                <h1 className='text-3xl font-bold text-center'>Continous Improvement</h1>
                <p className='text-3xl text-center'>We are committed to regular assessments and adjustments to ensure that our ESG practices align with evolving global standards and best practices. This comprehensive ESG policy is a testament to Tibio's dedication to responsible business practices and its vision for a sustainable and equitable future.</p>
            </div>
        

        </div>
      {/* <img src={robotLook} alt="tibio" /> */}

      </div>
      </div>
      <Footer />
    </div>
  )
}

export default ESG
