import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import AccountActivitiesColumn from '../components/AccountActivitiesColumn'
import Footer from '../components/Footer'

const page = () => {
  return (
    <div>
      <DashboardNavbar />
      <DashboardSidebar />
      <div style={{marginBottom:'200px'}}>
      <AccountActivitiesColumn />
      </div>
      <Footer />
    </div>
  )
}

export default page
