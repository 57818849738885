import  { useContext, useEffect, useState } from 'react'
import {BrowserRouter, Routes, Route, useParams, Link, Navigate} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login'
import AiTrading from './pages/AiTrading'
import Contact from './pages/Contact'
import Signup from './pages/Signup'
import Dashboard from './pages/Dashboard'
import Portfolios from './pages/Portfolios'
import ActivePortfolios from './pages/ActivePortfolios'
import ExpiredPortfolios from './pages/ExpiredPortfolios'
import Withdrawal from './pages/Withdrawal'
import WithdrawalHistory from './pages/WithdrawalHistory'
import AccountActivities from './pages/AccountActivities'
import Transfers from './pages/Transfers'
import Profile from './pages/Profile'
import Referral from './pages/Referral'
import ReferralBonus from './pages/ReferralBonus'
import TwoFA from './pages/TwoFA'
import UpdatePassword from './pages/UpdatePassword'

import Loader from './components/Loader'
import AboutUspage from './pages/About';
import PurchasePortfolioRates from './pages/PurchasePortfolioRates';
import { Player } from '@lottiefiles/react-lottie-player';
import goodSRC from './assets/goodAnimation.json'
import logo from './assets/aitradingLogo.png'
import  axios from 'axios';
import Finance from './pages/Finance';
import Performance from './pages/Performance';
import { AuthContext } from './context/AuthContext';
import LoginPassword from './pages/LoginPassword';
import SignupPassword from './pages/SignupPassword';
import ContactMessage from './pages/ContactMessage';
import { selectUserInfo } from './slices/authSlice';
import { useSelector } from 'react-redux'
import Wallets from './pages/Wallets';
import ChooseTransfer from './pages/ChooseTransfer';
import TransferToWallet from './pages/TransferToWallet';
import TransferToWalletAmount from './pages/TransferToWalletAmount';
import TransferToDownline from './pages/TransferToDownline';
import FundWallet from './pages/FundWallet';
import FundWalletSuccess from './pages/FundWalletSuccess';
import FundWalletFailure from './pages/FundWalletFailure';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentFailure from './pages/PaymentFailure';
import ReInvest from './pages/ReInvest';
import ReInvestAmount from './pages/ReInvestAmount';
import Footer from './components/Footer';
import SignupWithReferral from './pages/SignupWithReferral';
import TransferFromWalletAmount from './pages/TransferFromWalletAmount';
import TransferToDownlineAmount from './pages/TransferToDownlineAmount';
import ESG from './pages/ESG';
import ForgetPassword from './pages/ForgetPassword';
import SignupWithReferralPassword from './pages/SignupWithReferralPassword';
import ForgetPasswordUpdate from './pages/ForgetPasswordUpdate';


function App() {

    const [isLoading, setIsLoading] = useState(true);
    // const {user} = useContext(AuthContext)
    const userInfo = useSelector(selectUserInfo)

    const ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'

    useEffect(() => {
     
      setTimeout(() => {
        setIsLoading(false);
      }, 3000); 
    }, []);



   const ProtectedRoute =({children})=>{
            
    if(!userInfo?.user){
     return <Navigate to='/login' />
    }
    return children
 }



    const VerifyOTPComponent = () => {

      const { userId, otp } = useParams();
      const [showVerify, setShowVerify] = useState(false)

      console.log(userId, otp)

      useEffect(()=>{

        const verifyOTPFunction=async()=>{


      const urlString = window.location.href ;
        const url = new URL(urlString);
        const pathname = url.pathname; // This will give you '/6564ad9ec0f4500e63ff1b61/9921'

        // To extract the two parts separately:
        const parts = pathname.split('/').filter(Boolean); // Split the pathname into parts
        const firstPart = parts[0]; // '6564ad9ec0f4500e63ff1b61'
        const secondPart = parts[1]; // '9921'
          console.log(parts)
        console.log(firstPart, secondPart);

        // if(ENDPOINT == url ){
        //   console.log('true')
        // } else {
        //   console.log('false')
        //   console.log(ENDPOINT)
        // }

        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        fetch(`${ENDPOINT}/api/auth/verify-otp/${firstPart}/${secondPart}`, requestOptions)
          .then(response => response.json())
          .then(result =>{
            if(result?.user){
              setShowVerify(true)
            } else{
              alert('Error verifying, check your network and try again later')
              console.log('error verifying, click on the link again')
            }
            console.log(result)
          })
          .catch(error => console.log('error', error));

        }

        verifyOTPFunction()
      }, [])
      
    return (
      <>
      { showVerify &&
          <div style={{width:'100vw',height:'100vh'}} className='flex flex-col gap-2 items-center justify-center'>
  
          <Player
                  src={goodSRC}
                  className="player"
                  loop
                  autoplay
                   style={{ height: '100px', width: '100px' }}
                  
                  />
                  <h1 className='text-3xl font-light'>Congratulations!</h1>
                  <h1 className='text-3xl font-light '>You're Verified</h1>
                  <Link className='bg-sky-100 py-2 px-4 rounded-lg font-bold hover:text-white hover:bg-sky-700' to='/login'>Login</Link>
                  <img 
                  src={logo}
                  alt='logo image'
                  style={{ height: '100px', width: '100px' }}
                  />
              </div>
      }
      </>
    
    )
  }

  useEffect(() => {
    const currentUrl = window.location.href;
    const isVerificationLink = /^https:\/\/tibio\.ai\/\d+\/\d+$/i.test(currentUrl);

    if (isVerificationLink) {
      // Extract user ID and OTP from the URL
      console.log('verification...')
      const [, userId, otp] = currentUrl.match(/^https:\/\/tibio\.ai\/(\d+)\/(\d+)$/i);
      window.location.replace(`/${userId}/${otp}`);
    }
  }, []);

 

  return (
    <div style={{ overflow:'hidden',position:'inherit',height:'100%'}}>
     <BrowserRouter>
     {/* {isLoading ? <Loader /> : null} */}
   
     <Routes>
     <Route path='/'  element={<Home/>} />
     <Route path='/login'  element={<Login />} />
     <Route path='/loginPassword'  element={<LoginPassword />} />
     <Route path='/aiTrading'  element={<AiTrading />} />
      <Route path='/contact'  element={<Contact />} />
      <Route path='/contactMessage'  element={<ContactMessage />} />
      <Route path='/about'  element={<AboutUspage />} />
      <Route path='/forgotPassword'  element={<ForgetPassword />} />
      <Route path='/createNewPassword'  element={<ForgetPasswordUpdate />} />
      <Route path='/signup'  element={<Signup />} />
      <Route path='/register/referral/:referralID'  element={<SignupWithReferral />} />
      <Route path='/signupPassword'  element={<SignupPassword />} />
      <Route path='/signupWithReferralPassword'  element={<SignupWithReferralPassword />} />
      <Route path='/dashboard'  element={ <ProtectedRoute>
                                            <Dashboard />
                                          </ProtectedRoute>    
                                          } />
        <Route path='/portfolios'  element={<ProtectedRoute>
                                              <Portfolios  /> 
                                              </ProtectedRoute>} />
        <Route path='/portfoliosRates'  element={ <ProtectedRoute>
                                                  <PurchasePortfolioRates  />
                                                  </ProtectedRoute>} />
      <Route path='/activePortfolios'  element={<ProtectedRoute>
                                                  <ActivePortfolios />
                                                  </ProtectedRoute>} />
      <Route path='/expiredPortfolios'  element={ <ProtectedRoute>
                                                    <ExpiredPortfolios />
                                                    </ProtectedRoute>} />
      <Route path='/withdrawal'  element={<ProtectedRoute>
                                                <Withdrawal />
                                                </ProtectedRoute>} />
      <Route path='/withdrawalHistory'  element={ <ProtectedRoute>
                                                  <WithdrawalHistory />
                                                  </ProtectedRoute>} />
      <Route path='/accountActivities'  element={ <ProtectedRoute>
                                                    <AccountActivities />
                                                    </ProtectedRoute>} />
     <Route path='/reInvest'  element={<ProtectedRoute>
                                            <ReInvest />
                                            </ProtectedRoute>} />
      <Route path='/reInvestAmount'  element={<ProtectedRoute>
                                            <ReInvestAmount />
                                            </ProtectedRoute>} />
      <Route path='/transfers'  element={<ProtectedRoute>
                                            <Transfers />
                                            </ProtectedRoute>} />
      <Route path='/transferFromWalletAmount'  element={<ProtectedRoute>
                                            <TransferFromWalletAmount />
                                            </ProtectedRoute>} />

         <Route path='chooseTransfers'  element={<ProtectedRoute>
                                            <ChooseTransfer />
                                            </ProtectedRoute>} />

         <Route path='transferToWallet'  element={<ProtectedRoute>
                                            <TransferToWallet />
                                            </ProtectedRoute>} />
         <Route path='transferToWalletAmount'  element={<ProtectedRoute>
                                            <TransferToWalletAmount />
                                            </ProtectedRoute>} />
         <Route path='transferToDownline'  element={<ProtectedRoute>
                                            <TransferToDownline />
                                            </ProtectedRoute>} />
         <Route path='transferToDownlineAmount'  element={<ProtectedRoute>
                                            <TransferToDownlineAmount />
                                            </ProtectedRoute>} />

         <Route path='fundWallet'  element={<ProtectedRoute>
                                            <FundWallet />
                                            </ProtectedRoute>} />
          <Route path='paymentSuccess'  element={<ProtectedRoute>
                                            <PaymentSuccess />
                                            </ProtectedRoute>} />
          <Route path='paymentFailure'  element={<ProtectedRoute>
                                            <PaymentFailure />
                                            </ProtectedRoute>} />
          <Route path='fundWalletSuccess'  element={<ProtectedRoute>
                                            <FundWalletSuccess />
                                            </ProtectedRoute>} />
          <Route path='fundWalletFailure'  element={<ProtectedRoute>
                                            <FundWalletFailure />
                                            </ProtectedRoute>} />

      <Route path='/profile' element={ <ProtectedRoute>
                                        <Profile />
                                        </ProtectedRoute>} />
      <Route path='/referal' element={<ProtectedRoute>
                                        <Referral />
                                        </ProtectedRoute>} /> 
      <Route path='/referralBonus' element={ <ProtectedRoute>
                                              <ReferralBonus />
                                              </ProtectedRoute>} /> 
      <Route path='/twoFA' element={ <ProtectedRoute>
                                          <TwoFA />
                                        </ProtectedRoute>} /> 
      <Route path='/updatePassword' element={ <ProtectedRoute>
                                                <UpdatePassword />
                                                </ProtectedRoute>} /> 
       <Route path='/wallets' element={ <ProtectedRoute>
                                                <Wallets />
                                                </ProtectedRoute>} /> 
      <Route path='/finance' element={<Finance />} /> 
      <Route path='/esg' element={<ESG />} /> 
      <Route path='/performance' element={<Performance />} /> 
      <Route path="/:userId/:otp" element={ <VerifyOTPComponent />}/>
       
     
     </Routes>
    {/* <Footer /> */}
     </BrowserRouter>
      
    </div>
  )
}

export default App
