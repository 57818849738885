import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import AllWallets from '../components/AllWallets'
import Footer from '../components/Footer'

const Wallets = () => {
  return (
    <div>
            <DashboardNavbar />
      <DashboardSidebar />
      <AllWallets />
 
      <Footer />
    </div>
  )
}

export default Wallets
