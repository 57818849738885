import React, { useState } from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import styles from './purchasePortfolioRates.module.css'
import moment from 'moment';
import robot from '../assets/robotLogo.png'
import {useLocation, useNavigate} from 'react-router-dom'
import { IoArrowUndo } from "react-icons/io5";
import PurchasePortfolioAmountColumn from '../components/PurchasePortfolioAmountColumn';
import Footer from '../components/Footer';

const PurchasePortfolioRates = () => {
  const location = useLocation();
  const {state} = location;
  const navigate = useNavigate()

  const [selectedOption, setSelectedOption] = useState('compounding');

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(selectedOption)
  };

  console.log(location)
  console.log(state)
 console.log(selectedOption)
 console.log(state.amount)
 console.log(state.dailyROI)
 console.log(state.compoundingROI)
 
  return (
    <div>
       <DashboardNavbar />
      <DashboardSidebar />
      <div className='m-8'>
      <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
      <PurchasePortfolioAmountColumn />
      </div>
      <Footer />
    </div>
  )
}

export default PurchasePortfolioRates
