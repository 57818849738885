import React, { useState,useEffect } from 'react'
import styles from './transfersColumn.module.css'
import moment from 'moment';
import robot from '../assets/robotLogo.png'
import {useLocation, useNavigate} from 'react-router-dom'
import { IoArrowUndo } from "react-icons/io5";
import purchaseImage from '../assets/purchasePortfolio.jpg'
import { useSelector, useDispatch } from 'react-redux'
import { selectPortfolioDetails, setPortfolioDetails} from '../slices/PortfolioSlice';
import { plisio } from './Plisio';
import { selectUserInfo} from '../slices/authSlice';

const ReInvestAmountComponent = () => {
    const location = useLocation();
    const {state} = location;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const reduxedPortfolioDetails = useSelector(selectPortfolioDetails) 
  
    const [selectedOption, setSelectedOption] = useState('compounding');

    const [count, setCount] = useState(0)
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'
    const userInfo = useSelector(selectUserInfo)

    useEffect(()=>{
        const fetchCount = async()=>{

            var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_ENDPOINT}/api/count`, requestOptions)
      .then(response => response.json())
      .then(result => {
       setCount(result.count)
     
        console.log(result)
      })
      .catch(error => console.log('error', error));

  }

  fetchCount()
    },[])

    const handleCheckboxChange = (event) => {
        setSelectedOption(event.target.value);
        console.log(selectedOption)
      };

      const portfolioDetails = {
        selectedOption,
        amount:state.amount,
        dailyProfit: state.dailyROI,
        compoundingProfit: state.compoundingROI,
      }
      const handlePurchaseSubmit=async(e)=>{
        e.preventDefault()

        dispatch(setPortfolioDetails(portfolioDetails))
        // call the plisio payment function
        plisio(Number(state.amount), count, userInfo.user.email)
      }

      console.log(reduxedPortfolioDetails)
      console.log(count)

  return (
    <div className={styles.transferImagesContainer}>

    <img
            src={purchaseImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
  <div className={styles.transferContainer}>

<div  className='flex gap-3  items-center'>
<div>
<img
src={robot}
className={styles.loginImage}
style={{ width: 300, height: 200, zIndex:10, objectFit:'contain' }} // optional
alt='welcome image'
/>
</div>
<form onSubmit={handlePurchaseSubmit} style={{width:'400px'}} className='flex flex-col gap-3 '>
  <h1 className='text-2xl font-bold pb-6 pt-12 text-center text-white'>Return on Investment(ROI)</h1>
  <p className='text-sky-300'>ROI&apos;s are generated by AI based on todays market and projection for the future for maximum profit</p>
  <label className={styles.emailText} htmlFor="email">Daily Profit</label>
  <input disabled value={state.dailyROI} style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:20, fontWeight:'bold', textAlign:'center'}} type="number" name='roi' />
  <label className={styles.emailText} htmlFor="email">Compounding Profit</label>
  <input disabled value={state.compoundingROI} style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:20, fontWeight:'bold', textAlign:'center'}} type="number" name='roi' />
 
 <div className='flex items-center justify-center gap-10'>

 <div className='flex items-center'>
  <input
   value="compounding"
   checked={selectedOption === 'compounding'}
   onChange={handleCheckboxChange}
  className='w-5 h-5' type="checkbox" name="compounding" id="compounding" />
  <label className='ml-2 text-white' htmlFor="compounding">Compounding</label>
  </div>

  <div className='flex items-center'>
  <input 
   value="daily"
   checked={selectedOption === 'daily'}
   onChange={handleCheckboxChange} className='w-5 h-5 ' type="checkbox"  name='daily' id='daily' />
  <label className='ml-2 text-white'  htmlFor="daily">Daily</label>
  </div>

 
 </div>
  <button className={styles.loginButton} type="submit">Purchase</button>

</form>
</div>

<div style={{margin:'20px auto'}} className='flex flex-col items-center'>
    <h1 className='text-xl font-bold text-sky-300'>ROI Projection for the next few days</h1>
  
    <div style={{width:'80%'}} className='flex justify-between items-center bg-white p-2 m-1 shadow-md'>
    <p>{state.tomorrow}</p>
    <p> {selectedOption === 'daily' ? state.tomorrowProjection : state.tomorrowCompoundingProjection}% on ROI</p>
    </div>

    <div style={{width:'80%'}} className='flex justify-between items-center bg-yellow-50 p-2 m-1 shadow-md'>
    <p>{state.nextTomorrow}</p>
    <p> {selectedOption === 'daily' ? state.nextTomorrowProjection : state.nextTomorrowCompoundingProjection}% on ROI</p>
    </div>

    <div style={{width:'80%'}} className='flex justify-between items-center bg-purple-50 p-2 m-1 shadow-md'>
    <p>{state.nextNextTomorrow}</p>
    <p> {selectedOption === 'daily' ? state.nextNextTomorrowProjection : state.nextNextTomorrowCompoundingProjection}% on ROI</p>
    </div>

    <div style={{width:'80%'}} className='flex justify-between items-center bg-purple-50 p-2 m-1 shadow-md'>
    <p>{state.fourthDay}</p>
    <p> {selectedOption === 'daily' ? state.fourthDayProjection : state.fourthDayCompoundingProjection}% on ROI</p>
    </div>

    <div style={{width:'80%'}} className='flex justify-between items-center bg-purple-50 p-2 m-1 shadow-md'>
    <p>{state.fifthDay}</p>
    <p> {selectedOption === 'daily' ? state.fifthDayProjection : state.fifthDayCompoundingProjection}% on ROI</p>
    </div>
      
  
</div>

</div>
</div>
  )
}

export default ReInvestAmountComponent
