import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player';
import goodSRC from '../assets/goodAnimation.json'
import styles from './transfersColumn.module.css'
import purchaseImage from '../assets/purchasePortfolio.jpg'
import { FaRegTimesCircle } from 'react-icons/fa'

const PaymentFailureComponent = () => {
  const { data } = useParams();
  console.log('failed data:', data)
  return (
    <div className={styles.transferImagesContainer}>

    <img
            src={purchaseImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
  <div className={styles.transferContainer}>
  
    {/* <Player
            src={goodSRC}
            className="player"
            loop
            autoplay
             style={{ height: '100px', width: '100px' }}
            
            /> */}
            <div className='flex items-center justify-center'>
            < FaRegTimesCircle  className='text-7xl text-red-700 text-center m-3 ' />
            </div>
         
            <h1 className='text-3xl font-light text-white text-center'>Oops</h1>
            <h1 className='text-3xl font-light text-white text-center'>Payment Failed, Go back and try again</h1>
            <div style={{textAlign:'center', margin:'20px auto'}}>
            <Link className='bg-sky-100 py-2 px-4 rounded-lg font-bold hover:text-white hover:bg-sky-700 text-center' to='/dashboard'>Dashboard</Link>
            </div>
           
            {/* <img 
            src={logo}
            alt='logo image'
            style={{ height: '100px', width: '100px' }}
            /> */}
        </div>
        </div>
  )
}

export default PaymentFailureComponent
