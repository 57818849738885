import React,{useState} from 'react'
import videoFile from '../assets/aiVideo.mp4'
import styles from  './contact.module.css'
import { FaTelegram } from "react-icons/fa";
import { FaRegTimesCircle } from 'react-icons/fa'
import robot from '../assets/robot.png'
import {useNavigate} from 'react-router-dom'
import techHand from '../assets/techHand.jpg'

const ContactUsColumn = () => {
   
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)
    const [email, setEmail] = useState('')
    const navigate = useNavigate()
    const [removeEnterEmail, setRemoveEnterEmail] = useState(true)
  
    const handleClosePopup =()=>{
      setShowPopupModal(false)
       setPopUp('')
      }
     

    const handleNext=(e)=>{
        e.preventDefault()
        console.log(email)
        if(email){
          navigate('/contactMessage',{state:{email:email}})
        } else {
          setPopUp('Fill in your valid email address to continue')
          setShowPopupModal(true)
        }
      
    }

  return (
    <div className={styles.imageContainer}  >
         {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
    {/* {loading ? <Loader /> : null} */}
          {/* <video className={styles.heroImage} autoPlay loop muted>
          <source src={videoFile} type='video/mp4' />
         </video> */}
         <img
        src={techHand}
        alt='welcome image'
        quality={100}
       className={styles.heroImage}

        />
      <div className={styles.text}>
      

        <div className={styles.contactInnerContainer}>

        <div className='flex gap-3 bg-sky-100 p-4 w-fit text-black items-center m-auto cursor-pointer mt-8'>
        <FaTelegram className='font-bold text-3xl' />
        <p>Live chat with telegram bot</p>
        </div>
      
    <div className={styles.chatContainer}>

  <div className=''>
  <img
  src={robot}
  //  style={{width:600, height:400, objectFit:'contain'}}
  alt='welcome image'
  quality={100}
  className={styles.loginImage}

  />
  </div>
  <form style={{width:'300px'}} className='flex flex-col gap-3'>
    {/* <h1 className='text-2xl font-bold pb-6 pt-2 text-center'>Send a Message</h1> */}
  <div className={styles.inputContainer}>

    <input
    // onKeyUp={debouncedAutoSubmitEmail}
    placeholder='Enter a Reply Email'
    onFocus={()=> { setRemoveEnterEmail(false)}}
    onChange={(e)=>setEmail(e.target.value)}
     style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10}}
      type="email" name='email'
      className={styles.emailInput}
      />
        {/* {removeEnterEmail &&  <label className={styles.emailText} htmlFor="email">Enter a Reply Email</label>} */}
      </div>
  <button
   onClick={handleNext}
   className={styles.btn} type="submit">Next</button>


  </form>
</div>

</div>

{/* { showModal &&  <div

  className={styles.modal}>
     < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
      <div className='flex gap-3 my-11 bg-sky-200/50 mx-11 p-5 items-center backdrop-filter backdrop-blur-lg bg-opacity-30'>
        <div className=''>
        <img
        src={robot}
         style={{width:600, height:400, objectFit:'contain'}}
         className={styles.loginImage}
        alt='welcome image'
        quality={100}
       

        />
        </div>
        <form
        
          style={{width:'300px'}} className='flex flex-col gap-3' >

          <label className={styles.emailText} htmlFor="email">Type Your Message</label>
          <textarea
           onFocus={()=> setError('')}
            onChange={(e)=>setPassword(e.target.value)}
           style={{backgroundColor:'white', height:'100px', width:'100%', outline:'none', paddingLeft:10}} type="text" name='password'
            />

          <button className={styles.btn} type="submit">Submit</button>
         
          { error && <p className='px-3 py2 font-bold bg-red-500 text-white w-fit rounded-md  text-xs'>{error}</p>}

        </form>
      </div>

      </div> } */}

      
      </div>

    

  </div>
  )
}

export default ContactUsColumn
