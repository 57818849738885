import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import ProfileDetails from '../components/ProfileDetails'
import Footer from '../components/Footer'

const page = () => {
  return (
    <div>
       <DashboardNavbar />
      <DashboardSidebar />
      <ProfileDetails />
      <Footer />
    </div>
  )
}

export default page
