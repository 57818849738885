
import Popup from "./Popup";

export const plisio=async(amount, count, email)=>{
    const proxyUrl = 'http://localhost:3000'

   
    var myHeaders = new Headers();
myHeaders.append("Cookie", "lang=en; uid=X9nCKmWZK1KAO17HAwMrAg==");

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

console.log(amount,  email)


// fetch(`${proxyUrl}/https://plisio.net/api/v1/invoices/new?source_currency=USD&source_amount=${amount}&order_number=${count}&currency=BTC&email=${email}&order_name=btc1&callback_url=http://tibio.ai/dashboard&api_key=bNlT5tgtkT2AL9GOFDpHxS8PQGigQfKo0HDbC7bDqcDQC5D99jX6YuHIRn2HaMju&success_callback_url=https://tibio.ai/paymentSuccess&fail_callback_url=https://tibio.ai/paymentFailure`, requestOptions)
fetch(`https://plisio.net/api/v1/invoices/new?source_currency=USD&source_amount=${amount}&order_number=${count}&currency=BTC&email=${email}&order_name=btc1&callback_url=http://tibio.ai/dashboard&api_key=bNlT5tgtkT2AL9GOFDpHxS8PQGigQfKo0HDbC7bDqcDQC5D99jX6YuHIRn2HaMju&success_callback_url=https://tibio.ai/paymentSuccess&fail_callback_url=https://tibio.ai/paymentFailure`, requestOptions)
  .then(response => response.json())
  .then(result =>{
    if (result.status === "success") {
        // Proceed to the next step
        window.location.href = `${result.data.invoice_url}`;
      } else {
        // Payment was not successful, handle accordingly
        console.log("Payment failed");
        <Popup title='Network error creating invoice, Go back and try again' />
        return
      }
    console.log(result)})
  .catch(error => console.log('error', error));

}