
import React, { useEffect, useState } from 'react'
import styles from './homeHero.module.css'
// import Loader from './Loader';
import Loader  from './Loader'
import homeImage from '../assets/homeImage.jpg'
import robot from '../assets/robot.png'
import { Link } from 'react-router-dom'

const HomeHeroColumn = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);

  return (
    <div className={styles.imageContainer}  >
      {loading ? <Loader /> : null}
        <img
        src={homeImage}
        alt='welcome image'
       className={styles.heroImage}

        />
        <div className={styles.text}>

          <h1>Welcome to Tibio</h1>
          <p>Let Tibio AI Invest for you</p>
          <Link to='/login'>
          <button  className={styles.btn}>Get Started</button>
          </Link>
          
        </div>
 
      
        <img
        src={robot}
        width={100}
         height={50}
         style={{width:100, height:50, objectFit:'contain'}}
        alt='welcome image'
       className={styles.heroRobot}

        />
    </div>
  )
}

export default HomeHeroColumn
