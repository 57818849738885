import React, { useContext, useEffect, useState } from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import ProfileSummary from '../components/ProfileSummary'
import styles from './twoFA.module.css'
import robot from '../assets/robotLogo.png'
import {useNavigate} from 'react-router-dom'
import OtpInput from 'react-otp-input';
import { AuthContext } from '../context/AuthContext'
import { FaRegTimesCircle } from 'react-icons/fa'
import { Player } from '@lottiefiles/react-lottie-player';
import loaderSRC from '../assets/robotAnimation.json'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'
import WalletSlideColumn from '../components/WalletSlideColumn'
import purchaseImage from '../assets/techHand.jpg'
import Footer from '../components/Footer'

const TwoFA = () => {

  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const {user} = useContext(AuthContext)
  const navigate  = useNavigate()
const [popUp, setPopUp] = useState('')
const [showPopupModal, setShowPopupModal] = useState(false)

const userInfo = useSelector(selectUserInfo)
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'



  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
      navigate('/login')
    }


  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);

  const handleActivateTwoFA=async()=>{

    var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

      var formdata = new FormData();
      formdata.append("two_activated", true);

      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${API_ENDPOINT}/api/users/update-twoFA`, requestOptions)
        .then(response => response.json())
        .then(result => {
          
          setPopUp('Two Factor Authentication(2FA) activated successfully')
          setShowPopupModal(true)
          console.log(result)})
        .catch(error => console.log('error', error));
        
        
          
  }

  return (
    <div>
       <DashboardNavbar />
      <DashboardSidebar />

      <div className='p-8'>
      <div className={styles.transferImagesContainer}>

    <img
            src={purchaseImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
            <div className={styles.transferContainer}>
        
        <div style={{width:'65%', margin:'auto'}} className=' my-11 bg-sky-200/50 mx-11 p-5  backdrop-filter backdrop-blur-lg bg-opacity-30'>

<div style={{width:'100%', margin:'auto'}} className='flex flex-col gap-3 ' action="">
  <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Activate 2FA</h1>
   <p className='text-xl text-center'>By activating Two Factor Authentication, You will be required to provide a four(4) digit code that will be sent to your email address, This secures your account, Tibio recommends</p>
<button onClick={handleActivateTwoFA} className={styles.btn} type="submit">Activate 2FA</button>

</div>
</div>

 {/* popup */}
 { showPopupModal && 
   <div className='showContainer'>

        <div className='innerShowContainer'>
        < FaRegTimesCircle onClick={handleClosePopup} className='showClose' />
            <div className='flex gap-2 flex-col'>
            <Player
          src={loaderSRC}
          className="player"
          loop
          autoplay
           style={{ height: '50px', width: '50px' }}
          
          />
            <p className='showText'>{popUp}</p>
            </div>
           
            <button className='showButton' onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

      </div>
      </div>

      </div>
      <Footer />
    </div>
  )
}

export default TwoFA
