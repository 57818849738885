import React, { useEffect, useState } from 'react'
import './news.css'
import axios from 'axios'
import logo from '../assets/aitradingLogo.png'

const News = () => {
    const [news, setNews] = useState([])
    const [curr, setCurr] = useState(0);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'

    useEffect(()=>{
        const loadNews =async()=>{
            const response = await axios.get(`${API_ENDPOINT}/api/news`)
          console.log(response)
            console.log(response.data)
            setNews(response.data)
        }

        loadNews()
    },[])

    let autoSlide = true;
    let autoSlideInterval = 15000;

    const prev = () =>
    setCurr((curr) => (curr === 0 ? news.length - 1 : curr - 1))
  const next = () =>
    setCurr((curr) => (curr === news.length - 1 ? 0 : curr + 1))

  useEffect(() => {
    if (!autoSlide) return
    const slideInterval = setInterval(next, autoSlideInterval)
    return () => clearInterval(slideInterval)
  }, [])


  return (
    <div className='newsContainer'>
      {
        news.map((details, index)=>(
            <a  href={details.url}  className={index === curr ? 'newsInnerContainer activate' : 'newsInnerContainer'} key={index}>
               {index === curr && (
          <>
                <div className='left'>
                    <h1>{details.title}</h1>
                    <p>{details.description}</p>
                    <p className='font-bold text-right'>{details.author}</p>
                    <p className='font-bold text-right'>{details.source.name}</p>
                </div>
                <div className='right'>
                <img src={details.urlToImage ? details.urlToImage : logo} alt={details.content} />
                </div>
              
                </>
        )}
            </a>
        ))
      }
    </div>
  )
}

export default News
