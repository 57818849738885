import React, { useEffect, useState } from 'react'
import Loader from './Loader';
import styles from './allWallets.module.css'
import { FaWallet } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import {setFetchedUserInfo, selectFetchedUserInfo } from '../slices/userSlice'
const AllWallets = () => {
    const [loading, setLoading] = useState(true);
    const fetchedUserInfo = useSelector(selectFetchedUserInfo)
    

    console.log(fetchedUserInfo)
    useEffect(() => {
   
        setTimeout(() => {
          setLoading(false);
        }, 3000); 
      }, []);
  return (
    <div>
         {loading ? <Loader /> : null}

       
          <div style={{width:'250px', margin:'20px auto'}}>
          <Link to='/fundWallet' >
          <button className={styles.fund}>Fund Wallet</button>
          </Link>
          </div>
         
      
      <div className={styles.walletsInnerContainer}>

      <div className={styles.mainWallet}>
            <h1 className='text-lg font-bold'>Main Wallet</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(fetchedUserInfo?.mainWallet) > 0 ? fetchedUserInfo?.mainWallet : 0 }</h4>
            <div  style={{bottom:'-10px'}}  className='flex gap-2 items-center justify-center absolute'>
            <Link to='/transfers'>
            <button  className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>Transfer </button>
            </Link>
            <Link to='/withdrawal'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>Withdraw </button>
            </Link>
            <Link to='/reInvest'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>Re-Invest </button>
            </Link>
            </div>

        </div>

        <div className={styles.eachWallet}>
            <h1 className='text-lg font-bold'>Daily Profit</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(fetchedUserInfo?.dailyWalletTotal) > 0 ? Math.round(fetchedUserInfo?.dailyWalletTotal) : 0 }</h4>
            <Link style={{position:'absolute',bottom:'-10px'}} to='/transferToWallet'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>Transfer to Main Wallet <FaArrowRight /> </button>
            </Link>
        </div>

        <div className={styles.eachWallet}>
            <h1 className='text-lg font-bold'>Compounding Profit</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(fetchedUserInfo?.compoundingWalletTotal) > 0 ? fetchedUserInfo?.compoundingWalletTotal : 0 }</h4>
            {/* <Link style={{position:'absolute',bottom:'-10px'}} to='/chooseTransfers'> */}
            {/* <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>Transfer to Main Wallet <FaArrowRight /> </button> */}
            {/* </Link> */}
        </div>
        <div className={styles.eachWallet}>
            <h1 className='text-lg font-bold'>Referral Commission</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(fetchedUserInfo?.referralCommissionWalletTotal) > 0 ? fetchedUserInfo?.referralCommissionWalletTotal : 0 }</h4>
            <Link style={{position:'absolute',bottom:'-10px'}} to='/transferToWallet'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>Transfer to Main Wallet <FaArrowRight /> </button>
            </Link>
        </div>
        <div className={styles.eachWallet}>
            <h1 className='text-lg font-bold'>Downline Commission</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(fetchedUserInfo?.downlineCommissionWalletTotal) > 0 ? fetchedUserInfo?.downlineCommissionWalletTotal : 0 }</h4>
            <Link style={{position:'absolute',bottom:'-10px'}} to='/transferToDownline'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>Transfer to Downline <FaArrowRight /> </button>
            </Link>
        </div>
        <div className={styles.eachWallet}>
            <h1 className='text-lg font-bold'>Bonus</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(fetchedUserInfo?.bonusWalletTotal) > 0 ? fetchedUserInfo?.bonusWalletTotal : 0 }</h4>
            <Link  style={{position:'absolute',bottom:'-10px'}} to='/transferToWallet'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>Transfer to Main Wallet <FaArrowRight /> </button>
            </Link>
        </div>
       

      </div>
    </div>
  )
}

export default AllWallets
