import React from 'react'
import TopTag from '../components/TopTag'
import Navbar from '../components/Navbar'
import HomeHeroColumn from '../components/HomeHeroColumn'
import CryptoBinance from '../components/CryptoBinance'
import TradingViewOne from '../components/TradingViewOne'
// import TradingViewWidget from '../components/TradingViewWidget'
import { TickerTape } from "react-ts-tradingview-widgets";
import TradingViewWidgetTwo from '../components/TradingViewWidgetTwo'
import TradingViewWidgetThree from '../components/TradingViewWidgetThree'
import Footer from '../components/Footer'
import StockHeatMap from '../components/StockHeatMap'
import CryptoCoinHeatMap from '../components/CryptoCoinHeatMap'
import { MarketOverview } from "react-ts-tradingview-widgets";
import { EconomicCalendar } from "react-ts-tradingview-widgets";
import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";
import LogosSlide from '../components/LogosSlide'
import flyerImage from '../assets/flyerImage.jpeg'
import styles from './aiTrading.module.css'
import { Timeline } from "react-ts-tradingview-widgets";
import News from '../components/News'

const aiTrading = () => {
  const customCopyrightStyles = {
    display: 'none', 
    opacity: 0,
    color:'white'
  };

  // var perf = require('../components/news.html')
  return (
    <div>
         <TopTag />
         {/* <TradingViewWidget /> */}


      {/* <TickerTape copyrightStyles={customCopyrightStyles}  colorTheme="dark"></TickerTape> */}
        <Navbar />
        <HomeHeroColumn />
        {/* <CryptoCoin /> */}
        <div className='p-8 my-9'>
        <CryptoBinance />
     
        </div>
        <div className='p-8 my-9' style={{height:600, width:'80%', margin:'auto' }}>

        <TradingViewOne />

        </div>

        {/* <div className='p-8 my-9' style={{display:'flex', alignItems:'center', justifyContent:'center', width:'80%', margin:'auto', paddingLeft:'50px', marginLeft:'100px' }}>

          <TradingViewWidgetTwo />

          <div>
            <h1>Stock Market</h1>
            <p>Latest Gainers and Losers in the stock market</p>
          </div>

          </div> */}

          <h1 className='text-2xl text-center font-bold bg-sky-50 text-sky-800 p-2 w-fit m-auto'>Forex Cross Rates</h1>
          <div className={styles.forexContainer} style={{ width:'90%', margin:'auto', }}>
          <div className='flex-1'>
          <TradingViewWidgetThree />
          </div>
           
            {/* <div className='flex-1'>
              <img style={{width:'100%', height:'500px', objectFit:'contain'}} src={flyerImage} alt="flyerImage" />
            </div> */}

          </div>

          <h1 className='text-2xl text-center font-bold bg-sky-50 text-sky-800 p-2 w-fit m-auto'>CryptoCurrency Market</h1>
          <div className='p-8 my-9' style={{ width:'80%', margin:'auto', paddingLeft:'50px',height:'600px' }}>

            {/* <StockHeatMap /> */}
            {/* <CryptoCoinHeatMap /> */}
            <CryptoCurrencyMarket colorTheme="light" width="100%" height={400}></CryptoCurrencyMarket>

          </div>
          <h1 className='text-2xl text-center font-bold bg-sky-50 text-sky-800 p-2 w-fit m-auto'>Market Overview</h1>
          <div className={styles.forexContainer} style={{ width:'90%', margin:'auto', }}>
         
            <div className='flex-1'>
               <iframe src='https://5a304fe58db6417395dd1637a21398ca.elf.site' width='100%' height='400' frameborder='0'></iframe>
            {/* <Timeline colorTheme="dark" feedMode="market" market="crypto" height={400} width="100%"></Timeline> */}
            </div>
         <div className='flex-1'>
        <MarketOverview colorTheme="light" height={400} width="100%" showFloatingTooltip></MarketOverview>
         </div>
       
      </div>

      <div className={styles.newsContainer}>
  {/* show news */}
 
          <News />
</div>

      <h1 className='text-2xl text-center font-bold bg-sky-50 text-sky-800 p-2 w-fit m-auto'>Economic Calender</h1>
          <div className='p-8 my-9' style={{ width:'80%', margin:'auto', paddingLeft:'50px',height:'600px' }}>

          <EconomicCalendar colorTheme="dark" height={400} width="100%"></EconomicCalendar>
      </div>

      <div className='p-8 my-9' style={{ width:'100%', margin:'auto', paddingLeft:'50px',height:'600px' }}>

          <LogosSlide />
</div>


      <Footer />
    </div>
  )
}

export default aiTrading
