import React, { useState, useEffect } from 'react'
import styles from './transfersColumn.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import robot from '../assets/robotLogo.png'
import techHandImage from '../assets/techHandTwo.jpg'
import { IoArrowUndo } from "react-icons/io5";
import {useLocation, useNavigate} from 'react-router-dom'
import Loader from './Loader'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'

const TransferToWalletAmountComponent = () => {
    const [showModal, setShowModal] = useState(false)
    const location = useLocation();
    const {state} = location;
    const [debitWalletType, setDebitWalletType ] = useState('')
    const [amount, setAmount] = useState(0)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const userInfo = useSelector(selectUserInfo)
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai/'

    useEffect(()=>{
      setDebitWalletType(state.selectedOption)
    },[])
  

    console.log(debitWalletType)


    const handleMakeTransfer =(e)=>{
      e.preventDefault()

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);


     if(amount){
      var formdata = new FormData();
      formdata.append("amount", amount);
      formdata.append("debitWallet", debitWalletType);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(`${API_ENDPOINT}/api/transfers/make-transfer`, requestOptions)
        .then(response => response.json())
        .then(result =>{
          if(result.message === 'Transfers successful' ){
            setLoading(false)
             console.log(result)
             setPopUp('Transfer to your Main Wallet was successful')
             setShowPopupModal(true)
          }
          else {
            setPopUp('Error making the transfer, ensure you have sufficient balance in the debit wallet and try again')
            setShowPopupModal(true)
          }
         setLoading(false)
        })
        .catch(error => console.log('error', error));
  
  
    
   
    
     }
    }

    const handleClosePopup =()=>{
      setShowPopupModal(false)
       setPopUp('')
      }
    


    useEffect(() => {
     
      setTimeout(() => {
        setLoading(false);
      }, 3000); 
    }, []);

  return (
    <>
    
    

    <div className={styles.transferImagesContainer}>
      {loading ? <Loader /> : null}

            {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

    <img
            src={techHandImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
            <div className={styles.transferContainer}>

            <div style={{maxHeight:'max-content'}} className='flex gap-3 my-11 mx-11 p-5 items-center '>
          
            <form onSubmit={handleMakeTransfer} style={{width:'300px', margin:'auto'}} className='flex flex-col gap-3 ' action="">
            <h1 className='text-2xl font-bold pb-6 pt-2 text-center text-white sm:text-4xl'>Transfer to Main Wallet</h1>
              {/* <label className={styles.emailText} htmlFor="email">Amount</label> */}
              <input style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:10, outline:'none'}}
                placeholder='Enter Amount'
                onChange={(e)=>setAmount(e.target.value)}
               type="number" name='amount' />
             
              <button className={styles.loginButton} type="submit">Transfer to wallet</button>
            
            </form>
          </div>
    
       {/* { showModal &&  <div className={styles.modal}>
         < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
          <div className='flex gap-3 my-11 bg-sky-200 mx-11 p-5 items-center'>
            <div>
            <img
              src={robot}
              style={{ width: 600, height: 400, zIndex:10, objectFit:'contain' }} // optional
              alt='welcome image' 
            />
            </div>
            <form style={{width:'300px'}} className='flex flex-col gap-3 ' action="">
              <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Transfers</h1>
              <label className={styles.emailText} htmlFor="email">Amount</label>
              <input style={{backgroundColor:'white', height:'40px', width:'100%'}} type="email" name='email' />
             
              <button className={styles.loginButton} type="submit">Transfer</button>
            
            </form>
          </div>
    
          </div> } */}
              </div>
            
          
        </div>
        </>
  )
}

export default TransferToWalletAmountComponent
