import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import ProfileSummary from '../components/ProfileSummary'
import UpdatePasswordComponent from '../components/UpdatePasswordComponent'
import Footer from '../components/Footer'

const page = () => {
  return (
    <div>
        <DashboardNavbar />
      <DashboardSidebar />
      <div className='flex gap-10 m-6 md:flex flex-col items-center justify-center'>
      {/* <ProfileSummary /> */}
      <div>
        <UpdatePasswordComponent />
      </div>
      </div>
      <Footer />
    </div>
  )
}

export default page
