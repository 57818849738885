import React, { useState } from 'react'
import styles from './transfersToWalletColumn.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import robot from '../assets/robotLogo.png'
import {useLocation, useNavigate} from 'react-router-dom'
import techHandImage from '../assets/techHandTwo.jpg'

const TransferToWalletColumn = () => {
    const [showModal, setShowModal] = useState(false)
    const [selectedOption, setSelectedOption] = useState('');
    const navigate = useNavigate()
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)
  

    const handleSelectChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(selectedOption)
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // Handle form submission with the selectedOption value
      console.log('Selected Option:', selectedOption);
     if(selectedOption){
      navigate('/transferToWalletAmount',{state:{selectedOption: selectedOption}})
     } else {
      setPopUp('please Select the wallet you will be transfering to the main wallet from')
      setShowPopupModal(true)
     }
    
    };
    
    const handleClosePopup =()=>{
      setShowPopupModal(false)
       setPopUp('')
      }
    

  return (
    <div className={styles.transferImagesContainer}>

         {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
   <img
        src={techHandImage}
        alt='welcome image'
        quality={100}
       className={styles.transferHeroImage}

        />
        <div className={styles.transferContainer}>

        {/* <div style={{maxHeight:'max-content'}} className='flex gap-3 my-11 bg-sky-100/50 mx-11 p-5 items-center  backdrop-filter backdrop-blur-lg bg-opacity-50'> */}
        <div style={{maxHeight:'max-content'}} className='flex gap-3 my-11 mx-11 p-5 items-center '>
       
        <form style={{width:'300px', margin:'auto'}} className='flex flex-col gap-3 ' action="">
  <h1 className='text-2xl font-bold pb-6 pt-2 text-center text-white sm:text-4xl'>Transfer to Main Wallet</h1>
  {/* <label className={styles.emailText} htmlFor="wallets">Choose Wallet</label> */}
  {/* <input style={{backgroundColor:'white', height:'40px', width:'100%'}} type="email" name='email' /> */}
  <div style={{height:40, backgroundColor:'white'}}>

  <select  className='outline-none'  value={selectedOption} onChange={handleSelectChange}>
  <option value="">Select wallet...</option>
    <option className='p-3' value="daily">Daily Profit Wallet</option>
    {/* <option value="compoundingProfitWallet">Compounding Profit Wallet</option> */}
    <option value="referralCommission">Referral Commission Wallet</option>
    <option value="bonusWallet">Bonus Wallet</option>
  </select>

  </div>
 
<button onClick={handleSubmit}  className={styles.loginButton} type="submit">Next</button>

</form>
</div>

{/* { showModal &&  <div className={styles.modal}>
< FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
<div className='flex gap-3 my-11 bg-sky-200 mx-11 p-5 items-center'>
<div>
<img
  src={robot}
  style={{ width: 600, height: 400, zIndex:10, objectFit:'contain' }} // optional
  alt='welcome image' 
/>
</div>
<form style={{width:'300px'}} className='flex flex-col gap-3 ' action="">
  <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Transfer to Main Wallet</h1>
  <label className={styles.emailText} htmlFor="email">Amount</label>
  <input style={{backgroundColor:'white', height:'40px', width:'100%'}} type="email" name='email' />
 
  <button className={styles.loginButton} type="submit">Transfer to Main Wallet</button>

</form>
</div>

</div> } */}
</div>

</div>
  )
}

export default TransferToWalletColumn
