import React from 'react'
import { Link, useParams , useLocation} from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player';
import goodSRC from '../assets/goodAnimation.json'
import styles from './transfersColumn.module.css'
import purchaseImage from '../assets/purchasePortfolio.jpg'

const FundWalletSuccessComponent = () => {
  const { data } = useParams();
  console.log('success data from params data:', data)

  const location = useLocation();
const {state} = location;

console.log('success data from location state:', state)

    // activate portfolio by submitting to db
    
  return (
    <div className={styles.transferImagesContainer}>

    <img
            src={purchaseImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
  <div className={styles.transferContainer}>
  
    <Player
            src={goodSRC}
            className="player"
            loop
            autoplay
             style={{ height: '100px', width: '100px' }}
            
            />
            <h1 className='text-3xl font-light text-white text-center'>Congratulations!</h1>
            <h1 className='text-3xl font-light text-white text-center'>Payment is Successful and wallet has been Funded</h1>
            <div style={{textAlign:'center', margin:'20px auto'}}>
            <Link className='bg-sky-100 py-2 px-4 rounded-lg font-bold hover:text-white hover:bg-sky-700 text-center' to='/dashboard'>Dashboard</Link>
            </div>
           
            {/* <img 
            src={logo}
            alt='logo image'
            style={{ height: '100px', width: '100px' }}
            /> */}
        </div>
        </div>
  )
}

export default FundWalletSuccessComponent
