import React from 'react'
import Navbar from '../components/Navbar'
import TopTag from '../components/TopTag'
import { TickerTape } from "react-ts-tradingview-widgets";
import './about.css'
import InnerHeroComponent from '../components/InnerHeroComponent';
import robotIMG from '../assets/robImg.png'
import robotBabyImg from '../assets/babyRobot.jpg'
import robotFinance from '../assets/robotFinance.png'
import Footer from '../components/Footer';

const Finance = () => {
  return (
    <div>
    <TopTag />

    {/* <TickerTape copyrightStyles={customCopyrightStyles}  colorTheme="dark"></TickerTape> */}
      <Navbar />
  <InnerHeroComponent title='Tibio AI Trading Operations: A Seven-Year Financial Odyssey' />

    <div className='aboutContainer'>
      <div className="aboutTop">
        <ul>
        <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>Executive Summary:</p>
          <p className='text-sky-600 text-2xl font-bold'>In the dynamic landscape of financial technology, Tibio has not just emerged as an artificial intelligence robot but as a transformative force in the investment sphere. Over the past seven years, Tibio's journey has been characterized by innovation, resilience, and a commitment to reshaping the traditional paradigms of investment. This financial report provides a comprehensive overview of Tibio's performance, growth trajectory, and the impact it has had on investors globally.</p>
          
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>I.Financial Performance:</p>
          <li className='text-sky-600 text-lg' ><span>Tibio's Growth Trajectory: </span>Tibio embarked on its journey in 2016, and since then, its financial performance has witnessed remarkable growth. Starting with an initial investment capital of $50Million, the total asset under management (AUM) has grown exponentially, reaching $4.7Billion.</li>
          <li className='text-sky-600 text-lg'><span>Profit Generation and Distribution: </span>The core of Tibio's model lies in its ability to generate profits through automated trading. Over the seven-year period, Tibio's profit-sharing ecosystem has been a testament to collective success. Total profits generated amount to $75Million, and these have been efficiently distributed among investors, fostering a sense of shared prosperity.</li>
         
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>II. Operational Excellence:</p>
          <li className='text-sky-600 text-lg'><span>Ease and Convenience: </span> Tibio's commitment to user-centric design has resulted in a platform that is both easy to use and convenient. Investors, regardless of their experience level, have found Tibio's interface intuitive, making the investment process accessible to a broad audience.</li>
          <li className='text-sky-600 text-lg'><span>Autonomous Operation: </span> One of Tibio's standout features is its autonomy. The robot operates without human interference, leveraging advanced algorithms and artificial intelligence to make data-driven decisions. This autonomy has not only ensured operational efficiency but has also minimized the impact of emotional decision-making on investments.</li>
        
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>III.  Global Impact and Community Building:</p>
          <li className='text-sky-600 text-lg'><span>Tibio's Global Reach: </span> With a presence in [Number] countries, Tibio has created a global community of investors. This diverse network provides a platform for the exchange of insights, strategies, and experiences, enriching the investment journey for all participants.</li>
          <li className='text-sky-600 text-lg'><span>Investor Testimonials: </span> Real stories from investors around the world showcase the positive impact Tibio has had on their financial portfolios. Testimonials and success stories highlight how Tibio's AI trading operations have transformed lives and financial outlooks.</li>
        
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>IV. Transparency and Continuous Innovation:</p>
          <li className='text-sky-600 text-lg'><span>Transparent Operations: </span> Transparency has been a cornerstone of Tibio's operations. Investors have access to real-time insights into their investments, ensuring an informed decision-making process.</li>
          <li className='text-sky-600 text-lg'><span>Continuous Innovation: </span> Tibio's commitment to staying ahead of the curve is reflected in its continuous innovation. Regular updates, enhancements, and adaptations to the latest market trends showcase Tibio's adaptability and responsiveness to the ever-evolving financial landscape. </li>
        
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>V.  Challenges and Risk Mitigation:</p>
          <li className='text-sky-600 text-lg'><span>Market Challenges: </span> The financial report acknowledges the challenges presented by market volatility, regulatory changes, and technological advancements. Tibio's risk management strategies and adaptive algorithms have been pivotal in mitigating these challenges.</li>
      
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>VI. Future Outlook:</p>
          <li className='text-sky-600 text-lg'><span>Expansion Plans: </span> Looking ahead, Tibio envisions further expansion into emerging markets and the introduction of new features to enhance the investor experience. The goal is to remain at the forefront of technological innovation in the financial sector.</li>
      
        
        

        </ul>
      <img src={robotIMG} alt="tibio" />
      </div>

      <div  className="aboutMiddle">
        {/* <h1 className='text-4xl text-sky-800 font-bold text-center my-5'>Unlocking Financial Prosperity with Tibio AI Trading Operations: A Transformative Investment Experience</h1>
        <h4 className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>Welcome to the Future of Investment!</h4>
        <p className='text-sky-600 text-2xl m-8'>In a world driven by innovation, Tibio stands out as a beacon of financial empowerment. Tibio is not just an artificial intelligence robot; it's a revolution in the way we perceive and engage with investments. Let's delve into the myriad advantages that make investing with Tibio a game-changer for individuals seeking financial growth and independence.</p> */}

        {/* <div className="aboutMiddleInner">
          <h2>1. Automated Precision:</h2>
          <p>Tibio harnesses the power of cutting-edge artificial intelligence to make lightning-fast, data-driven decisions. This ensures that your investments are always strategically positioned in the dynamic financial landscape.</p>
        </div>

        <div className="aboutMiddleInner">
          <h2>2. Profit Sharing Ecosystem:</h2>
          <p>At Tibio, we believe in shared success. Our unique profit-sharing model means that as Tibio generates profits, they are distributed among the community of investors. Your financial success is our collective goal.</p>
        </div>
        <div className="aboutMiddleInner">
          <h2>3. Ease and Convenience:</h2>
          <p>Designed with user experience in mind, Tibio offers unparalleled ease and convenience. From onboarding to daily operations, our platform is crafted to be user-friendly, ensuring that you navigate the investment landscape effortlessly.</p>
        </div>
        <div className="aboutMiddleInner">
          <h2>4. Autonomous Operation:</h2>
          <p>Say goodbye to constant monitoring and decision fatigue. Tibio performs autonomously, removing the need for human intervention. Your investments are managed efficiently and effectively round the clock.</p>
        </div>
        <div className="aboutMiddleInner">
          <h2>5. Global Impact:</h2>
          <p>Join a community of investors around the world who have experienced the transformative power of Tibio. Our global reach means that you are part of a diverse network, sharing insights and experiences with like-minded individuals.</p>
        </div>
        <div className="aboutMiddleInner">
          <h2>6. Transparent Operations:</h2>
          <p>Tibio prioritizes transparency. Our operations are transparent, ensuring that you have real-time insights into your investments. Track performance, understand strategies, and make informed decisions with ease.</p>
        </div>
        <div className="aboutMiddleInner">
          <h2>7. Proven Track Record:</h2>
          <p>Tibio has transformed the lives of investors worldwide. Explore success stories and testimonials that underscore the positive impact our AI trading operations have had on financial portfolios.</p>
        </div>
        <div className="aboutMiddleInner">
          <h2>8. Continous Innovation:</h2>
          <p>In the ever-evolving landscape of finance and technology, Tibio is committed to continuous innovation. Benefit from ongoing enhancements, ensuring that your investment strategy aligns with the latest market trends.</p>
        </div> */}

        <div className='babyRobotContainer'>
        <p className='text-sky-600 text-2xl m-11'>This seven-year financial report encapsulates Tibio's evolution, triumphs, and its ongoing commitment to reshaping the investment landscape. Tibio is not just an AI trading robot; it's a testament to the potential of artificial intelligence to redefine how we invest, share profits, and build a global community of empowered investors.</p>
                  <img src={robotFinance} alt="tibio" />
        </div>
      
        {/* <p className='text-sky-600 text-4xl m-11 text-center font-bold'>Join the Tibio community today and redefine your financial future!</p> */}
      </div>
    </div>
    <Footer />
  </div>
  )
}

export default Finance
