import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import PaymentFailureComponent from '../components/PaymentFailureComponent'
import Footer from '../components/Footer'

const PaymentFailure = () => {
  return (
    <div>
          <DashboardNavbar />
      <DashboardSidebar />
      <PaymentFailureComponent />
      <Footer />
    </div>
  )
}

export default PaymentFailure
