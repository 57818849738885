import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import ReferralData from '../components/ReferralData'
import Footer from '../components/Footer'


const page = () => {
  return (
    <div>
       <DashboardNavbar />
      <DashboardSidebar />
      <ReferralData />
      {/* <div style={{marginTop:'150px'}} className='flex gap-5 flex-wrap m-12 items-center justify-center'>
          
      </div> */}
       <Footer />
    </div>
  )
}

export default page
