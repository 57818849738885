import React,{useState, useEffect} from 'react'
import EachActivePortfolio from './EachActivePortfolio'
import { selectUserInfo, setUserInfo } from '../slices/authSlice';
import {setFetchedUserInfo, selectFetchedUserInfo } from '../slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import EmptyContainer from './EmptyContainer'

const ActivePortfolioComponents = () => {
  const userInfo = useSelector(selectUserInfo)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'
 
    const [allPortfolios, setAllPortfolios] = useState([])
  

console.log(userInfo.user._id)

  useEffect(() => {
    const fetchReferrals = async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_ENDPOINT}/api/portfolios/${userInfo.user._id}`, requestOptions);
        const result = await response.json();
        console.log('called again')
        setAllPortfolios(result.portfolios);
       
        console.log(result);
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchReferrals()
   
  }, [userInfo.token, API_ENDPOINT]); // Add dependencies as needed

  console.log(allPortfolios)
  console.log(userInfo.user.portfolios)

  const commonPortfolios = allPortfolios.filter(portfolio =>
    userInfo.user.portfolios.some(userPortfolio => userPortfolio === portfolio._id)
  );
  console.log(commonPortfolios)
  return (
    <div>
       <h1 className='text-xl m-5 font-bold'>Active Portfolios</h1>
       
       <div className='flex flex-wrap items-center gap-10 justify-center'>
     
        {commonPortfolios.length ? commonPortfolios
        // .filter(portf=> portf._id = userInfo.user.portfolios)
        .map(portfolio=>(
             <EachActivePortfolio key={portfolio._id} {...portfolio} />
        ) ) : 
        <EmptyContainer emptyTitle='You dont have any Portfolios yet' emptyDetails='Purchase Portfolio to make investment' />
        }
      
     

        </div>
    </div>
  )
}

export default ActivePortfolioComponents
