import homeImage from '../assets/homeImage.jpg'
import robot from '../assets/robot.png'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../components/Navbar'
import styles from './signup.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import { AuthContext } from '../context/AuthContext';
import { TickerTape } from "react-ts-tradingview-widgets";
import TopTag from '../components/TopTag'
import axios from 'axios'
import { Player } from '@lottiefiles/react-lottie-player';
import goodSRC from '../assets/goodAnimation.json'
import Footer from '../components/Footer'
import Loader from '../components/Loader'
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

const SignupWithReferralPassword = () => {
  const [showModal, setShowModal] = useState(false)
 
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [showPopupTwoModal, setShowPopupTwoModal] = useState(false)
  // const { err, dispatch} = useContext(AuthContext);
  const [popUp, setPopUp] = useState('')
  const [popUpTwo, setPopUpTwo] = useState('')
  const [removeEnterEmail, setRemoveEnterEmail] = useState(true)
  const location = useLocation();
  const {state} = location;
  const [email, setEmail] = useState('')
  const [refCode, setRefcode] = useState('')
  const [loading, setLoading] = useState(false)
  const [isPasswordSecure, setIsPasswordSecure] = useState(true)

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'

  useEffect(()=>{
    setEmail(state.email)
    setRefcode(state.refCode)
  },[])
//   console.log(state.email)

//   const handleShowPasswordForm =async(e)=>{
//     e.preventDefault()

//     if(!email) {
//       setError('Fill in your email address')
//       setPopUp('Fill in your valid email address to continue')
//       setShowPopupModal(true)
//       return
//     }


//   var formdata = new FormData();
//   formdata.append("email", email);
  
//   var requestOptions = {
//     method: 'POST',
//     body: formdata,
//     redirect: 'follow'
//   };
  
//   fetch("http://localhost:5000/api/auth/verify-email", requestOptions)
//     .then(response => response.json())
//     .then(result => {
//       console.log(result.message)
//       if(result.message === 'Email is available'){
        
//         setShowModal(true)
//       }else if(result.message === 'Email already taken!'){
//         setError('User already exists.')
//         setPopUp('User already exist, Edit your email or Login if you do have an account.')
//         setShowPopupModal(true)
//         return
//       }
//       console.log(result)})
//     .catch(error => console.log('error', error));
    

//     console.log('email & password :',email, password)
//   }


console.log(email)
console.log(refCode)


  const handleSignup =async(e)=>{
    e.preventDefault()
    if(!password) {
      setError('Fill in your Password')
      return
    }

    var formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
      setLoading(true)
      fetch(`${API_ENDPOINT}/api/auth/register/${refCode}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if(result.message === 'Registration successful'){

            setShowModal(false)
            const form = e.target;
            form.reset()
            setShowPopupTwoModal(true)
            setPopUpTwo('Go to your provided email address to confirm your email, also make sure to refresh your email and also check your spam mails.')
            console.log(result)
            setLoading(false)

          } else {
            setPopUp('Error registering user, Please check your internet and try again.')
            setLoading(false)
          }
         
        })
        .catch(error => console.log('error', error));
   
  //   try {



  // const res =    await fetch('http://localhost:5000/api/auth/register', {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": 'application/json'
  //       },
  //       body: JSON.stringify({
  //         email,
  //         password
  //       })
  //     })

  //     console.log('res--->', res)

  //     if(res.ok){
  //       const {user} = await res.json()
  //       console.log(user)
       
  //     }else{
  //       console.log("User registration failed")
  //     }
  //   } catch (error) {
  //     console.log("Error during registration", error)
  //   }
  //   console.log('email & password :',email, password)
  }




const handleClosePopup =()=>{
  setShowPopupModal(false)
   setPopUp('')
  }

  const handleClosePopupTwo =()=>{
    setShowPopupTwoModal(false)
     setPopUpTwo('')
     navigate('/login')
    }
  return (
    <div>
      {loading ? <Loader /> : null}
      <TopTag />
{/* <TickerTape   colorTheme="dark"></TickerTape> */}

      <Navbar />

         {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}


           {/* popup 2*/}
           { showPopupTwoModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={handleClosePopupTwo} className={styles.popClose} />
            <h1 className='p-2 bg-sky-800 text-white font-semibold text-2xl my-7'>Registration Successful</h1>
            <Player
          src={goodSRC}
          className="player"
          loop
          autoplay
           style={{ height: '50px', width: '50px' }}
          
          />
             <p className={styles.popText}>{popUpTwo}</p>
            <button className={styles.popButton} onClick={handleClosePopupTwo}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup 2 ends */}
      <div className={styles.imageContainer}  >
      <img
        src={homeImage}
        alt='welcome image'
       className={styles.heroImage}

        />

<div className={styles.loginContainer}>

<div className='flex gap-3 my-2 mx-11 p-5 items-center'>
      
<div>
        <img
        src={robot}
        style={{width:600, height:400, objectFit:'contain'}}
        alt='welcome image'
        quality={100}
        className={styles.loginImage}

        />
        </div>
        <form  onSubmit={handleSignup}  style={{width:'300px'}} className='flex flex-col gap-3 ' >
          <h1 className='text-4xl font-bold pb-6 pt-2 text-center text-white'>Sign up</h1>
       
          <div className={styles.inputContainer}>

          <input 
           onFocus={()=>{setError(''); setRemoveEnterEmail(false)}}
           className={styles.emailInput}
            onChange={(e)=>setPassword(e.target.value)}
            placeholder='Enter Password'
           style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10}}
           type={isPasswordSecure ? "password" : 'name'}  name='password' />

        {isPasswordSecure ? <FaRegEye
            onClick={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray',  fontSize:'20px', cursor:'pointer'}}/>  
                               : <FaRegEyeSlash 
                               onClick={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray', fontSize:'20px', cursor:'pointer'}}/>
             }
        
          {/* {removeEnterEmail && <label className={styles.emailText} htmlFor="email">Enter Password</label>} */}
          </div>
        
          <button 
         
          className={styles.btn} 
        
          >Sign up</button>
       
       { error && <p className='px-3 py2 font-bold bg-red-500 text-white w-fit rounded-md  text-xs'>{error}</p>}
        {/* <Link href='signup' className='text-sm'>Dont have an account? <strong className=''>Sign up</strong></Link> */}
        </form>
        </div>
      </div>

      { showModal &&  <div className={styles.modal}>
     < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
      <div className='flex gap-3 my-11 bg-sky-200/50 mx-11 p-5 items-center  backdrop-filter backdrop-blur-lg bg-opacity-10'>
      
      </div>

      </div> }
    </div>
    <Footer />
    </div>
  )
}

export default SignupWithReferralPassword
