import React from 'react'
import Navbar from '../components/Navbar'
import TopTag from '../components/TopTag'
import { TickerTape } from "react-ts-tradingview-widgets";
import './about.css'
import InnerHeroComponent from '../components/InnerHeroComponent';
import robotIMG from '../assets/robImg.png'
import robotBabyImg from '../assets/babyRobot.jpg'
import robotLook from '../assets/robotLook.jpg'
import Footer from '../components/Footer';

const Performance = () => {
  return (
    <div>
    <TopTag />

    {/* <TickerTape copyrightStyles={customCopyrightStyles}  colorTheme="dark"></TickerTape> */}
      <Navbar />
  <InnerHeroComponent title='Tibio AI Trading Operations: 2016 - 2023 Financial Statement' />

    <div className='aboutContainer'>
      <div className="aboutTop">
      <ul>
        <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>Introduction:</p>
          <p className='text-sky-600 text-2xl font-bold'>Tibio, the pioneering artificial intelligence robot in investment, commenced its journey in 2016 with a vision to revolutionize the financial landscape. This financial statement reflects the comprehensive overview of Tibio's growth, performance, and its impact on investors from its inception to the current year, 2023.</p>
          
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>I. Financial Performance:</p>
          <li className='text-sky-600 text-lg' ><span>Initial Capital Growth: </span>In 2016, Tibio initiated operations with an initial investment capital of $50 million. This capital served as the foundation for its growth.</li>
          <li className='text-sky-600 text-lg'><span>Asset Under Management (AUM) Evolution: </span>Over the years, Tibio's AUM has experienced remarkable growth. As of 2023, the AUM stands at an impressive $3.7 billion, showcasing the exponential expansion of its market presence.</li>
         

          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>II. Profit Generation and Distribution:</p>
          <li className='text-sky-600 text-lg'><span>Profit Accumulation: </span> Tibio's automated trading algorithms have consistently generated profits. The cumulative profits from 2016 to 2023 amount to $470Million, underlining the effectiveness of its trading strategies.</li>
          <li className='text-sky-600 text-lg'><span>Profit Sharing Model: </span> Tibio's commitment to shared prosperity is evident in its profit-sharing model. The profits generated have been distributed among investors, fostering a sense of collective success. </li>
        
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>III. Operational Excellence:</p>
          <li className='text-sky-600 text-lg'><span>Ease of Use: </span> Tibio's user-centric design ensures ease of use. The platform's intuitive interface caters to investors of all experience levels, making it accessible to a broad audience.</li>
          <li className='text-sky-600 text-lg'><span>Autonomy and Efficiency: </span> Operating autonomously, Tibio leverages advanced AI to make data-driven decisions. This autonomy not only ensures operational efficiency but also eliminates the impact of emotional decision-making on investments.</li>
        
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>IV. Global Impact and Community Building:</p>
          <li className='text-sky-600 text-lg'><span>Global Presence: </span> Tibio has established a global footprint, reaching investors in 135 countries. This widespread presence has created a diverse community of investors, facilitating the exchange of insights and experiences.</li>
          <li className='text-sky-600 text-lg'><span>Community Engagement: </span>The platform encourages community engagement, fostering a collaborative environment. Investors worldwide contribute to the collective knowledge base, enhancing the overall investment experience. </li>
        
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>V. Transparency and Innovation:</p>
          <li className='text-sky-600 text-lg'><span>Transparent Operations:</span> Transparency remains a fundamental principle of Tibio's operations. Real-time insights empower investors with the information needed for informed decision-making.</li>
          <li className='text-sky-600 text-lg'><span>Continuous Innovation:</span> Tibio is committed to staying ahead of industry trends. Regular updates and innovations ensure that the platform remains at the forefront of technological advancements in finance.</li>
      
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>VI. Risk Mitigation and Challenges:</p>
          <li className='text-sky-600 text-lg'><span>Risk Management Strategies:</span> In navigating market challenges, Tibio employs robust risk management strategies. These strategies have been instrumental in mitigating risks associated with market volatility and regulatory changes.</li>
          <li className='text-sky-600 text-lg'><span>Challenges Addressed:</span> Tibio acknowledges the challenges presented by an evolving financial landscape and has adapted to technological advancements, demonstrating resilience in the face of change.</li>
      
        
          <p className='text-sky-600 text-2xl font-bold bg-sky-50 p-2 w-fit mt-9'>VII. Future Outlook:</p>
          <li className='text-sky-600 text-lg'><span>Expansion Plans: </span> Looking ahead, Tibio envisions further expansion into emerging markets and the introduction of innovative features. The goal is to maintain a leadership position in the ever-evolving landscape of financial technology.</li>
      
        
        

        </ul>
      <img src={robotLook} alt="tibio" />
      </div>

      <div  className="aboutMiddle">
      
        <div className='babyRobotContainer'>
        <p className='text-sky-600 text-2xl m-11'>In summary, this financial statement encapsulates Tibio's evolution, achievements, and unwavering commitment to reshaping the investment domain through artificial intelligence. The impressive financial growth from 2016 to 2023 underscores Tibio's significance in the realm of automated trading and shared prosperity among its global community of investors.</p>
                  <img src={robotBabyImg} alt="tibio" />
        </div>
      
     
      </div>
    </div>
    <Footer />
  </div>
  )
}

export default Performance
