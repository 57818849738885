import React,{useState, useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import styles from './referralBonus.module.css'
import { selectUserInfo } from '../slices/authSlice';
import { selectFetchedUserInfo } from '../slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 120 },
    { field: 'date', headerName: 'Date', width: 120 },
    { field: 'comment', headerName: 'Title', width: 250 },
    { field: 'amount', headerName: 'Amount', width: 160 },
      {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div style={{borderRadius:'10px'}} className='bg-green-600 p-2 text-white'>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  const rows = [
    { id: 1, date: '11 September 2023', title: 'Welcome to Ai Trading Bot', amount:'$0',   status:'Registration'},
    { id: 2, date: '14 September 2023 ', title: 'Funding of account', amount:'$500',  status:'Funding' },
    { id: 3, date: '20 September 2023 ', title: 'Withdrawal of $50', amount:'$50',  status:'Withdrawal' },
    { id: 4, date: '22 September 2023 ', title: 'Transfered $50 to another account', amount:'$50',   status:'Transfer' },
    // Add more data rows as needed
  ];
  // type MyDataGridProps = {
  //   pageSize: number;
  //   rowsPerPageOptions: number[];
  // };


const RecentTransactionTable = () => {
  const [loading, setLoading] = useState(true);

  const fetchedUserInfo = useSelector(selectFetchedUserInfo)
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'
 
    const [allResponses, setAllResponses] = useState([])

  // fetch responses

  useEffect(() => {
    const fetchReferrals = async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_ENDPOINT}/api/response/${userInfo.user._id}`, requestOptions);
        const result = await response.json();
        console.log('called again')
        
       
         // const sortedData = result.withdrawals.sort((b, a) => a.createdAt - b.createdAt)
         const dataWithDateObjects = result.userResponses.map(item => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }));
              // Sort the array based on the createdAt property in descending order (from latest to oldest)
              const sortedData =  dataWithDateObjects.sort((a, b) => b.createdAt - a.createdAt);
              setAllResponses(sortedData);
              setLoading(false)
        console.log(result);
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchReferrals()
   
  }, [userInfo.token, API_ENDPOINT]); // Add dependencies as needed

  console.log(allResponses)

  function getFirstWord(inputString) {
    // Use split to separate words
    const wordsArray = inputString.split(' ');
  
    // Retrieve the first word (element at index 0)
    const firstWord = wordsArray[0];
  
    return firstWord;
  }
    // Create a new array by mapping through the transactions array
const newData = allResponses.map((transaction) => {
  // Find the corresponding user by matching IDs
  // const user = fetchedUsers.find((user) => user._id === transaction.user);

  // Combine user data and transaction data
  return {
    id: transaction._id,
    name: userInfo ? userInfo.user.name : '', // Use an empty string if the user is not found
    email: userInfo ? userInfo.user.email : '',
    comment: transaction.comment,
    amount: transaction.amount,
    status:getFirstWord(transaction.comment),
    date: transaction.createdAt,
  };
});

  return (
    <div className={styles.datatable}>
    <h1 className="m-4 text-2xl">
       Latest Transactions
    </h1>
    <DataGrid
      className={styles.datagrid}
      rows={newData} columns={columns}
    //   columns={requestColumns.concat(actionColumn)}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      GridLinesVisibility="Vertical"
    />
    
  </div>
  )
}

export default RecentTransactionTable
