import React, { useEffect, useState } from 'react'
import styles from './forgetPasswordColumn.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import passwordImage from '../assets/building.jpg'
import { selectUserInfo, setUserInfo } from '../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Loader from './Loader';
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

const ForgetPasswordUpdatePasswordComponent = () => {
  const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [showLoginLoading, setShowLoginLoading] = useState(false)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://admin-panel.tibio.ai'
  
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isNewPasswordSecure, setIsNewPasswordSecure] = useState(true)
    const [isConfirmPasswordSecure, setIsConfirmPasswordSecure] = useState(true)
    const [email, setEmail] = useState('')
    const location = useLocation();
     const {state} = location;
     const [updateTrue, setUpdateTrue] = useState(false)

    useEffect(()=>{
      setEmail(state.email)
    },[])
    const handleSubmitPassword=async(e)=>{
        e.preventDefault()
        if(!newPassword || !confirmPassword){
            setPopUp('Fill in the details to proceed')
            setShowPopupModal(true)
            return
        }
        if(newPassword !== confirmPassword){
            setPopUp('New Password and Confirm Password does not match')
            setShowPopupModal(true)
            return
        }

          setShowLoginLoading(true)
          var formdata = new FormData();
          formdata.append("password", newPassword);
          formdata.append("email", email);
          
          var requestOptions = {
            method: 'PUT',
            body: formdata,
            redirect: 'follow'
          };
        setLoading(true)

        if(newPassword && email && confirmPassword){

          fetch(`${API_ENDPOINT}/api/auth/forget-password`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result.message)
            if(result.message === 'Password updated successfully'){
              setLoading(false)
              setUpdateTrue(true)
              setPopUp('Password Updated Successfully')
              setShowPopupModal(true)
              return
              
             
            }else if(result.error){
              // setShowModal(true)
              setPopUp(`${result.error}`)
              setShowPopupModal(true)
      
            }
            setLoading(false)
            console.log(result)})
          .catch(error => console.log('error', error))

        }
      
            
            
        
        
    }

    const handleClosePopup =()=>{
        setShowPopupModal(false)
         setPopUp('')

         if(updateTrue){
          navigate('/login')
         }
        }
      
  return (
    <div className={styles.transferImagesContainer}>
    {loading ? <Loader /> : null}
       {/* popup */}
 { showPopupModal && 
 <div className={styles.popContainer}>
     
      <div className={styles.innerPopContainer}>
          < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
           <p className={styles.popText}>{popUp}</p>
          <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
      </div>
    
  
  </div>
      }
 {/* popup ends */}

  <img
          src={passwordImage}
          alt='welcome image'
          quality={100}
         className={styles.transferHeroImage}
  
          />
          <div className={styles.transferContainer}>
<form onSubmit={handleSubmitPassword} style={{width:'300px', margin:'auto'}} className='flex flex-col gap-3 ' action="">
<h1 className='text-4xl font-bold pb-6 pt-2 text-center text-white'>Forgot Password</h1>


        
        <div className='relative'>
        <input 
          value={newPassword}
           onChange={(e)=>setNewPassword(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}} 
          placeholder='New Password' 
          className='bg-sky-100 mt-2 p-2' type={isNewPasswordSecure ? "password" : 'name'}
          name='newPassword' />
           {isNewPasswordSecure ? <FaRegEye
            onClick={() => { isNewPasswordSecure ? setIsNewPasswordSecure(false) : setIsNewPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray',  fontSize:'20px', cursor:'pointer'}}/>  
                               : <FaRegEyeSlash 
                               onClick={() => { isNewPasswordSecure ? setIsNewPasswordSecure(false) : setIsNewPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray', fontSize:'20px', cursor:'pointer'}}/>
             }
        </div>

        <div className='relative'>
          <input
          value={confirmPassword}
          onChange={(e)=>setConfirmPassword(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}}
           placeholder='Confirm Password' 
           className='bg-sky-100 mt-2 p-2'
           type={isConfirmPasswordSecure ? "password" : 'name'} name='oldPassword' />
              {isConfirmPasswordSecure ? <FaRegEye
            onClick={() => { isConfirmPasswordSecure ? setIsConfirmPasswordSecure(false) : setIsConfirmPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray',  fontSize:'20px', cursor:'pointer'}}/>  
                               : <FaRegEyeSlash 
                               onClick={() => { isConfirmPasswordSecure ? setIsConfirmPasswordSecure(false) : setIsConfirmPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray', fontSize:'20px', cursor:'pointer'}}/>
             }
          </div>


<button  className={styles.btn} type="submit">Next</button>

</form>
</div>


</div>
  )
}

export default ForgetPasswordUpdatePasswordComponent
