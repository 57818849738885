import React from 'react'
import { ForexCrossRates } from "react-ts-tradingview-widgets";

const TradingViewWidgetThree = () => {
  return (
    <div style={{margin:'20px', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}}>

    
      <ForexCrossRates  height={400} width={600} colorTheme="light" ></ForexCrossRates>
      </div>
  )
}

export default TradingViewWidgetThree
