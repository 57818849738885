import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import WithdrawalHistoryColumn from '../components/WithdrawalHistoryColumn'
import Footer from '../components/Footer'

const page = () => {
  return (
    <div>
      <DashboardNavbar />
      <DashboardSidebar />
      <WithdrawalHistoryColumn />
      <Footer />
    </div>
  )
}

export default page
